.listItem {
  background: #f8f8fd;
  /* Gray 5 */

  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 3px;
  width: 100%;
  height: 33px;
  margin-top: 2px;
  padding-left: 15px;
  padding-top: 6px;
  cursor: pointer;
}

.icon {
  width: 11px;
  height: 11px;
  margin-right: 13px;
}

.name {
  font-family: "EuclidFlex-Regular";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  display: flex;
  align-items: center;

  /* Charcoal */

  color: #343642;
  margin-right: 15px;

  p {
    display: inline;
    font-family: "EuclidFlex-Regular";
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    /* identical to box height */

    align-items: center;

    /* Charcoal */

    color: #343642;
    // margin-right: 15px;
  }
}

.time {
  font-family: "ProximaSoft-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 11px;
  /* identical to box height */

  display: flex;
  align-items: center;

  /* Charcoal */

  color: #343642;
  // margin-right: 15px;

  p {
    font-family: "ProximaSoft-Regular";
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 11px;
    display: inline;
    /* identical to box height */

    /* Charcoal */

    color: #343642;
    margin-right: 15px;
  }
}

.singleItem {
  display: inline;
}
