@font-face {
  font-family: "ProximaSoft-Regular";
  src: url("../fonts/ProximaSoft-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/ProximaSoft-Regular.otf") format("opentype"),
    url("../fonts/ProximaSoft-Regular.woff") format("woff"),
    url("../fonts/ProximaSoft-Regular.ttf") format("truetype"),
    url("../fonts/ProximaSoft-Regular.svg#ProximaSoft-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Proxima Soft";
  src: url("../fonts/ProximaSoft-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/ProximaSoft-Regular.otf") format("opentype"),
    url("../fonts/ProximaSoft-Regular.woff") format("woff"),
    url("../fonts/ProximaSoft-Regular.ttf") format("truetype"),
    url("../fonts/ProximaSoft-Regular.svg#ProximaSoft-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Soft";
  src: url("../fonts/ProximaSoft-Medium.eot?#iefix") format("embedded-opentype"),
    url("../fonts/ProximaSoft-Medium.woff2") format("woff2"),
    url("../fonts/ProximaSoft-Medium.woff") format("woff"),
    url("../fonts/ProximaSoft-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "EuclidFlex-Medium";
  src: url("../fonts/EuclidFlex-Medium.eot?#iefix") format("embedded-opentype"),
    url("../fonts/EuclidFlex-Medium.otf") format("opentype"),
    url("../fonts/EuclidFlex-Medium.woff") format("woff"),
    url("../fonts/EuclidFlex-Medium.ttf") format("truetype"),
    url("../fonts/EuclidFlex-Medium.svg#EuclidFlex-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Euclid Flex";
  src: url("../fonts/EuclidFlex-Medium.eot?#iefix") format("embedded-opentype"),
    url("../fonts/EuclidFlex-Medium.otf") format("opentype"),
    url("../fonts/EuclidFlex-Medium.woff") format("woff"),
    url("../fonts/EuclidFlex-Medium.ttf") format("truetype"),
    url("../fonts/EuclidFlex-Medium.svg#EuclidFlex-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "EuclidFlex-Regular";
  src: url("../fonts/EuclidFlex-Regular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/EuclidFlex-Regular.otf") format("opentype"),
    url("../fonts/EuclidFlex-Regular.woff") format("woff"),
    url("../fonts/EuclidFlex-Regular.ttf") format("truetype"),
    url("../fonts/EuclidFlex-Regular.svg#EuclidFlex-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Euclid Flex";
  src: url("../fonts/EuclidFlex-Medium.eot?#iefix") format("embedded-opentype"),
    url("../fonts/EuclidFlex-Medium.otf") format("opentype"),
    url("../fonts/EuclidFlex-Medium.woff") format("woff"),
    url("../fonts/EuclidFlex-Medium.ttf") format("truetype"),
    url("../fonts/EuclidFlex-Medium.svg#EuclidFlex-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
}
* {
  -webkit-border-radius: 0;
  border-radius: 0;
  margin: 0;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
button,
select,
textarea {
  outline: 0;
}
a,
button {
  cursor: pointer;
}
input,
select {
  -webkit-appearance: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
input {
  transition: 0.5s all ease-in-out;
  -webkit-transition: 0.5s all ease-in-out;
  -moz-transition: 0.5s all ease-in-out;
  -ms-transition: 0.5s all ease-in-out;
}
select,
button,
input {
  outline: none;
}
body {
  margin: 0;
  padding: 0;
  /* overflow-x: hidden; */
  color: #4f4f4f;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  background: #f4f9ff;
}
ul {
  padding: 0;
  list-style: none;
  margin: 0;
}
a,
img {
  border: 0px;
}
img {
  max-width: 100%;
}
a,
ul,
li {
  text-decoration: none;
}
a {
  outline: 0;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  color: #333333;
}
a,
button {
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  cursor: pointer;
}
p {
  padding-bottom: 20px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #4f4f4f;
  margin: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 15px;
  font-weight: 500;
}
h1 {
  font-size: 60px;
  line-height: 71px;
  font-weight: 500;
}
h2 {
  font-size: 30px;
  line-height: 35px;
  font-weight: 500;
}
h3 {
  font-size: 24px;
}
h4 {
  font-size: 18px;
}
h5 {
  font-size: 17px;
}
h6 {
  font-size: 16px;
}
strong,
b {
  font-weight: 500;
}
em,
i {
  font-style: italic;
}
::-webkit-input-placeholder {
  color: #828282;
  opacity: 1 !important;
}
::-moz-placeholder {
  opacity: 1 !important;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: #828282;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: #828282;
  opacity: 1 !important;
}
.common-input::-webkit-input-placeholder {
  color: #828282;
  opacity: 1 !important;
}
.common-input::-moz-placeholder {
  opacity: 1 !important;
}
.common-input::-ms-input-placeholder {
  color: #828282;
}
.common-input::-moz-placeholder {
  color: #828282;
}
.active-field::-webkit-input-placeholder {
  color: #333;
}
.active-field::-ms-input-placeholder {
  color: #333;
}
.active-field::-moz-placeholder {
  color: #333;
}
.active-field::-webkit-input-placeholder {
  color: #333;
}
.office-invited .common-input::-webkit-input-placeholder {
  color: #bdbdbd;
  opacity: 1 !important;
}
.office-invited .common-input::-moz-placeholder {
  opacity: 1 !important;
}
.office-invited .common-input::-ms-input-placeholder {
  color: #bdbdbd;
}
.office-invited .common-input::-moz-placeholder {
  color: #bdbdbd;
}
/*** reset css end ***/
/*** log in page css start ***/
.full-height {
  height: 100vh;
}
.display-none {
  display: none;
}
.form-bg {
  /* background: -moz-linear-gradient(
    90deg,
    rgba(7, 50, 111, 1) 0%,
    rgba(58, 128, 213, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(7, 50, 111, 1) 0%,
    rgba(58, 128, 213, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(7, 50, 111, 1) 0%,
    rgba(58, 128, 213, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#07326f",endColorstr="#3a80d5",GradientType=1); */
  width: 100%;
  height: 100vh;
  background-image: url("../images/coriganpattern.png");
  background-size: cover;
}
.vertical-middle {
  position: absolute;
  top: 50%;
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  left: 50%;
  text-align: center;
  width: 100%;
}
.common-form {
  background: #fff;
  border-radius: 5px;
}
.form-main {
  padding: 0 30px;
  max-width: 524px;
}
.login-form {
  padding: 50px 43px 38px 43px;
}
.form-logo {
  margin-bottom: 12px;
  display: inline-block;
  vertical-align: middle;
}
.form-logo-main {
  text-align: center;
}
.login-form .common-input {
  width: 100%;
}
.login-form .form-logo {
  max-width: 284px;
}
.form-head h2 {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #4f4f4f;
  margin: 0 0 40px 0;
}
.common-input {
  background: #fff;
  border: 1px solid #828282;
  border-radius: 5px;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #828282;
  font-family: "Roboto", sans-serif;
  padding: 5px 6px 5px 18px;
  height: 45px;
}
.common-form form p {
  padding-bottom: 30px;
}
.active-field {
  border: 1px solid #2675da !important;
  color: #333333 !important;
}
.blue-btn {
  background: #2a3688;
  color: #fff;
}
.blue-btn.btn-hover {
  background: #2675da;
  color: #fff;
}
.common-btn {
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  display: inline-block;
  border: 0;
  vertical-align: middle;
  font-size: 13px;
  line-height: 15px;
  font-weight: 500;
  padding: 10px 12px;
  font-family: "Roboto", sans-serif;
}
.login-form .common-btn {
  min-width: 75px;
}
.grey-btn {
  background: #d2d2d2;
  font-size: 12px;
  line-height: 14px;
  color: #323232;
  padding: 10px;
}
form label {
  color: #4f4f4f;
  font-size: 14px;
  line-height: 16px;
}
.forgot-pass {
  text-align: left;
  margin-top: -24px;
}
.forgot-pass a {
  font-size: 12px;
  line-height: 14px;
  color: #2a3688;
  border-bottom: 1px solid #2a3688;
}
.login-submit {
  text-align: right;
  margin: 45px 0 0 0;
  display: flex;
  justify-content: flex-end;
}
footer {
  margin: 13px 0 0 0;
}
footer ul li {
  display: inline;
  font-size: 12px;
  line-height: 14px;
  position: relative;
  margin: 0 2px;
}
footer ul li a {
  color: #fff;
}
.no-border-top {
  border-top: 0 !important;
}
.wrong-credentials {
  float: left;
  width: calc(100% - 150px);
  text-align: left;
  margin: 45px 0 0 0;
}
.wrong-credentials p {
  color: #2675da;
  font-size: 12px;
  line-height: 14px;
}
.forgot-pass-head {
  text-align: left;
  margin-bottom: 10px;
}
.forgot-pass-head h2 {
  margin-bottom: 10px;
}
.forgot-pass-form form .login-submit {
  margin-top: 23px;
}
.sub-heading {
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
  padding: 23px 20px;
}
.sub-heading.with-arrow {
  padding: 17px 20px;
}
.sub-heading.with-arrow h3 {
  background: url(../images/heading-arrow.png) no-repeat left;
  padding: 6px 0px 6px 40px;
}
.sub-heading h3 {
  color: #052d64;
  font-size: 16px;
  line-height: 19px;
  font-weight: normal;
  margin-bottom: 0;
}
.login-form.forgot-pass-form {
  padding: 0;
}
.inner-forgot-pass {
  padding: 40px 43px 38px 43px;
}
.not-registered-email {
  margin-top: -17px;
}
.not-registered-email p {
  color: #2675da;
  padding-bottom: 0 !important;
  font-size: 12px;
  line-height: 14px;
  text-align: left;
}
.backto-login {
  margin-top: -21px;
}
/*** log in page css end ***/
/*** register page css start ***/
.register-form {
  padding: 50px 0 38px 0;
}
.register-form .common-input {
  width: 100%;
}
.office-invited {
  padding: 0px 43px 15px 43px;
  border-bottom: 1px solid #e0e0e0;
  text-align: left;
}
.common-form form .office-invited p {
  padding-bottom: 17px;
}
.passwords-main {
  padding: 30px 43px 0 43px;
}
.office-invited .common-input {
  border: 1px solid #bdbdbd;
  color: #bdbdbd;
}
.register-form .login-submit {
  margin: 0;
}
.register-form .login-submit .common-btn {
  min-width: 130px;
}
.form-head p {
  font-size: 12px;
  line-height: 14px;
}
/*** privacy policy page css start ***/
.sub-heading.privacy-policy-head {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  padding: 23px 20px 14px;
  position: relative;
}
.sub-heading.privacy-policy-head .privacy-logo {
  float: left;
}
.privacy-policy-head h3 {
  color: #828282;
  float: left;
  padding-left: 25px;
}
.privacy-policy-form {
  max-width: 770px;
  text-align: left;
}
.policy-content h2 {
  font-size: 18px;
  line-height: 21px;
  font-weight: 400;
}
/*** custom scrollbar css start ***/
.custom-scrollbar {
  padding: 70px 0;
}
#cstm-scrlbar {
  overflow: hidden;
}
.custom-scrl-content {
  overflow: auto;
  position: relative;
  width: 100%;
  max-width: 100%;
}
.privacy-policy-form .custom-scrl-content {
  height: 505px;
}
.custom-scrl-content.hidden {
  display: none;
}
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #828282 !important;
  border-radius: 5px !important;
  width: 9px !important;
  height: 103px;
}
.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #e0e0e0 !important;
  border-radius: 0 !important;
  width: 9px !important;
}
.mCSB_scrollTools {
  right: -3px;
}
/*** custom scrollbar css end ***/
.privacy-inner {
  padding: 0 0 20px 20px;
}
.policy-content {
  padding: 30px 0 0 0;
}
/*** dashboard page css start ***/
/* .dashboard-main {
	height: 100vh;
	overflow: hidden;
} */
.dashboard-main .middle-content {
  width: calc(100% - 277px);
  padding-right: 30px;
}
.leftbar {
  background: #38417c;
  top: 18px;
  border-radius: 0 10px 10px 0;
  height: calc(100vh - 90px);
  min-height: calc(100vh - 90px);
  float: left;
  /* padding: 15px 10px 15px; */
}
.leftbar .logo {
  max-width: 132px;
  display: inline-block;
  vertical-align: middle;
  margin: 15px 10px 15px;
}
.rightbar {
  padding: 18px 30px 50px 277px;
  position: relative;
  background: #f4f9ff;
}
.dashboard-title {
  float: left;
}
.dashboard-title h2 {
  color: #2a3688;
}
.user-profile-right {
  float: right;
  position: relative;
}
.user-prof {
  background: url(../images/toggle.png) no-repeat center right;
  padding: 5px 20px 5px 0;
}
.user-prof img {
  vertical-align: middle;
}
.user-profile-right span {
  line-height: 16px;
  color: #052d64;
  padding-right: 10px;
}
.sky-btn {
  border-radius: 5px;
  background: #2a3688;
  color: #fff;
}
.sky-btn.btn-hover {
  background: #aed2ff;
  color: #052d64 !important;
}
.dashboard-top {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}
.white-box {
  box-shadow: 2px 2px 20px rgba(62, 132, 219, 0.08);
  background: #fff;
  border-radius: 5px;
}
.dash-content-wrapper {
  max-width: 70.4%;
  margin-top: 30px;
  padding-bottom: 20px;
}
.select-office h3 {
  color: #052d64;
  line-height: 28px;
  font-weight: 400;
  float: left;
  width: calc(100% - 159px);
  margin: 5px 20px 0 0;
}
.goto-office .common-btn span {
  font-weight: 700;
  background: url(../images/right-arrow.png) no-repeat right center;
  padding: 9px 42px 7px 0;
}
.goto-office .common-btn {
  padding: 12px 7px 12px 20px;
}
.select-office {
  border-top: 1px solid #e0e0e0;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  padding: 35px 30px;
}
.select-office:first-child {
  border-top: 0;
}
.goto-office .common-btn.btn-hover {
  color: #fff;
}
.middle-content {
  position: absolute;
  top: 50%;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
}
.dashboard-main-title h2 {
  font-weight: 700;
  font-size: 16px;
  line-height: 40px;
  color: #2a3688;
}
.dashboard-main-title h2 span {
  display: block;
  line-height: 16px;
  color: #052d64;
  font-weight: 400;
  font-size: 14px;
  margin-top: -2px;
}
.invitation .dashboard-main-title h2 {
  margin-bottom: 25px;
}
.invitation .white-box {
  padding: 22px 35px 22px 30px;
  min-height: 68px;
}
.invitation h4 {
  margin: 0 20px 0 0;
  width: calc(100% - 138px);
  float: left;
  line-height: 21px;
  font-weight: normal;
  color: #052d64;
}
.light-blue {
  background: #aed2ff;
  color: #052d64;
}
.light-blue.btn-hover {
  background: #052d64;
  color: #fff;
}
.invist-btns .common-btn {
  padding: 5px;
  min-width: 50px;
}
.invitation {
  padding: 47px 0 0 0;
}
.invist-btns ul li {
  margin-left: 7px;
  display: inline;
}
.create-new-office a {
  line-height: 16px;
  color: #343642;
  font-weight: 700;
  text-align: center;
  padding: 14px 11px 11px 11px;
  display: inline-block;
  width: 100%;
  vertical-align: middle;
}
.create-new-office a span {
  background: url(../images/create-new-btn.png) no-repeat;
  padding: 5px 0 5px 33px;
}
.create-new-office {
  margin: 40px 0 0 0;
  margin-bottom: 115px;
}
.create-new-office .white-box {
  float: left;
  margin-right: 25px;
}
#user-info {
  position: absolute;
  right: 0;
  top: 30px;
  background: #fff;
  box-shadow: none;
  width: 105px;
  text-align: center;
  z-index: 9;
  font-size: 13px;
  margin-top: 10px;
  margin: 18px auto 0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  display: none;
  border: 1px solid #e0e0e0;
  padding: 0 7px;
}
#user-info li {
  display: inline-block;
  width: 100%;
  position: relative;
  z-index: 99;
  vertical-align: middle;
  margin: 0;
  border-top: 1px solid #e0e0e0;
  padding: 3px 0;
}
#user-info li a {
  color: #333;
  text-transform: capitalize;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  padding: 7px 0;
  letter-spacing: 0.03em;
  font-size: 12px;
  line-height: 14px;
}
.invist-btns p a {
  color: #052d64;
  font-size: 13px;
  font-weight: 500;
  line-height: 15px;
  padding: 5px 0 0 0;
  float: right;
}
/* .close-box {
	position: absolute;
	right: 20px;
	top: 21px;
} */

.fancybox-button.fancybox-close-small svg {
  display: none;
}
.overlay .fancybox-bg {
  background: transparent;
}
.overlay .fancybox-slide--html .fancybox-button {
  padding: 0;
  top: 22px;
  right: -7px;
  opacity: 1;
  border: 0;
}
.create-new-office-popup .sub-heading h3 {
  font-size: 14px;
  line-height: 16px;
}
.fancybox-bg {
  background: #f4f9ff;
}
.box-with-shadow {
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.08);
}
.create-office-form .sub-heading {
  padding: 14px 15px 12px;
}
.small-popup {
  max-width: 464px;
}
.fancybox-can-pan .fancybox-content,
.fancybox-can-swipe .fancybox-content {
  cursor: inherit;
}
/* .create-new-office-popup {
	left: 123px;
} */
.office-heading p {
  color: #052d64;
  font-size: 14px;
  line-height: 20px;
}
.create-office-form .inner-forgot-pass {
  padding: 30px 20px 25px 20px;
}
.office-heading h3 {
  font-size: 24px;
  line-height: 28px;
  color: #052d64;
  font-weight: 400;
  margin-bottom: 10px;
}
.login-submit.ok .common-btn {
  font-size: 13px;
  line-height: 15px;
  color: #f2f2f2;
  text-transform: uppercase;
  min-width: 42px;
}
.login-submit.ok {
  margin-top: 0;
}
#create-new-office3 .create-office-form {
  min-width: 524px;
}
.fancybox-slide--html .fancybox-button {
  padding: 0;
  top: 15px;
  right: 15px;
  opacity: 1;
  border: 0;
}
.create-office-form form label {
  color: #333;
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
  width: 115px;
  padding-right: 20px;
  float: left;
  vertical-align: middle;
  margin: 9px 0 0 0;
}
.big-popup {
  max-width: 768px;
}
.create-office-form {
  margin-left: 244px;
}
.create-office-form .office-name .common-input {
  width: 233px;
}
/*** custom radio button css start ***/
[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
[type="checkbox"]:checked,
[type="checkbox"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 33px;
  cursor: pointer;
  color: #000;
  font-size: 16px;
  font-weight: 500;
}
[type="checkbox"]:checked + label,
[type="checkbox"]:not(:checked) + label {
  position: relative;
  padding-left: 33px;
  cursor: pointer;
  color: #000;
  font-size: 16px;
  font-weight: 500;
}
[type="radio"]:checked + label::before,
[type="radio"]:not(:checked) + label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 2px;
  width: 9px;
  height: 9px;
  border: 1px solid #4f4f4f;
  border-radius: 100%;
  background: #fff;
  margin: 0;
}
[type="checkbox"]:checked + label::before,
[type="checkbox"]:not(:checked) + label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 2px;
  width: 9px;
  height: 9px;
  border: 1px solid #4f4f4f;
  border-radius: 100%;
  background: #fff;
  margin: 0;
}
[type="radio"]:checked + label::after,
[type="radio"]:not(:checked) + label::after {
  content: "";
  width: 10px;
  height: 10px;
  background: #4f4f4f;
  position: absolute;
  top: 2px;
  left: 0;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
[type="checkbox"]:checked + label::after,
[type="checkbox"]:not(:checked) + label::after {
  content: "";
  width: 10px;
  height: 10px;
  background: #4f4f4f;
  position: absolute;
  top: 2px;
  left: 0;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
  opacity: 0;
}
[type="radio"]:checked + label:after {
  opacity: 1;
}
[type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
}
[type="checkbox"]:checked + label:after {
  opacity: 1;
}
/*** custom radio button css end ***/
.create-office-form .common-input {
  height: 35px;
  border: 1px solid #bdbdbd;
  width: 100%;
  color: #333;
  padding-left: 10px;
}
.member-main-form {
  background: #f4f9ff;
  border-radius: 5px;
  padding: 10px 10px 15px;
  float: left;
  width: calc(100% - 115px);
  margin: 22px 0 0 0;
}
.create-office-form .fields-row-main label {
  font-size: 12px;
  line-height: 14px;
  color: #333;
  font-weight: 400;
  margin-bottom: 10px;
}
.fields-row.half {
  float: left;
  width: 50%;
  padding: 0 11px;
}
.fields-row {
  padding: 0 10px;
}
.btns-group {
  background: #fff;
  border-radius: 5px;
  border: 1px solid #bdbdbd;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}
.create-office-form .fields-row-main .btns-group label {
  color: #4f4f4f;
  padding: 0 0 0 20px;
  text-transform: capitalize;
  margin: 0;
  width: auto;
  float: none;
  display: block;
}
.btns-group li {
  border-left: 1px solid #bdbdbd;
  display: inline-block;
  padding: 9px 20px;
  vertical-align: middle;
}
.no-border {
  border: 0 !important;
}
.fields-row-main {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  margin-bottom: 5px;
}
.create-office-form .fields-row-main .btns-group label {
  font-size: 14px;
  line-height: 16px;
}
.add-another {
  margin: 25px 0 0 115px;
  display: inline-block;
  vertical-align: middle;
}
.add-another button {
  background: url(../images/add-another.png) no-repeat;
  font-size: 13px;
  line-height: 15px;
  font-weight: 500;
  color: #4f4f4f;
  padding: 10px 0 10px 50px;
  border: 0;
}
.create-office-form.big-popup .login-submit .common-btn {
  min-width: 224px;
}
.add-another-fields {
  margin: 10px 0 25px 115px;
  padding-bottom: 0px;
  position: relative;
}
.invite-members > label {
  margin-top: 50px !important;
}
.close-fields {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 0px;
  top: 12px;
}
.close {
  background: url(../images/close.png) no-repeat;
  display: block;
  height: 8px;
  background-size: 8px;
}
/*** dashboard page css end ***/
/*** settings page css start ***/
.left-menu {
  margin: 110px 0 100px 0;
}
.left-menu li a {
  color: #f4f9ff;
  font-size: 16px;
  line-height: 19px;
  padding: 12px 13px 12px 23px;
  width: 100%;
  display: inline-block;
  vertical-align: middle;
  color: #f4f9ff;
  opacity: 0.3;
}
.left-menu li a span {
  padding-left: 31px;
}
.left-menu li a.btn-hover,
.left-menu li.active a {
  background-color: #3e4896;
  color: #fff;
  opacity: 1;
}
.patients {
  opacity: 1 !important;
}
.left-menu li.active a {
  font-size: 17px;
}
.left-menu li a.patients span {
  background: url(../images/patients.png) no-repeat left;
}
.left-menu li a.inbox span {
  background: url(../images/inbox.png) no-repeat left;
}
.left-menu li a.appointments span {
  background: url(../images/calendar.png) no-repeat left;
}
.left-menu li a.bulletin span {
  background: url(../images/bulletin.svg) no-repeat left;
}
.waiting-room {
  border-top: 1px solid #2a3688;
  border-bottom: 1px solid #2a3688;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}
.waiting-room a {
  font-size: 16px;
  line-height: 19px;
  color: #fff;
  padding: 12px 13px 12px 23px;
}
.waiting-room a:hover,
.waiting-room a:focus,
.waiting-room a.active {
  background-color: #3e4896;
}

.left-menu .hover-active {
  background-color: #3e4896;
}
.waiting-room a span {
  background: url(../images/waiting-room.svg) no-repeat left;
  padding-left: 31px;
}
.past-appointments a {
  font-size: 14px;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.7);
  padding: 12px 13px 12px 23px;
  width: 100%;
  display: inline-block;
  vertical-align: middle;
}
.past-appointments a:hover,
.past-appointments a:focus,
.past-appointments a.active {
  background: #3e4896;
}
.past-appointments a span {
  background: url(../images/clock.png) no-repeat left;
  padding-left: 20px;
}
.patients-table.white-box {
  padding: 20px;
}
.patients-table table tr {
  border-bottom: 1px solid #e0e0e0;
  background: #fff;
}
.patients-table table tr td {
  padding: 26px 5px;
}
.patients-table table tr:first-child {
  background: transparent;
}
table {
  width: 100%;
  border-collapse: collapse;
}
.patients-table table tr td {
  padding: 26px 27px;
}
table tr td {
  font-size: 14px;
  line-height: 16px;
  color: #333333;
}
.patients-table td:first-child {
  font-size: 16px;
}
.patients-table table th {
  text-align: left;
  font-size: 14px;
  line-height: 16px;
  color: #343642;
  padding: 0 30px;
  font-weight: 400;
  text-transform: capitalize;
}
.patients-table table tr:first-child th {
  padding-bottom: 20px;
}
.patients-table table tr:nth-child(2),
.patients-table table tr:last-child {
  box-shadow: 2px 2px 20px rgba(62, 132, 219, 0.08);
}
.patients-table table tr:nth-child(2) td:first-child,
.patients-table table tr:last-child td:first-child {
  border-radius: 5px 0 0 0 !important;
}
.patients-table table tr:nth-child(2) td:last-child,
.patients-table table tr:last-child td:last-child {
  border-radius: 0 5px 0 0 !important;
}
.loadmore {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  text-align: center;
  padding: 40px 0 0 0;
}
.loadmore a {
  background: url(../images/dots.svg) no-repeat center;
  display: block;
  height: 7px;
}
.middle-content.main-content-wrapper {
  position: static;
  transform: none;
  width: 100%;
  padding-right: 0;
  display: inline-block;
  vertical-align: middle;
}
.tabbing-main {
  background: #fff;
  border-radius: 5px;
  font-size: 13px;
  line-height: 15px;
  font-weight: 500;
  min-width: 246px;
  float: left;
  margin: 10px 25px 43px 0;
  overflow: hidden;
}
.tabbing-main li a.active-tab {
  background: #2a3688;
  color: #fff;
}
.tabbing-main li a {
  float: left;
  vertical-align: middle;
  width: 50%;
  text-align: center;
  color: #052d64;
  padding: 10px;
}
.tabbing-main li {
  display: inline;
}
.requested span {
  height: 6px;
  width: 6px;
  border-radius: 100%;
  background: #3e4896;
  margin: -3px 0 0 10px;
  display: inline-block;
  vertical-align: middle;
}
.search-form-main {
  margin: 10px 0 0 0;
  width: 341px;
  float: left;
}
.search-form {
  position: relative;
  background: #fff;
  height: 35px;
  border-radius: 3px;
  padding: 7px 0;
  padding-top: 0;
  padding-bottom: 0;
}
.search-form .form-control {
  border: 0;
  font-size: 14px;
  line-height: 16px;
  height: auto;
  padding-left: 38px;
  width: 100%;
  padding-right: 15px;
  color: #333;
}
.search-form input[type="submit"] {
  position: absolute;
  top: 11px;
  border: 0;
  left: 15px;
  background-color: transparent;
  width: 14px;
  height: 14px;
  background: url(../images/search.svg) no-repeat center left;
  padding: 0;
}
/*** patinets requested page css start ***/
.patients-table table td .common-btn {
  min-width: 65px;
  height: 25px;
  text-transform: capitalize;
  padding: 5px;
  margin-left: 5px;
}
.patients-table table td .common-btn:first-child {
  margin-left: 0;
}
.border-btn {
  border: 1px solid #828282;
  color: #828282;
}
.border-btn:hover,
.border-btn:focus {
  background: #828282;
  color: #fff;
}
/*** patinets confirmed page css start ***/
.patients-table table tr.patients-confirmed {
  background: #ffecb3;
  border-bottom: 1px solid #ffecb3;
  border-top: 2px solid #ffecb3;
}
.patients-table.white-box.confirmed-patients {
  padding: 0;
}
.patients-table table tr td.accepted-pt {
  color: #052d64;
}
/*** patients dwtails css start ***/
.patients-details-table .white-box {
  padding: 25px 25px 10px 25px;
}
.patients-details-table table {
  text-align: left;
}
.patients-details-table table th {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  width: 130px;
  padding-bottom: 15px;
  color: #333;
}
.patients-details-table table tr td {
  padding-bottom: 15px;
}
.patients-details-table table tr td a {
  color: #333;
}
.patients-details-table table tr td a:hover,
.patients-details-table table tr td a:focus {
  color: #2675da;
}
.patients-details-table table tr td {
  padding-bottom: 15px;
}
.patients-name {
  margin: 32px 0 0 0;
}
.patients-name h3 {
  line-height: 28px;
  color: #052d64;
  font-weight: 400;
}
.patients-details-table {
  margin: 33px 0 0 0;
}
.patients-details-table > h4 {
  font-size: 14px;
  line-height: 16px;
  color: #052d64;
  font-weight: 400;
}
/*** waiting room page css start ***/
.waiting-table.white-box table {
  text-align: left;
}
.waiting-table table th {
  color: #333;
  font-weight: 400;
  padding: 15px 10px 16px 50px;
}
.waiting-table table td,
.waiting-table table td a {
  font-size: 14px;
  line-height: 16px;
  color: #4f4f4f;
  padding: 0 10px;
}
.waititng-dots {
  height: 12px;
  width: 12px;
  border-radius: 100%;
  margin-right: 10px;
  float: left;
}
.dots-orange {
  background: #f2994a;
}
.dots-green {
  background: #27ae60;
}
.dots-red {
  background: #ce0000;
}
.waiting-table table tr {
  border-top: 1px solid rgba(224, 224, 224, 0.3);
}
.waiting-table table tr:first-child {
  border-top: 0;
}
.waiting-table table td.calls a {
  background: url(../images/phone.svg) no-repeat 10px center;
  color: #052d64;
  padding-left: 30px;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  height: 30px;
  display: block;
}
.waiting-table table td.calls a span {
  border-left: 1px solid #e0e0e0;
  padding: 6px 0 6px 0;
  height: 30px;
  display: block;
  text-align: center;
}
.msg-patient a {
  background: url(../images/msg.svg) no-repeat;
  display: block;
  width: 18px;
  position: relative;
  height: 16px;
}
.patient-file a {
  background: url(../images/file.svg) no-repeat;
  width: 16px;
  height: 17px;
  display: block;
}
.gear-file a {
  background: url(../images/gear.png) no-repeat;
  width: 16px;
  height: 17px;
  display: block;
}
.left-sec {
  width: 70.4%;
  float: left;
}
.waiting-table.white-box {
  padding: 5px;
}
.waiting-table table td.close-waiting a {
  background: url(../images/close-icon.svg) no-repeat center center;
  width: 10px;
  height: 10px;
  display: block;
  border-left: 1px solid #e0e0e0;
  height: 35px;
  padding: 0 18px;
}
.waiting-table .custom-scrl-content {
  height: 142px;
}
.waiting-table .mCSB_inside > .mCSB_container {
  margin-right: 0;
}
.waiting-table .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  height: 30px;
}
.waiting-table .short-name {
  height: 24px;
  width: 24px;
  line-height: 22px;
  margin-left: -40px;
}
.waiting-table table th.withshort-name {
  padding: 11px 10px 12px 50px;
}
.withshort-name span {
  padding: 3px 0 0 0;
  display: block;
}
.waiting-title h3 {
  font-size: 14px;
  line-height: 16px;
  color: #052d64;
  font-weight: 400;
  margin-bottom: 10px;
}
.video-call-content {
  margin: 30px 0 0 0;
}
.videcall-img img {
  width: 100%;
}
.waitting-tabbing {
  background: #fff;
  border-radius: 5px;
  font-size: 13px;
  line-height: 15px;
  font-weight: 500;
  width: 100%;
  float: left;
  margin: 0px 0 15px 0;
  overflow: hidden;
}
.waitting-tabbing li {
  display: inline;
}
.waitting-tabbing li.active-tabs a {
  background: #0c4491;
  color: #fff;
}
.waitting-tabbing li a {
  float: left;
  vertical-align: middle;
  width: 50%;
  text-align: center;
  color: #0c4491;
  padding: 10px;
}
.right-sec {
  float: left;
  width: 29.6%;
  padding-left: 30px;
}
.patients-info-main {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  height: 100%;
}
.patients-info-heading {
  padding: 15px 18px;
  border-bottom: 1px solid #e0e0e0;
  display: inline-block;
  width: 100%;
  vertical-align: middle;
}
.patients-info-heading h3 {
  font-size: 16px;
  line-height: 19px;
  color: #0c4491;
  font-weight: 700;
  float: left;
  width: calc(100% - 17px);
  padding-right: 15px;
  margin-bottom: 0;
}
.patients-title {
  background: rgba(244, 249, 255, 0.5);
  padding: 12px 18px;
  margin-bottom: 5px;
}
.patients-title h4 {
  font-size: 12px;
  line-height: 14px;
  color: #0c4491;
  font-weight: 700;
  margin-bottom: 0;
}
.patients-info label {
  font-size: 16px;
  line-height: 19px;
  width: 100%;
  color: #333;
  float: left;
  margin-bottom: 11px;
}
.patients-info {
  padding: 15px 25px 15px 15px;
}
.patients-info ul li .p-info {
  padding: 0 0 0 15px;
}
.patients-info .common-input {
  border: 1px solid #e0e0e0;
  width: 100%;
  min-height: 65px;
  padding: 15px;
}
.patients-info-main [type="radio"]:checked + label,
.patients-info-main [type="radio"]:not(:checked) + label {
  padding-left: 20px;
  color: #333;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
}
.patients-info-main [type="checkbox"]:checked + label,
.patients-info-main [type="checkbox"]:not(:checked) + label {
  padding-left: 20px;
  color: #333;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
}
.patients-info-main [type="radio"]:checked + label::before,
.patients-info-main [type="radio"]:not(:checked) + label::before {
  border: 1px solid #828282;
  background: #bdbdbd;
}
.patients-info-main [type="checkbox"]:checked + label::before,
.patients-info-main [type="checkbox"]:not(:checked) + label::before {
  border: 1px solid #828282;
  background: #bdbdbd;
}
.patients-info li {
  margin-top: 10px;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}
.patients-info li:first-child {
  margin-top: 0;
}
.patients-info-main .custom-scrl-content {
  height: 100%;
}
.patients-info-main .mCSB_inside > .mCSB_container {
  margin-right: 0;
}
.waittingroom-main .middle-content {
  margin: 15px 0 0 0;
  padding-bottom: 30px;
}
.patients-info-row {
  margin-top: 10px;
}
.patients-info-row:first-child {
  margin-top: 0;
}
.videcall-img > img {
  border-radius: 5px;
}
.waiting-table table td.calls.end-call a {
  background: #ce0000 url(../images/phone-white.svg) no-repeat 10px center;
  color: #fff;
  border: 1px solid #ce0000;
}
.waiting-table table td.calls.end-call a span {
  border-left: 1px solid #fff;
}
.waiting-table table td.calls.rejoin-call a {
  background-color: #ffecb3;
  border: 1px solid #ffecb3;
}
.waiting-table table td.calls.rejoin-call a span {
  border-left: 1px solid #fff;
}
.waitingroom-full-img img {
  width: 100%;
}
.waitingroom-full-img {
  background: url(../images/full-img.png) no-repeat center center;
  background-size: contain;
}
.chatbox {
  padding: 12px;
  border-radius: 5px;
  word-break: break-all;
  float: left;
  width: 100%;
  position: relative;
  margin-bottom: 5px;
}
.chatbox p {
  color: #fff;
  line-height: 16px;
  padding-bottom: 0;
}
.chat-time {
  font-size: 10px;
  line-height: 13px;
  color: #4f4f4f;
}
.short-name {
  border-radius: 100%;
  height: 30px;
  width: 30px;
  background: #aed2ff;
  color: #0c4491;
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  float: left;
  line-height: 32px;
  text-transform: uppercase;
}
.blue-bx {
  background: #2675da;
}
.grey-bx {
  background: #f2f2f2;
}
.grey-bx p {
  color: #052d64;
}
/* .chat-text:nth-child(2n + 1) {
  margin-left: 50px;
  margin-right: 40px;
} */

.right {
  margin-left: 50px;
  margin-right: 40px;
}

.left {
  margin-right: 60px;
  margin-left: 40px;
}

/* .chat-text:nth-child(2n) {
  margin-right: 60px;
  margin-left: 40px;
} */
.chat-content {
  padding: 15px;
  position: relative;
}
/* .chat-text:nth-child(odd) .chat-time {
  text-align: right;
  display: block;
} */
.chat-text .short-name {
  position: absolute;
  left: -38px;
  bottom: 0;
  right: auto;
}
.right .short-name {
  right: -38px !important;
  left: auto !important;
}
.chat-text {
  margin: 25px 50px 0;
}
.chat-text:first-child {
  margin-top: 10px;
}
.type-msg .common-input {
  font-size: 13px;
  color: #828282;
  border: 1px solid #e0e0e0;
  width: 100%;
  letter-spacing: 0.05em;
}
.type-msg {
  margin-top: 135px;
}
/*** past appointment page css start ***/
.select-multiple-main {
  float: left;
  min-width: 135px;
  height: 35px;
  margin: 10px 20px 0 20px;
}
.select-multiple-main a {
  line-height: 16px;
  background: #fff;
  color: rgba(0, 3, 51, 0.5);
  width: 100%;
  display: block;
  text-align: center;
  height: 35px;
  padding: 10px;
  border-radius: 5px;
}
.select-multiple-main a:hover,
.select-multiple-main a:focus {
  background: #052d64;
  color: #fff;
}
.appointment-table {
  margin: 45px 0 0 0;
}
/*** custom checkbox button css start ***/
.checkbox-custom {
  display: none;
}
.checkbox-custom-label {
  position: relative;
  cursor: pointer;
}
.checkbox-custom + .checkbox-custom-label:before {
  content: "";
  background: transparent;
  border-radius: 2px;
  border: 1px solid #bdbdbd;
  display: inline-block;
  vertical-align: middle;
  width: 8px;
  height: 8px;
  margin-right: 20px;
  text-align: center;
  margin-top: -2px;
}
.checkbox-custom:checked + .checkbox-custom-label::after {
  content: "";
  text-align: center;
  position: absolute;
  width: 9px;
  height: 9px;
  top: 5px;
  left: 1px;
  background: #828282;
}
/*** custom checkbox button css end ***/
.appointment-table table th .checkbox-custom + .checkbox-custom-label::before {
  border: 1px solid #052d64;
}
.appointment-table
  table
  th
  .checkbox-custom:checked
  + .checkbox-custom-label::after {
  top: 4px;
  background: #052d64;
}
.export-delete .common-btn {
  min-width: 65px;
  height: 25px;
  padding: 5px;
  text-transform: capitalize;
  margin-left: 9px;
}
.export-delete .common-btn:first-child {
  margin-left: 0;
}
.export-delete {
  float: left;
  margin: 15px 0 0 0;
}
.patients-export-option .patients-name {
  float: left;
  width: calc(100% - 100px);
  padding-right: 20px;
  margin: 1px 0 0 0;
}
.exports .common-btn {
  min-width: 95px;
  text-transform: capitalize;
  text-align: center;
}
.exports .common-btn span {
  background-image: url(../images/dropdown-arrow.svg);
  background-repeat: no-repeat;
  background-position: right center;
  padding-right: 16px;
  transition: 0.4s;
}
.exports .common-btn.btn-hover:hover,
.exports .common-btn.btn-hover:focus {
  color: #fff !important;
}
.past-appoint-data {
  padding-top: 15px;
  padding-bottom: 25px;
}
.past-appoint-data .patients-info {
  padding: 15px 60px 15px 30px;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}
.patients-data {
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  line-height: 16px;
  color: #333;
  padding: 15px;
  width: 100%;
  float: left;
}
.patients-data p {
  line-height: 16px;
  color: #333;
  padding-bottom: 15px;
}
.physical-exam > strong {
  text-transform: uppercase;
  padding-bottom: 10px;
  display: block;
}
.patients-export-option {
  padding: 30px 0 30px 0;
}
.appointment-data p {
  padding-top: 10px;
  clear: both;
}
.appointment-data .patients-info {
  padding-left: 25px;
}
.patients-info [type="radio"]:checked + label::after,
.patients-info [type="radio"]:not(:checked) + label::after {
  background: #fff;
  border: 1px solid #828282;
}
/*** past appointment page css end ***/
/*** edit office page css start ***/
select.common-input {
  color: #4f4f4f;
  background: url(../images/select-arrow.svg) no-repeat 95%;
  cursor: pointer;
}
.member-main-form.member-main-table {
  background: transparent;
  padding: 0;
}
.member-main-form.member-main-table table {
  border-spacing: 0 10px;
  border-collapse: unset;
}
.member-main-table table tr {
  background: #f4f9ff;
}
.member-main-table table td {
  padding: 10px 15px;
  height: 45px;
}
.close-member {
  background: url(../images/close-grey.svg) no-repeat;
  height: 10px;
  width: 20px;
  display: block;
}
.edit-office-main .common-btn {
  min-width: auto;
}
#edit-office .common-btn {
  min-width: auto !important;
  margin-left: 10px;
}
#edit-office .custom-scrl-content {
  height: 221px;
}
#edit-office .inner-forgot-pass {
  padding-right: 0;
}
#edit-office .login-submit {
  padding-right: 30px;
}
#edit-office .office-name {
  padding-right: 30px;
}

#create-new-office {
  padding: 0;
}

.create-office-form {
  padding: 0;
  margin-left: 0;
}

/*** new added css start ***/
/*** waiting room page css start ***/
.waiting-table.white-box table {
  text-align: left;
}
.waiting-table table th {
  color: #333;
  font-weight: 400;
  padding: 15px 10px 16px 50px;
}
.waiting-table table td,
.waiting-table table td a {
  font-size: 14px;
  line-height: 16px;
  color: #4f4f4f;
  padding: 0 10px;
}
.waititng-dots {
  height: 12px;
  width: 12px;
  border-radius: 100%;
  margin-right: 10px;
  float: left;
}
.dots-orange {
  background: #f2994a;
}
.dots-green {
  background: #27ae60;
}
.dots-red {
  background: #ce0000;
}
.waiting-table table tr {
  border-top: 1px solid rgba(224, 224, 224, 0.3);
}
.waiting-table table tr:first-child {
  border-top: 0;
}
.waiting-table table td.calls a {
  background: url(../images/phone.svg) no-repeat 10px center;
  color: #052d64;
  padding-left: 30px;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  height: 30px;
  display: block;
}
.waiting-table table td.calls a span {
  border-left: 1px solid #e0e0e0;
  padding: 6px 0 6px 0;
  height: 30px;
  display: block;
  text-align: center;
}
.msg-patient a {
  background: url(../images/msg.svg) no-repeat;
  display: block;
  width: 18px;
  height: 16px;
}
.patient-file a {
  background: url(../images/file.svg) no-repeat;
  width: 16px;
  height: 17px;
  display: block;
}
.left-sec {
  width: 70.4%;
  float: left;
}
.waiting-table.white-box {
  padding: 5px;
}
.waiting-table table td.close-waiting a {
  background: url(../images/close-icon.svg) no-repeat center center;
  width: 10px;
  height: 10px;
  display: block;
  border-left: 1px solid #e0e0e0;
  height: 35px;
  padding: 0 18px;
}
.waiting-table .custom-scrl-content {
  height: 142px;
}
.waiting-table .mCSB_inside > .mCSB_container {
  margin-right: 0;
}
.waiting-table .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  height: 30px;
}
.waiting-table .short-name {
  height: 24px;
  width: 24px;
  line-height: 22px;
  margin-left: -40px;
}
.waiting-table table th.withshort-name {
  padding: 11px 10px 12px 50px;
}
.withshort-name span {
  padding: 3px 0 0 0;
  display: block;
}
.waiting-title h3 {
  font-size: 14px;
  line-height: 16px;
  color: #052d64;
  font-weight: 400;
  margin-bottom: 10px;
}
.video-call-content {
  margin: 30px 0 0 0;
}
.videcall-img img {
  width: 100%;
}
.waitting-tabbing {
  background: #fff;
  border-radius: 5px;
  font-size: 13px;
  line-height: 15px;
  font-weight: 500;
  width: 100%;
  float: left;
  margin: 0px 0 15px 0;
  overflow: hidden;
}
.waitting-tabbing li {
  display: inline;
}
.waitting-tabbing li.active-tabs a {
  background: #0c4491;
  color: #fff;
}
.waitting-tabbing li a {
  float: left;
  vertical-align: middle;
  width: 50%;
  text-align: center;
  color: #0c4491;
  padding: 10px;
}
.right-sec {
  float: left;
  width: 29.6%;
  padding-left: 30px;
}
.patients-info-main {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  /* height: 100%; */
}
.patients-info-heading {
  padding: 15px 18px;
  border-bottom: 1px solid #e0e0e0;
  display: inline-block;
  width: 100%;
  vertical-align: middle;
}
.patients-info-heading h3 {
  font-size: 16px;
  line-height: 19px;
  color: #0c4491;
  font-weight: 700;
  float: left;
  width: calc(100% - 17px);
  padding-right: 15px;
  margin-bottom: 0;
}
.patients-title {
  background: rgba(244, 249, 255, 0.5);
  padding: 12px 18px;
  margin-bottom: 5px;
}
.patients-title h4 {
  font-size: 12px;
  line-height: 14px;
  color: #0c4491;
  font-weight: 700;
  margin-bottom: 0;
}
.patients-info label {
  font-size: 16px;
  line-height: 19px;
  width: 100%;
  color: #333;
  float: left;
  margin-bottom: 11px;
}
.patients-info {
  padding: 15px 25px 15px 15px;
}
.patients-info ul li .p-info {
  padding: 0 0 0 15px;
}
.patients-info .common-input {
  border: 1px solid #e0e0e0;
  width: 100%;
  min-height: 65px;
  padding: 15px;
}
.patients-info-main [type="radio"]:checked + label,
.patients-info-main [type="radio"]:not(:checked) + label {
  padding-left: 20px;
  color: #333;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
}
.patients-info-main [type="checkbox"]:checked + label,
.patients-info-main [type="checkbox"]:not(:checked) + label {
  padding-left: 20px;
  color: #333;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
}
.patients-info-main [type="radio"]:checked + label::before,
.patients-info-main [type="radio"]:not(:checked) + label::before {
  border: 1px solid #828282;
  background: #bdbdbd;
}
.patients-info-main [type="checkbox"]:checked + label::before,
.patients-info-main [type="checkbox"]:not(:checked) + label::before {
  border: 1px solid #828282;
  background: #bdbdbd;
}
.patients-info li {
  margin-top: 10px;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}
.patients-info li:first-child {
  margin-top: 0;
}
.patients-info-main .custom-scrl-content {
  height: 520px;
  /* height: 100%; */
}
.patients-info-main .mCSB_inside > .mCSB_container {
  margin-right: 0;
}
.waittingroom-main .middle-content {
  margin: 15px 0 0 0;
  padding-bottom: 30px;
}
.patients-info-row {
  margin-top: 10px;
}
.patients-info-row:first-child {
  margin-top: 0;
}
.videcall-img > img {
  border-radius: 5px;
}
.waiting-table table td.calls.end-call a {
  background: #ce0000 url(../images/phone-white.svg) no-repeat 10px center;
  color: #fff;
  border: 1px solid #ce0000;
}
.waiting-table table td.calls.end-call a span {
  border-left: 1px solid #fff;
}
.waiting-table table td.calls.rejoin-call a {
  background-color: #ffecb3;
  border: 1px solid #ffecb3;
}
.waiting-table table td.calls.rejoin-call a span {
  border-left: 1px solid #fff;
}
.waitingroom-full-img img {
  width: 100%;
}
.waitingroom-full-img {
  background: url(../images/full-img.png) no-repeat center center;
  background-size: contain;
}
.chatbox {
  padding: 12px;
  border-radius: 5px;
  float: left;
  width: 100%;
  position: relative;
  margin-bottom: 5px;
}
.chatbox p {
  color: #fff;
  line-height: 16px;
  padding-bottom: 0;
}
.chat-time {
  font-size: 10px;
  line-height: 13px;
  color: #4f4f4f;
}
.short-name {
  border-radius: 100%;
  height: 30px;
  width: 30px;
  background: #aed2ff;
  color: #0c4491;
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  float: left;
  line-height: 32px;
  text-transform: uppercase;
}
.blue-bx {
  background: #2675da;
}
.grey-bx {
  background: #f2f2f2;
}
.grey-bx p {
  color: #052d64;
}
/* .chat-text:nth-child(2n + 1) {
  margin-left: 50px;
  margin-right: 40px;
} */

.right {
  margin-left: 50px;
  margin-right: 40px;
}

.left {
  margin-right: 60px;
  margin-left: 40px;
}

/* .chat-text:nth-child(2n) {
  margin-right: 60px;
  margin-left: 40px;
} */
.chat-content {
  padding: 15px;
  position: relative;
}
.right .chat-time {
  text-align: right;
  display: block;
}

.left .chat-time {
  text-align: left !important;
}
.chat-text .short-name {
  position: absolute;
  left: -38px;
  bottom: 0;
  right: auto;
}
.right .short-name {
  right: -38px;
  left: auto;
}
.chat-text {
  margin: 25px 50px 0;
}
.chat-text:first-child {
  margin-top: 10px;
}
.type-msg .common-input {
  font-size: 13px;
  color: #828282;
  border: 1px solid #e0e0e0;
  width: 100%;
  letter-spacing: 0.05em;
}
.type-msg {
  /* margin-top: 135px; */
  margin-top: 0 !important;
}
/*** past appointment page css start ***/
.select-multiple-main {
  float: left;
  min-width: 135px;
  height: 35px;
  margin: 10px 20px 0 20px;
}
.select-multiple-main a {
  line-height: 16px;
  background: #fff;
  color: rgba(0, 3, 51, 0.5);
  width: 100%;
  display: block;
  text-align: center;
  height: 35px;
  padding: 10px;
  border-radius: 5px;
}
.select-multiple-main a:hover,
.select-multiple-main a:focus {
  background: #052d64;
  color: #fff;
}
.appointment-table {
  margin: 45px 0 0 0;
}
/*** custom checkbox button css start ***/
.checkbox-custom {
  display: none;
}
.checkbox-custom-label {
  position: relative;
  cursor: pointer;
}
.checkbox-custom + .checkbox-custom-label:before {
  content: "";
  background: transparent;
  border-radius: 2px;
  border: 1px solid #bdbdbd;
  display: inline-block;
  vertical-align: middle;
  width: 8px;
  height: 8px;
  margin-right: 20px;
  text-align: center;
  margin-top: -2px;
}
.checkbox-custom:checked + .checkbox-custom-label::after {
  content: "";
  text-align: center;
  position: absolute;
  width: 9px;
  height: 9px;
  top: 5px;
  left: 1px;
  background: #828282;
}
/*** custom checkbox button css end ***/
.appointment-table table th .checkbox-custom + .checkbox-custom-label::before {
  border: 1px solid #052d64;
}
.appointment-table
  table
  th
  .checkbox-custom:checked
  + .checkbox-custom-label::after {
  top: 4px;
  background: #052d64;
}
.export-delete .common-btn {
  min-width: 65px;
  height: 25px;
  padding: 5px;
  text-transform: capitalize;
  margin-left: 9px;
}
.export-delete .common-btn:first-child {
  margin-left: 0;
}
.export-delete {
  float: left;
  margin: 15px 0 0 0;
}
.patients-export-option .patients-name {
  float: left;
  width: calc(100% - 100px);
  padding-right: 20px;
  margin: 1px 0 0 0;
}
.exports .common-btn {
  min-width: 95px;
  text-transform: capitalize;
  text-align: center;
}
.exports .common-btn span {
  background-image: url(../images/dropdown-arrow.svg);
  background-repeat: no-repeat;
  background-position: right center;
  padding-right: 16px;
  transition: 0.4s;
}
.exports .common-btn.btn-hover:hover,
.exports .common-btn.btn-hover:focus {
  color: #fff !important;
}
.past-appoint-data {
  padding-top: 15px;
  padding-bottom: 25px;
}
.past-appoint-data .patients-info {
  padding: 15px 60px 15px 30px;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}
.patients-data {
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  line-height: 16px;
  color: #333;
  padding: 15px;
  width: 100%;
  float: left;
}
.patients-data p {
  line-height: 16px;
  color: #333;
  padding-bottom: 15px;
}
.physical-exam > strong {
  text-transform: uppercase;
  padding-bottom: 10px;
  display: block;
}
.patients-export-option {
  padding: 30px 0 30px 0;
}
.appointment-data p {
  padding-top: 10px;
  clear: both;
}
.appointment-data .patients-info {
  padding-left: 25px;
}
.patients-info [type="radio"]:checked + label::after,
.patients-info [type="radio"]:not(:checked) + label::after {
  background: #fff;
  border: 1px solid #828282;
}
.d-flex {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
}
.eql-height {
  background: #fff;
  height: 100%;
}
/*** past appointment page css end ***/
/*** new added css end ***/

#video-area video {
  /* width: 100%; */
}

.participant video {
  border-radius: 8px;
}

.chat {
  /* height: 600px; */
  height: 450px;
  /* overflow: scroll; */
  /* scroll-snap-type: y mandatory; */
}

.patients-info textarea {
  height: 100px;
  resize: none;
}

.react-select-container {
  padding-left: 35px;
}

.react-select__control {
  border: 0 !important;
}

.incall-middle {
  overflow: hidden;
  border-radius: 8px;
}

.incall-middle video {
  transform-origin: center;
  transform: scale(162%);
}
/* .box-img .sb-avatar__text {
  line-height: initial !important;
  text-align: center !important;
  color: rgb(255, 255, 255) !important;
  background: rgb(66, 133, 244) none repeat scroll 0% 0% !important;
  border-radius: 100% !important;
  height: 100% !important;
  width: 100% !important;
  object-fit: cover !important;
}
.box-img .sb-avatar__text div {
  font-size: 20px;
} */

.remote-participant {
  position: relative;
}

.local-participant {
  position: absolute;
  width: 18%;
  right: 5%;
  top: 5%;
}
.chatEnd {
  scroll-snap-align: end;
}

.toast-background {
  border-radius: 8px !important;
  font-family: "Roboto", sans-serif;
  color: #343642;
}

.fancy-progress-bar {
  background: #2a3688 !important;
}

.form-input {
  border: 1px solid #e0e0e0;

  width: 100%;

  min-height: 65px;

  padding: 15px;

  margin-bottom: 20px;
}

.input-span {
  color: #333;

  font-size: 14px;

  line-height: 16px;

  font-weight: 400;
  padding-bottom: 5px;
}

.input-label {
  margin-bottom: 0 !important;
}

table .react-select-container {
  font-size: 11px;
}

#edit-office {
  padding: 0px;
  border-radius: 8px;
}

/*** new added css start ***/
/*** waiting room page css start ***/
.waiting-table.white-box table {
  text-align: left;
}
.waiting-table table th {
  color: #333;
  font-weight: 400;
  padding: 15px 10px 16px 50px;
}
.waiting-table table td,
.waiting-table table td a {
  font-size: 14px;
  line-height: 16px;
  color: #4f4f4f;
  padding: 0 10px;
}
.waititng-dots {
  height: 12px;
  width: 12px;
  border-radius: 100%;
  margin-right: 10px;
  float: left;
}
.dots-orange {
  background: #f2994a;
}
.dots-green {
  background: #27ae60;
}
.dots-red {
  background: #ce0000;
}
.waiting-table table tr {
  border-top: 1px solid rgba(224, 224, 224, 0.3);
}
.waiting-table table tr:first-child {
  border-top: 0;
}
.waiting-table table td.calls a {
  background: url(../images/phone.svg) no-repeat 10px center;
  color: #052d64;
  padding-left: 30px;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  height: 30px;
  display: block;
}
.waiting-table table td.calls a span {
  border-left: 1px solid #e0e0e0;
  padding: 6px 0 6px 0;
  height: 30px;
  display: block;
  text-align: center;
}
.msg-patient a {
  background: url(../images/msg.svg) no-repeat;
  display: block;
  width: 18px;
  height: 16px;
}
.patient-file a {
  background: url(../images/file.svg) no-repeat;
  width: 16px;
  height: 17px;
  display: block;
}
.left-sec {
  width: 70.4%;
  float: left;
}
.waiting-table.white-box {
  padding: 5px;
}
.waiting-table table td.close-waiting a {
  background: url(../images/close-icon.svg) no-repeat center center;
  width: 10px;
  height: 10px;
  display: block;
  border-left: 1px solid #e0e0e0;
  height: 35px;
  padding: 0 18px;
}
.waiting-table .custom-scrl-content {
  height: 142px;
}
.waiting-table .mCSB_inside > .mCSB_container {
  margin-right: 0;
}
.waiting-table .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  height: 30px;
}
.waiting-table .short-name {
  height: 24px;
  width: 24px;
  line-height: 22px;
  margin-left: -40px;
}
.waiting-table table th.withshort-name {
  padding: 11px 10px 12px 50px;
}
.withshort-name span {
  padding: 3px 0 0 0;
  display: block;
}
.waiting-title h3 {
  font-size: 14px;
  line-height: 16px;
  color: #052d64;
  font-weight: 400;
  margin-bottom: 10px;
}
.video-call-content {
  margin: 30px 0 0 0;
}
.videcall-img img {
  width: 100%;
}
.waitting-tabbing {
  background: #fff;
  border-radius: 5px;
  font-size: 13px;
  line-height: 15px;
  font-weight: 500;
  width: 100%;
  float: left;
  margin: 0px 0 15px 0;
  overflow: hidden;
}
.waitting-tabbing li {
  display: inline;
}
.waitting-tabbing li.active-tabs a {
  background: #0c4491;
  color: #fff;
}
.waitting-tabbing li a {
  float: left;
  vertical-align: middle;
  width: 50%;
  text-align: center;
  color: #0c4491;
  padding: 10px;
}
.right-sec {
  float: left;
  width: 29.6%;
  padding-left: 30px;
}
.patients-info-main {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  /* height: 100%; */
}
.patients-info-heading {
  padding: 15px 18px;
  border-bottom: 1px solid #e0e0e0;
  display: inline-block;
  width: 100%;
  vertical-align: middle;
}
.patients-info-heading h3 {
  font-size: 16px;
  line-height: 19px;
  color: #0c4491;
  font-weight: 700;
  float: left;
  width: calc(100% - 17px);
  padding-right: 15px;
  margin-bottom: 0;
}
.patients-title {
  background: rgba(244, 249, 255, 0.5);
  padding: 12px 18px;
  margin-bottom: 5px;
}
.patients-title h4 {
  font-size: 12px;
  line-height: 14px;
  color: #0c4491;
  font-weight: 700;
  margin-bottom: 0;
}
.patients-info label {
  font-size: 16px;
  line-height: 19px;
  width: 100%;
  color: #333;
  float: left;
  margin-bottom: 11px;
}
.patients-info {
  padding: 15px 25px 15px 15px;
}
.patients-info ul li .p-info {
  padding: 0 0 0 15px;
}
.patients-info .common-input {
  border: 1px solid #e0e0e0;
  width: 100%;
  min-height: 65px;
  padding: 15px;
}
.patients-info-main [type="radio"]:checked + label,
.patients-info-main [type="radio"]:not(:checked) + label {
  padding-left: 20px;
  color: #333;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
}
.patients-info-main [type="radio"]:checked + label::before,
.patients-info-main [type="radio"]:not(:checked) + label::before {
  border: 1px solid #828282;
  background: #bdbdbd;
}
.patients-info li {
  margin-top: 10px;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}
.patients-info li:first-child {
  margin-top: 0;
}
.patients-info-main .custom-scrl-content {
  height: 520px;
}
.patients-info-main .mCSB_inside > .mCSB_container {
  margin-right: 0;
}
.waittingroom-main .middle-content {
  margin: 15px 0 0 0;
  padding-bottom: 30px;
}
.patients-info-row {
  margin-top: 10px;
}
.patients-info-row:first-child {
  margin-top: 0;
}
.videcall-img > img {
  border-radius: 5px;
}
.waiting-table table td.calls.end-call a {
  background: #ce0000 url(../images/phone-white.svg) no-repeat 10px center;
  color: #fff;
  border: 1px solid #ce0000;
}
.waiting-table table td.calls.end-call a span {
  border-left: 1px solid #fff;
}
.waiting-table table td.calls.rejoin-call a {
  background-color: #ffecb3;
  border: 1px solid #ffecb3;
}
.waiting-table table td.calls.rejoin-call a span {
  border-left: 1px solid #fff;
}
.waitingroom-full-img img {
  width: 100%;
}
.waitingroom-full-img {
  background: url(../images/full-img.png) no-repeat center center;
  background-size: contain;
}
.chatbox {
  padding: 12px;
  border-radius: 5px;
  float: left;
  width: 100%;
  position: relative;
  margin-bottom: 5px;
}
.chatbox p {
  color: #fff;
  line-height: 16px;
  padding-bottom: 0;
}
.chat-time {
  font-size: 10px;
  line-height: 13px;
  color: #4f4f4f;
}
.short-name {
  border-radius: 100%;
  height: 30px;
  width: 30px;
  background: #aed2ff;
  color: #0c4491;
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  float: left;
  line-height: 32px;
  text-transform: uppercase;
}
.blue-bx {
  background: #2675da;
}
.grey-bx {
  background: #f2f2f2;
}
.grey-bx p {
  color: #052d64;
}
.chat-text:nth-child(2n + 1) {
  margin-left: 50px;
  /* margin-right: 40px; */
}
/* .chat-text:nth-child(2n) {
  margin-right: 60px;
  margin-left: 40px;
} */
.chat-content {
  padding: 15px;
  position: relative;
}
.right-side .chat-time {
  text-align: right;
  display: block;
}
.chat-text .short-name {
  position: absolute;
  left: -38px;
  bottom: 0;
  right: auto;
}
.dark-blue .short-name {
  right: -38px;
  left: auto;
}
.chat-text {
  margin: 25px 50px 0;
}
.chat-text:first-child {
  margin-top: 10px;
}
.type-msg .common-input {
  font-size: 13px;
  color: #828282;
  border: 1px solid #e0e0e0;
  width: 100%;
  letter-spacing: 0.05em;
}
.type-msg {
  margin-top: 135px;
}
/*** past appointment page css start ***/
.select-multiple-main {
  float: left;
  min-width: 135px;
  height: 35px;
  margin: 10px 20px 0 20px;
}
.select-multiple-main a {
  line-height: 16px;
  background: #fff;
  color: rgba(0, 3, 51, 0.5);
  width: 100%;
  display: block;
  text-align: center;
  height: 35px;
  padding: 10px;
  border-radius: 5px;
}
.select-multiple-main a:hover,
.select-multiple-main a:focus {
  background: #052d64;
  color: #fff;
}
.appointment-table {
  margin: 45px 0 0 0;
}
/*** custom checkbox button css start ***/
.checkbox-custom {
  display: none;
}
.checkbox-custom-label {
  position: relative;
  cursor: pointer;
}
.checkbox-custom + .checkbox-custom-label:before {
  content: "";
  background: transparent;
  border-radius: 2px;
  border: 1px solid #bdbdbd;
  display: inline-block;
  vertical-align: middle;
  width: 8px;
  height: 8px;
  margin-right: 20px;
  text-align: center;
  margin-top: -2px;
}
.checkbox-custom:checked + .checkbox-custom-label::after {
  content: "";
  text-align: center;
  position: absolute;
  width: 9px;
  height: 9px;
  top: 5px;
  left: 1px;
  background: #828282;
}
/*** custom checkbox button css end ***/
.appointment-table table th .checkbox-custom + .checkbox-custom-label::before {
  border: 1px solid #052d64;
}
.appointment-table
  table
  th
  .checkbox-custom:checked
  + .checkbox-custom-label::after {
  top: 4px;
  background: #052d64;
}
.export-delete .common-btn {
  min-width: 65px;
  height: 25px;
  padding: 5px;
  text-transform: capitalize;
  margin-left: 9px;
}
.export-delete .common-btn:first-child {
  margin-left: 0;
}
.export-delete {
  float: left;
  margin: 15px 0 0 0;
}
.patients-export-option .patients-name {
  float: left;
  width: calc(100% - 100px);
  padding-right: 20px;
  margin: 1px 0 0 0;
}
.exports .common-btn {
  min-width: 95px;
  text-transform: capitalize;
  text-align: center;
}
.exports .common-btn span {
  background-image: url(../images/dropdown-arrow.svg);
  background-repeat: no-repeat;
  background-position: right center;
  padding-right: 16px;
  transition: 0.4s;
}
.exports .common-btn.btn-hover:hover,
.exports .common-btn.btn-hover:focus {
  color: #fff !important;
}
.past-appoint-data {
  padding-top: 15px;
  padding-bottom: 25px;
}
.past-appoint-data .patients-info {
  padding: 15px 60px 15px 30px;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}
.patients-data {
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  line-height: 16px;
  color: #333;
  padding: 15px;
  width: 100%;
  float: left;
}
.patients-data p {
  line-height: 16px;
  color: #333;
  padding-bottom: 15px;
}
.physical-exam > strong {
  text-transform: uppercase;
  padding-bottom: 10px;
  display: block;
}
.patients-export-option {
  padding: 30px 0 30px 0;
}
.appointment-data p {
  padding-top: 10px;
  clear: both;
}
.appointment-data .patients-info {
  padding-left: 25px;
}
.patients-info [type="radio"]:checked + label::after,
.patients-info [type="radio"]:not(:checked) + label::after {
  background: #fff;
  border: 1px solid #828282;
}
.d-flex {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
}
.eql-height {
  background: #fff;
  height: 100%;
}
/*** past appointment page css end ***/
/*** new added css end ***/

/*** inbox page css start ***/
.col-3 {
  float: left;
  width: 27.2%;
}
.col-7 {
  float: left;
  width: 72.8%;
  padding-left: 25px;
}
.inbox-info-left .search-form {
  background: #f2f2f2;
  border-radius: 5px;
  height: 42px;
  line-height: 30px;
  margin: 0 20px 25px 20px;
}
.inbox-info-left .search-form .form-control {
  background: transparent;
  font-family: "Roboto", sans-serif;
}
.inbox-icon {
  background: url(../images/newmessage.png) no-repeat right;
  height: 14px;
  float: left;
  width: 14px;
}
.archives-inbox {
  background: url(../images/view-archive.png) no-repeat right;
  width: 13px;
  float: right;
  height: 13px;
}
.inbox-archive {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  padding: 15px 20px 20px;
}
.inbox-archive ul li {
  float: right;
  position: relative;
}
.inbox-archive ul li.archive-main {
  margin-left: 12px;
}
.archive-show {
  position: absolute;
  top: 100%;
  width: 105px;
  left: 0;
  text-align: left;
  background: #fff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  z-index: 999;
  /* display: none; */
  margin: 10px 0 0 0;
  padding: 8px 10px;
}
.archive-show ul li a {
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.03em;
  color: #4f4f4f;
  position: relative;
}
.archive-show ul li a:hover,
.archive-show ul li a:focus {
  color: #2a3688;
}
.new-msg-info .archive-show {
  left: auto;
  right: 0;
}
.inbox-archive ul li ul li {
  float: none;
}
.inbox-info-left .search-form input[type="submit"] {
  top: 16px;
}
.inbox-msgs {
  border-top: 1px solid #e0e0e0;
}
.new-msg-info h3 {
  font-size: 16px;
  line-height: 19px;
  color: #333333;
  margin-bottom: 7px;
  font-weight: normal;
}
.new-msg-info h4 {
  color: #828282;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 0;
  font-weight: normal;
}
.new-msg-info h4 span {
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.05em;
  color: #bdbdbd;
  float: right;
}
.new-msg-info {
  padding: 12px 20px;
  /* transition: 0.4s; */
  border-top: 1px solid #f2f2f2;
}
.new-msg-info:first-child {
  border-top: 0;
}
.new-msg-info:hover,
.new-msg-info.active {
  background: #f0f2fb;
}
.msg-dots {
  height: 7px;
  width: 7px;
  border-radius: 100%;
  background: #3e4896;
  display: inline-block;
  position: absolute;
  right: 0;
  top: 7px;
}
.new-msg-info-inner {
  position: relative;
}
/* .archive-main.open-archives .archive-show {
	display: block;
} */
.new-msg-info .conversations {
  position: absolute;
  top: 2px;
  right: 0;
}
.archive-show li a.followup {
  background: url(../images/followup.png) no-repeat left;
  padding-left: 15px;
}
.archive-show li a.important {
  background: url(../images/important.png) no-repeat left;
  padding-left: 15px;
}
.archive-show li {
  margin: 3px 0;
}
.archive-show li:nth-child(3) {
  border-top: 1px solid #e0e0e0;
  padding: 7px 0 0 0;
  margin-top: 7px;
}
.inbox-main .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #c4c4c4 !important;
  width: 7px !important;
}
.inbox-msgs .custom-scrl-content {
  height: 587px;
}
.inbox-msgs .mCSB_inside > .mCSB_container {
  margin-right: 0;
}
.inbox-hd {
  float: left;
  width: calc(100% - 150px);
}
.inbox-info-right {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  height: 100%;
  position: relative;
}
.inbox-right-head .conversations {
  float: right;
  margin: 9px 0 0 0;
  position: relative;
}
.inbox-hd h3 {
  font-size: 16px;
  line-height: 19px;
  color: #4f4f4f;
  font-weight: 700;
  margin-bottom: 7px;
  width: 50%;
}

.inbox-hd h3 input {
  font-size: 16px;
  line-height: 19px;
  color: #4f4f4f;
  font-weight: 700;
  margin-bottom: 7px;
}

.inbox-right-head {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  border-bottom: 1px solid #e0e0e0;
  padding: 14px 30px 14px 20px;
}
.inbox-right-head .archive-show li:last-child {
  border-top: 1px solid #e0e0e0;
  padding-top: 6px;
  margin-top: 6px;
}
.inbox-hd h4 {
  font-size: 14px;
  line-height: 16px;
  color: #828282;
  margin-bottom: 0;
  font-weight: normal;
}
.inbox-hd h4 input {
  font-size: 14px;
  line-height: 16px;
  color: #828282;
  margin-bottom: 0;
  font-weight: normal;
  border: 0;
}
.inbox-right-head .conversations .archive-show {
  left: auto;
  right: 0;
  margin-top: 15px;
}
.dark-blue {
  background: #3e4896;
}
.inbox-info-right .chat-text {
  float: right;
  margin-top: 4px;
  width: 100%;
  margin: 0;
  position: relative;
}
.inbox-info-right .chat-text .grey-bx {
  float: left;
}
.inbox-info-right .chatbox {
  padding: 12px;
  border-radius: 3px;
  margin-bottom: 10px;
  max-width: 56%;
  float: right;
}
.inbox-info-right .grey-bx p {
  color: #333;
}
.inbox-info-right .chat-text .short-name {
  background: #9ca9e2;
  color: #2a3688;
}
.inbox-info-right .chat-content {
  padding: 15px 25px 25px 25px;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  height: 500px;
}
.inbox-info-right .type-msg .common-input {
  color: #4f4f4f;
  border: 0;
  background: transparent;
  padding-left: 40px;
}
.inbox-info-right .type-msg {
  float: left;
  width: calc(100% - 50px);
  position: absolute;
  left: 25px;
  bottom: 25px;
  margin: 0;
  border: 1px solid #e0e0e0;
  border-radius: 15px;
  overflow: hidden;
}
.copy {
  /* background: url(../images/copy.png) no-repeat left; */
  display: block;
  height: 16px;
}
.copy-msg {
  background: #f2f2f2;
  height: 36px;
  padding: 11px 0 0 20px;
  position: relative;
}
.inbox-info-right .type-msg .common-input.active-field {
  border: 0 !important;
}
.important-msg h3 {
  position: relative;
  padding-left: 20px;
}
.followup-msg h3 {
  position: relative;
  padding-left: 20px;
}
.important-msg h3:before {
  position: absolute;
  left: 0;
  top: 4px;
  content: "";
  background: url(../images/important.png) no-repeat left;
  width: 10px;
  height: 9px;
}
.followup-msg h3:before {
  position: absolute;
  left: 0;
  top: 4px;
  content: "";
  background: url(../images/followup.png) no-repeat left;
  width: 10px;
  height: 9px;
}
.copy-msg-open {
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.03em;
  color: #4f4f4f;
  border: 1px solid #e0e0e0;
  border-radius: 0;
  position: absolute;
  top: -30px;
  left: 10px;
  width: 126px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  opacity: 0;
  transition: 0.4s;
}
.copymsg .copy-msg-open {
  opacity: 1;
}
.inbox-info-right .checkbox-custom + .checkbox-custom-label::before {
  border-radius: 100%;
  width: 14px;
  height: 14px;
}
.inbox-info-right .checkbox-custom:checked + .checkbox-custom-label::after {
  width: 14px;
  height: 14px;
  top: 1px;
  left: 1px;
  background: #4f4f4f;
  border-radius: 100%;
}
.msg-select {
  position: absolute;
  right: 0;
  bottom: 42px;
}
.left-side {
  margin-right: 0;
  padding-left: 40px;
}
.right-side {
  padding-right: 50px;
  margin: 0;
}
.copy-selected-msg {
  background: #333333;
  border-radius: 5px;
  font-weight: 500;
  font-size: 11px;
  width: 44px;
  height: 18px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #f2f2f2;
  line-height: 17px;
  float: right;
}
.copy-selected-msg:hover,
.copy-selected-msg:focus {
  background: #3e4896;
}
.selected-msgs > span {
  font-weight: 500;
  font-size: 11px;
  line-height: 127.19%;
  letter-spacing: 0.05em;
  color: #4f4f4f;
}
.selected-msgs {
  background: #e0e0e0;
  padding: 7px 25px;
  position: absolute;
  width: 100%;
  z-index: 9;
}
.inbox-info-right .chat-time {
  color: #4f4f4f;
  clear: both;
  display: block;
}
.multiple-msg .msg-select {
  right: -107px;
  bottom: 30px;
}
.inbox-archv {
  position: relative;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  color: #2a3688;
  padding-left: 25px;
}
.inbox-archv:before {
  position: absolute;
  left: 0;
  top: 4px;
  content: "";
  background: url(../images/leftarw.png) no-repeat left;
  width: 17px;
  height: 11px;
}
.inbox-archive-link {
  padding: 15px 10px 15px 25px;
}
.open-links .archive-show,
.open-links2 .archive-show,
.open-links3 .archive-show {
  display: block;
}

.left-side .chat-time {
  text-align: left !important;
}

.right-side .chat-time {
  text-align: right !important;
}

.chat-text {
  margin-bottom: 20px !important;
}

.video-call-element {
  width: 100%;
  background: #343642;
  border-radius: 5px;
  height: 700px;

  position: relative;
  overflow: hidden;
}

.bottom-area {
  width: 100%;
  position: absolute;
  bottom: 0%;
  left: 0;
  height: 70px;
  background: rgba(79, 79, 79, 0.5);

  border-radius: 5px;
}

.remote-participant {
  height: 100%;
}

.participant {
  height: 100%;
}

.remote-participant video {
  /* width: 100%; */

  height: 100% !important;
  width: auto !important;
  margin: 0 auto;
  display: block;
}

.circle {
  width: 35px;
  height: 35px;
  background: #fff;
  border-radius: 100%;
}

.bottom-area ul {
  list-style: none;
  text-align: center;
  position: absolute;
  left: 44%;
  top: 20%;
}

.bottom-area ul li {
  display: inline;
  float: left;
  margin-right: 24px;
  cursor: pointer;
}

/* .bottom-area ul li:first-of-type {
  
} */

.circle img {
  margin-top: 8px;
}

/* .absolute-center {
  height: 
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
} */

.local-participant video {
  width: 100%;
}

.portraitView {
  height: 100% !important;
  width: auto !important;
  margin: 0 auto;
  display: block;
}

.red {
  background-color: red !important;
}

.expand-list {
  left: auto !important;
  right: 2% !important;
}

.full-height-call {
  height: 100% !important;
}

.display-linebreaks {
  white-space: pre-line;
}

.red-circle {
  height: 6px;
  width: 6px;
  border-radius: 100%;
  position: absolute;
  background-color: red;
  right: 0%;
  top: -10%;
}

.status-picker {
  width: 190px;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.feedback {
  position: absolute;
  bottom: 2%;
  left: 25px;
}

.feedback span {
  color: white;
  opacity: 0.8;
}

body {
  color: #343642;
}
p {
  color: #343642;
  font-weight: normal;
  font-family: "ProximaSoft-Regular";
}
.stage4-main {
  font-family: "EuclidFlex-Medium";
}
.leftbar {
  background: #38417c;
  top: 18px;
  border-radius: 0 10px 10px 0;
  height: calc(100vh - 90px);
  min-height: calc(100vh - 90px);
  bottom: 0;
  z-index: 99999999999;
  float: left;
  width: 244px;
  position: fixed;
}
.leftbar .logo {
  max-width: 100px;
  margin: 33px 10px 15px 25px;
}
.left-menu li a span {
  position: relative;
}
.menu-arrow {
  background: url(../images/menu-arrow.svg) no-repeat left center;
  position: absolute;
  top: 37px;
  right: 25px;
  cursor: pointer;
}
.menu-arrow > img {
  vertical-align: middle;
  margin-left: 14px;
}
.logo-main {
  position: relative;
}
.leftbar.menu-open {
  width: 56px;
}
.leftbar.menu-open .menu-arrow {
  background: none;
}
.leftbar.menu-open .logo,
.leftbar.menu-open .left-menu li a span b,
.leftbar.menu-open .waiting-room a span b,
.leftbar.menu-open .past-appointments a span b {
  display: none !important;
}
.left-menu li a span b {
  font-weight: normal;
}
.leftbar.menu-open .left-menu li a,
.leftbar.menu-open .waiting-room a {
  padding-left: 15px;
}
.leftbar.menu-open .past-appointments a {
  padding-left: 20px;
}
.close-menu .rightbar {
  padding-left: 90px;
}
.waiting-room a:hover,
.waiting-room a:focus,
.waiting-room a.active,
.past-appointments a:hover,
.past-appointments a:focus,
.past-appointments a.active,
.left-menu li a.btn-hover,
.left-menu li.active a {
  background-color: #3e4896;
}
.waiting-room a.meeting-link span {
  background: url(../images/meeting.svg) no-repeat left center;
}
.left-menu,
.waiting-room {
  font-family: "ProximaSoft-Regular";
  /* font-family: 'Roboto', sans-serif !important; */
}
.waiting-room a span b,
.past-appointments a span b {
  font-weight: normal;
}
.waiting-room a {
  /* border-top: 1px solid #3E4896; */
  width: 100%;
  display: inline-block;
  border-bottom: 1px solid #3e4896;
}
.waiting-room {
  border: 0;
}
.past-appointments a {
  font-size: 14px;
  color: #9ca9e2;
}
.waiting-room a span {
  background: url(../images/waiting-room.svg) no-repeat left;
}
.left-menu li a.patients span {
  background: url(../images/patients.svg) no-repeat left;
}
.left-menu li a.bulletin span {
  background: url(../images/bulletin.svg) no-repeat left;
}
.left-menu li a.inbox span {
  background: url(../images/inbox.png) no-repeat left;
}
.left-menu li a {
  color: #f4f9ff;
}
.left-menu li a.active {
  opacity: 1;
}
.note {
  font-family: "EuclidFlex-Medium";
  background: #6dcdb8;
  height: 13px;
  width: 13px;
  border-radius: 100%;
  text-align: center;
  line-height: 15px;
  font-size: 8px;
  color: #2a3688;
  position: absolute;
  right: -20px;
  top: -4px;
  font-style: normal;
}
.leftbar.menu-open .note {
  right: 4px;
  top: -6px;
}
/*** meeting room css start ***/
.meeting-right .custom-scrl-content {
  height: 217px;
  padding: 10px 0 7px 7px;
}
.username {
  background: #f2994a;
  font-family: "EuclidFlex-Medium";
  font-size: 13px;
  height: 34px;
  width: 34px;
  text-align: center;
  line-height: 34px;
  color: #fff;
  border-radius: 100%;
  color: #fff;
  position: relative;
  display: inline-block;
}
.notification {
  position: absolute;
  top: -6px;
  right: -8px;
}
.user-profile-right span {
  line-height: 16px;
  color: #343642;
  font-family: "EuclidFlex-Medium";
  /* font-family: 'Roboto', sans-serif !important; */
  padding-right: 10px;
  font-size: 13px;
}
.dashboard-title h2 {
  color: #2a3688;
  font-family: "EuclidFlex-Medium";
  /* font-family: 'Roboto', sans-serif !important; */
  line-height: 38px;
}
.btns {
  font-family: "EuclidFlex-Medium";
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  background: #3e4896;
  padding: 11px 12px 9px;
  min-width: 164px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 5px;
}
.btns:hover,
.btns:focus {
  background: #2a3688;
}
.start-meeting span {
  background: url(../images/video.svg) no-repeat left center;
  padding-left: 25px;
}
.meeting-details h2 {
  font-family: "EuclidFlex-Medium";
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #343642;
  border-bottom: 1px solid #e0e0e0;
  padding: 18px 30px;
}
.meeting-details form label {
  font-family: "ProximaSoft-Regular";
  font-weight: normal;
  font-size: 14px;
  color: #4f4f4f;
}
.meeting-details form {
  padding: 35px 108px 40px 30px;
  display: inline-block;
  width: 100%;
}
.meeting-details input[type="radio"]:checked + label,
.meeting-details input[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 20px;
  cursor: pointer;
  font-weight: 500;
  font-family: "ProximaSoft-Regular";
  /* font-family: 'Roboto', sans-serif !important; */
  font-size: 14px;
  color: #4f4f4f;
  width: 6px;
  height: 6px;
  font-weight: 400;
  /* border: 1px solid #828282; */
}
.meeting-details input[type="radio"]:checked + label::before,
.meeting-details input[type="radio"]:not(:checked) + label::before {
  width: 6px;
  height: 6px;
  border: 1px solid #828282;
}
.meeting-details [type="radio"]:checked + label::after,
.meeting-details[type="radio"]:not(:checked) + label::after {
  width: 8px;
  height: 8px;
}
.meeting-details .form-group > label {
  width: 125px;
  float: left;
  margin-top: 10px;
}
.meeting-details .common-input {
  border: 1px solid #e0e0e0;
  padding: 5px 6px 5px 18px;
  height: 35px;
  font-family: "ProximaSoft-Regular";
  /* font-family: 'Roboto', sans-serif !important; */
  font-size: 14px;
  color: #4f4f4f;
  min-width: 289px;
}
.inner-fields {
  background: rgba(242, 242, 242, 0.5);
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 15px 20px;
  float: left !important;
  width: calc(100% - 125px);
}
.meeting-details form .inner-fields label {
  width: 100%;
  display: inline-block;
  margin-bottom: 7px;
  font-size: 11px;
}
.addguest {
  font-family: "EuclidFlex-Medium";
  /* font-family: 'Roboto', sans-serif !important; */
  font-weight: 500;
  font-size: 13px;
  color: #4f4f4f;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  min-width: 100px;
  display: inline-block;
  text-align: center;
  text-transform: capitalize;
  padding: 8px;
}
.addguest:hover,
.addguest:focus {
  color: #fff;
  background: #4f4f4f;
  border: 1px solid #4f4f4f;
}
.add-guest {
  float: left;
  margin: 12px 0 0 125px;
  width: calc(100% - 125px);
}
.meeting-details .inner-fields .common-input {
  min-width: 100%;
  padding-left: 10px;
}
.meeting-details textarea.common-input {
  min-height: 70px;
  width: 100%;
  padding: 10px;
}
.meeting-details {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  margin-bottom: 20px;
}
.meeting-details form .btns {
  min-width: 190px;
  float: right;
}
.tooltip {
  background: url(../images/tooltip.svg) no-repeat right;
  padding: 5px 30px 5px 0;
}
.p-info-inner {
  display: inline-block;
  vertical-align: middle;
  margin-left: 25px;
}
.meeting-option .p-info {
  padding: 20px 0 35px 0;
}
.p-info-inner:first-child {
  margin-left: 0;
}
.meeting-details .form-group {
  margin-bottom: 35px;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}
.patients-export-option.meeting-patients-de > h2 {
  width: calc(100% - 260px);
  float: left;
  line-height: 28px !important;
}
/*** date time picker css start ***/
.bootstrap-datetimepicker-widget.dropdown-menu.top {
  position: absolute;
  top: 100% !important;
  left: 100px !important;
  bottom: auto !important;
  z-index: 999999;
  background: #ffffff;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  margin-top: 20px;
}
.bootstrap-datetimepicker-widget table td.today::before {
  content: none;
}
.bootstrap-datetimepicker-widget table tr td {
  font-size: 11px;
  line-height: 16px;
  color: #333333;
}
.bootstrap-datetimepicker-widget table td.active {
  background-color: #3e4896;
  color: #fff;
  text-shadow: none;
  border-radius: 100%;
  height: 22px;
  width: 22px;
  display: inline-block;
  line-height: 14px;
}
.bootstrap-datetimepicker-widget table td.active:hover,
.bootstrap-datetimepicker-widget table td.day.active:hover {
  background-color: #3e4896;
  color: #fff;
}
.bootstrap-datetimepicker-widget table th {
  color: #bdbdbd;
  text-transform: uppercase;
  padding: 2px;
}
.bootstrap-datetimepicker-widget table td.old,
.bootstrap-datetimepicker-widget table td.new {
  color: #bdbdbd;
}
.bootstrap-datetimepicker-widget table td.day:hover,
.bootstrap-datetimepicker-widget table td.hour:hover,
.bootstrap-datetimepicker-widget table td.minute:hover,
.bootstrap-datetimepicker-widget table td.second:hover {
  border-radius: 100%;
  background: transparent;
}
.bootstrap-datetimepicker-widget table th.prev::after,
.bootstrap-datetimepicker-widget table th.next::after {
  content: none;
}
.bootstrap-datetimepicker-widget table th.prev,
.bootstrap-datetimepicker-widget table th.prev:hover,
.bootstrap-datetimepicker-widget table th.prev:focus {
  background: url(../images/prev-dt.svg) no-repeat center center !important;
}
.bootstrap-datetimepicker-widget table th.next,
.bootstrap-datetimepicker-widget table th.next:hover,
.bootstrap-datetimepicker-widget table th.next:focus {
  background: url(../images/next-dt.svg) no-repeat center center !important;
}
.bootstrap-datetimepicker-widget table td.day {
  height: 22px;
  line-height: 23px;
  width: 22px;
  padding: 0 !important;
}
.bootstrap-datetimepicker-widget table th.picker-switch {
  color: #4f4f4f;
  text-transform: capitalize;
}
/* .app-details .add-another a .form-control {
    border: 0;
    line-height: 10px;
  } */
.bootstrap-datetimepicker-widget table th.picker-switch:hover {
  background: transparent !important;
}
.bootstrap-datetimepicker-widget.dropdown-menu.top::before {
  border-left: none;
  border-right: none;
}
.bootstrap-datetimepicker-widget.dropdown-menu::after {
  border: none !important;
  background: url(../images/datetime-arrow.png) no-repeat center center !important;
  width: 68px;
  height: 21px;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: -20px;
}
.bootstrap-datetimepicker-widget.dropdown-menu {
  width: 205px;
}
/* .app-details .add-another table a {
    background: transparent;
    border: 0;
  } */
.datetime-main table.table-condensed tr:first-child a i.fa-clock-o,
.datetime-main table.table-condensed tr:first-child a i.fa-clock-o:hover {
  background: url(../images/clock-blue.svg) no-repeat center center !important;
  width: 13px;
  background-size: 100% !important;
}
.picker-switch.accordion-toggle {
  margin-top: -10px;
  text-align: center;
  width: 100%;
  padding: 0 !important;
}
.bootstrap-datetimepicker-widget table td span:hover,
.bootstrap-datetimepicker-widget table td i:hover {
  background: transparent;
}
.meeting-right h5 {
  font-family: "EuclidFlex-Medium";
  /* font-family: 'Roboto', sans-serif !important; */
  font-weight: 500;
  font-size: 14px;
  color: #2a3688;
  border-bottom: 1px solid #e0e0e0;
  padding: 25px;
  margin-bottom: 0;
}
.meeting-right li > span {
  display: block;
  padding: 5px 0 0 0px;
}
.meeting-right li {
  background: rgba(242, 242, 242, 0.5) url(../images/left-arrow.svg) no-repeat
    97%;
  margin-bottom: 8px;
  padding: 13px 15px;
  font-size: 12px;
  color: #4f4f4f;
}
.meeting-right li:nth-child(even) {
  background: #f2f2f2 url(../images/left-arrow.svg) no-repeat 97%;
}
.right-sec.meeting-right {
  font-family: "ProximaSoft-Regular";
  margin-bottom: 20px;
}
.stage4-main .mCSB_scrollTools .mCSB_draggerRail {
  width: 7px !important;
}
.stage4-main .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #c4c4c4 !important;
  width: 7px !important;
  height: 133px;
}
.stage4-main .mCSB_inside > .mCSB_container {
  margin-right: 20px;
}
.patients-export-option {
  padding: 10px 0 40px 0;
}
/*** patients1 page css start ***/
.meeting-details .switch-main {
  margin: 15px 0 0 125px;
  width: 289px;
  position: relative;
}
.meeting-details .notify {
  padding-left: 10px;
  font-family: "ProximaSoft-Regular";
  font-weight: normal;
  color: #4f4f4f;
  /* font-family: 'Roboto', sans-serif !important; */
}
.meeting-details .switch-btn::before {
  background-color: #bdbdbd;
  border-radius: 20px;
}
.meeting-details .switch-btn {
  background-color: #e0e0e0;
}
.meeting-details input:checked + .switch-btn::before {
  background-color: #4f4f4f;
}
.meeting-details input:checked + .switch-btn {
  background-color: #e0e0e0;
}
.switch-main .tooltip-main {
  position: absolute;
  right: 0;
  top: 0;
}
.inner-fields .fields-row-main {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  margin-bottom: 15px;
}
.meeting-details select {
  background: #fff url(../images/select-arrow.svg) no-repeat 96%;
  height: 35px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  width: 100%;
  color: #4f4f4f;
  padding-left: 5px;
}
.arrow_box {
  background: rgba(242, 242, 242, 0.5);
  position: absolute;
  left: 100%;
  padding: 10px 20px;
  font-size: 12px;
  color: #4f4f4f;
  width: 325px;
  top: -27px;
  margin-left: 20px;
  font-family: "EuclidFlex-Regular";
  opacity: 0;
  transition: 0.4s;
}
.arrow_box::after {
  right: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: transparent;
  border-right-color: rgba(242, 242, 242, 0.5);
  border-width: 10px;
  margin-top: -10px;
}
.meeting-tooltip {
  position: relative;
  display: inline-block;
}
.meeting-tooltip.open-tooltip .arrow_box,
.tooltip-main.open-tooltip .arrow_box {
  opacity: 1;
}
/*** patients1 page css end ***/
/*** patients4 page css start ***/
.inner-fields.btm {
  margin: 15px 0;
}
.stage4-popup {
  width: 100%;
  padding: 0;
}
.stage4-main .fancybox-slide--html {
  padding: 0;
}
.stage4-main .fancybox-bg {
  background: rgba(52, 54, 66, 0.7);
}
.stage4-popup .sub-heading h3 {
  color: #2a3688;
  font-size: 14px;
  line-height: 14px;
  text-transform: uppercase;
  font-family: "ProximaSoft-Regular";
  letter-spacing: 0.08em;
}
.stage4-popup .inner-forgot-pass {
  padding: 20px;
  text-align: left;
}
.stage4-popup .inner-forgot-pass h5 {
  font-size: 18px;
  line-height: 23px;
  color: #343642;
  margin-bottom: 5px;
}
.stage4-popup .login-submit .btns {
  min-width: 60px;
}
.meeting-details .custom-scrl-content {
  height: 800px;
}
.stage4-main .left-sec .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  height: 463px;
}
/*** meeting patients details page css start ***/
.title-goback h2 {
  background: url(../images/goback.svg) no-repeat left center;
  padding-left: 30px;
}
.meeting-patients-de.patients-export-option {
  padding: 0 0 10px 0;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}
.stage4-main .patients-export-option > h2 {
  float: left;
  font-size: 24px;
  line-height: 38px;
  color: #343642;
  font-family: "EuclidFlex-Regular";
}
.stage4-main .patients-export-option .exports {
  float: right;
}
.stage4-main .border-btn {
  border: 1px solid #e0e0e0;
  color: #4f4f4f;
  min-width: 120px;
  background: transparent !important;
  padding: 10px 12px 8px;
}
.stage4-main .border-btn:hover {
  background: #4f4f4f !important;
  color: #fff;
  border: 1px solid #4f4f4f;
}
.meeting-patients-de .btns {
  min-width: 110px;
}
.meeting-patients-de .exports .btns {
  margin-left: 12px;
}
.meeting-details h5 {
  font-size: 14px;
  color: #2a3688;
  margin-bottom: 30px;
}
.patients-n {
  font-size: 14px;
  color: #4f4f4f;
  padding: 8px 0 5px 0;
  text-decoration: underline;
  font-family: "ProximaSoft-Regular";
  display: block;
}
.guests-link {
  padding: 7px 0 20px 0;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}
.guests-link a {
  font-size: 12px;
  font-family: "EuclidFlex-Regular";
  color: #4f4f4f;
  text-decoration: underline;
}
.guests-link a:hover,
.guests-link a:focus {
  color: #2a3688;
}
.inner-fields-main {
  width: calc(100% - 125px);
  float: left;
}
.inner-fields-main .inner-fields {
  width: 100%;
}
.guest-form-group .add-guest {
  margin-top: 0;
}
.meet-links input[type="text"] {
  background: transparent;
  border: 0;
  color: #4f4f4f;
  font-size: 14px;
  text-decoration: underline;
  width: calc(100% - 30px);
}
.meet-links-main {
  padding: 5px 25px 25px 25px;
}
.meet-links {
  background: #f2f2f2;
  border-radius: 5px;
  height: 30px;
  padding: 4px 10px;
}
.meet-links input[type="submit"] {
  background: url(../images/submit-arrow.svg) no-repeat left -2px;
  float: right;
  border: 0;
  width: 26px;
  height: 26px;
  cursor: pointer;
}
.meeting-id-main {
  font-size: 14px;
  color: #4f4f4f;
  font-family: "ProximaSoft-Regular";
  margin: 25px 0 0 0;
}
.meeting-id-main > label {
  width: 85px;
  display: inline-block;
  vertical-align: middle;
}
.meeting-id {
  background: #fee398;
  height: 30px;
  min-width: 87px;
  border-radius: 5px;
  display: inline-block;
  text-align: center;
  line-height: 30px;
  text-decoration: underline;
}
.id-copy {
  font-size: 11px;
  border: 1px solid #e0e0e0;
  height: 30px;
  text-align: center;
  line-height: 30px;
  display: inline-block;
  text-align: center;
  min-width: 60px;
  border-radius: 5px;
  margin-left: 15px;
}
.meeting-info h5 {
  border: 0;
}
.stage4-popup .fancybox-button {
  top: 20px;
}
.stage4-popup .login-submit .btns.border-btn {
  margin-right: 10px;
}
.stage4-popup .login-submit {
  margin: 0;
}
/*** past appointmnet patient oriented page css start ***/
.text-decoration {
  text-decoration: none;
}
.meeting-option.form-group .p-info {
  padding: 11px 0 0 0;
}
.past-appointment-stage4 .meeting-details .inner-fields .common-input {
  background: rgba(255, 255, 255, 0.3);
}
.past-appointment-stage4 .meeting-details select {
  background: rgba(255, 255, 255, 0.3) url(../images/select-arrow.svg) no-repeat
    96%;
}
.meeting-right .meeting-summary-main {
  margin-bottom: 25px;
}
.meeting-info p {
  color: #4f4f4f;
  padding-bottom: 5px;
}
.meeting-summary {
  padding: 0 25px 25px;
}
.export-btn span {
  background: url(../images/export-arrow.svg) no-repeat right center;
  padding: 10px 30px 10px 0;
}
.meeting-patients-de .btns.export-btn {
  min-width: 95px;
}
.meeting-patients-de .btns.delete-app {
  min-width: 60px;
}
.guests-fields .inner-fields {
  margin-bottom: 12px;
  padding: 15px 10px;
}
.guests-fields .inner-fields .fields-row-main {
  margin-bottom: 0;
}
.stage4-main .dashboard-title {
  width: calc(100% - 180px);
}
.datetime-main #datetimepicker1 {
  background: rgba(242, 242, 242, 0.5);
  border: 0;
  border-radius: 5px;
  height: 35px;
  min-width: 260px;
  text-align: center;
  font-family: "ProximaSoft-Regular";
  color: #4f4f4f;
}
.form-group.datetime-main {
  font-family: "ProximaSoft-Regular";
  position: relative;
}
/*** past appointmnet patient oriented page css end ***/

.switch {
  position: relative;
  width: 30px;
  height: 17px;
  float: left;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.switch-btn {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #bdbdbd;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 20px;
}
.switch-btn::before {
  position: absolute;
  content: "";
  height: 11px;
  width: 11px;
  left: 4px;
  bottom: 3px;
  background-color: #4f4f4f;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 20px;
}

input:checked + .switch-btn:before {
  -webkit-transform: translateX(12px);
  -ms-transform: translateX(12px);
  transform: translateX(12px);
  background-color: #2a3688;
}

.delete-app {
  border: 1px solid #e0e0e0;
  color: #4f4f4f;
  min-width: 120px;
  background: transparent !important;
  padding: 10px 12px 8px;
}

.datetimepicker-input {
  background: rgba(242, 242, 242, 0.5);
  border: 0;
  border-radius: 5px;
  height: 35px;
  min-width: 260px;
  text-align: center;
  font-family: "ProximaSoft-Regular";
  color: #4f4f4f;
}

.socket-indicator-container {
  height: 100%;
  width: 100%;
  position: relative;
}

.socket-container {
  background-color: rgba(156, 169, 226, 0.15);
  width: 110px;
  height: 29px;
  border-radius: 5px;
  position: absolute;
  top: 10%;
  left: 8%;
  padding-top: 6px;
}

.socket-container svg {
  display: inline;
  margin-left: 10px;
}

.socket-container p {
  font-family: "ProximaSoft-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  display: inline;
  align-items: center;
  padding-bottom: 0;
  /* White */
  vertical-align: middle;
  color: #ffffff;
  padding-left: 8px;
}

.menu-open .socket-container {
  width: 100%;
  left: 0%;
}
.menu-open .socket-container svg {
  margin: 0 auto;
  display: block;
  margin-top: 1.5px !important;
}

.menu-open .socket-container p {
  display: none;
}
.dashboard-title.with-right-icon {
  position: relative;
  width: auto;
}

.dashboard-title h2 a > img {
  max-width: 33px;
  margin: 0px 0 0 10px;
  display: inline-block;
  vertical-align: middle;
}

.dashboard-title.with-right-icon .archive-show {
  width: 154px;
  right: 0;
  left: auto;
  margin-top: 0;
}
.archive-show li a.copy-public {
  background: url(../images/copy-grey.png) no-repeat 15px center;
  font-size: 10px;
}
.copy-public span {
  color: #4f4f4f;
  opacity: 1;
  padding-left: 20px;
}
.dashboard-title.with-right-icon .archive-show .notify {
  font-size: 12px;
}
.dashboard-title.with-right-icon .archive-show li {
  margin: 0;
}
.archive-show li:nth-child(3) {
  margin: 3px 0;
  padding: 0;
  border: 0;
}
.dashboard-title.with-right-icon .archive-show .switch-main,
.dashboard-title.with-right-icon .archive-show li a {
  padding: 11px 15px;
}

.dashboard-title.with-right-icon .archive-show .switch-main,
.dashboard-title.with-right-icon .archive-show li a {
  padding: 11px 15px;
}
.patients-only-bg {
  background: rgba(224, 224, 224, 0.3);
}

.dashboard-title h2 a > img {
  max-width: 33px;
  margin: 0px 0 0 10px;
  display: inline-block;
  vertical-align: middle;
}
.dashboard-title.with-right-icon {
  position: relative;
  width: auto;
}
.dashboard-title.with-right-icon .archive-show {
  width: 154px;
  right: 0;
  left: auto;
  margin-top: 0;
}
.archive-show li a.copy-public {
  background: url(../images/copy-grey.png) no-repeat 15px center;
  font-size: 10px;
}
.copy-public span {
  color: #4f4f4f;
  opacity: 1;
  padding-left: 20px;
}
.dashboard-title.with-right-icon .archive-show .notify {
  font-size: 12px;
}
.dashboard-title.with-right-icon .archive-show li {
  margin: 0;
}
.archive-show li:nth-child(3) {
  margin: 3px 0;
  padding: 0;
  border: 0;
}
.dashboard-title.with-right-icon .archive-show .switch-main,
.dashboard-title.with-right-icon .archive-show li a {
  padding: 11px 15px;
  width: 100%;
}
.patients-only-bg {
  background: rgba(224, 224, 224, 0.3);
}
.user-settingss > a {
  background: url(../images/gear.png) no-repeat;
  width: 17px;
  height: 17px;
  display: block;
  z-index: 9999;
  position: relative;
}
.user-settings-main {
  position: relative;
  padding: 20px;
  /* background: url(../images/white-bx.png) no-repeat; */
  display: block;
  width: 300px;
  height: 151px;
  position: absolute;
  padding: 20px 45px 20px 20px;
  right: 40px;
  top: -28px;
  z-index: 999;
  display: none;
}
.user-name-info > img {
  max-width: 38px;
  float: left;
  margin-right: 10px;
}
.user-name-info h5 {
  font-size: 15px;
  font-family: "EuclidFlex-Medium";
  display: table;
}
.pc-info {
  background: rgba(242, 242, 242, 0.5);
  border-radius: 2px;
  padding: 10px;
  display: inline-block;
  width: 100%;
}
.pc-info li {
  float: left;
  width: 50%;
  margin-bottom: 6px;
}
.pc-info li > img {
  vertical-align: middle;
  display: inline-block;
  margin-right: 5px;
}
.call-info li {
  display: inline;
}
.call-info li a img {
  vertical-align: middle;
  display: inline-block;
}
.user-name-info {
  float: left;
  width: 50%;
}
.call-info {
  float: left;
  margin: 5px 0 0 0;
}
.user-settingss {
  position: relative;
}
.pc-info li:nth-child(2) {
  padding-left: 20px;
}
.close-popups {
  position: absolute;
  top: 12px;
  right: 34px;
}
.open-settingss .user-settings-main {
  display: block;
}

.calendar-main {
  width: 100%;
  height: 100%;
  min-height: 600px;
  margin: 15px 0 0 0;
}
.rbc-btn {
  color: inherit;
  font: inherit;
  margin: 0;
}
button.rbc-btn {
  overflow: visible;
  text-transform: none;
  -webkit-appearance: button;
  cursor: pointer;
}
button[disabled].rbc-btn {
  cursor: not-allowed;
}
button.rbc-input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
.rbc-calendar {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: stretch;
  align-items: stretch;
}
.rbc-calendar *,
.rbc-calendar *:before,
.rbc-calendar *:after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}
.rbc-abs-full,
.rbc-row-bg {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.rbc-ellipsis,
.rbc-event-label,
.rbc-row-segment .rbc-event-content,
.rbc-show-more {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.rbc-rtl {
  direction: rtl;
}
.rbc-off-range {
  color: #999999;
}
.rbc-header {
  overflow: hidden;
  -ms-flex: 1 0;
  flex: 1 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 3px;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
  font-size: 90%;
  min-height: 0;
  border-bottom: 1px solid #e0e0e0;
}
.rbc-header + .rbc-header {
  border-left: 1px solid #e0e0e0;
}
.rbc-rtl .rbc-header + .rbc-header {
  border-left-width: 0;
  border-right: 1px solid #e0e0e0;
}
.rbc-header > a,
.rbc-header > a:active,
.rbc-header > a:visited {
  color: inherit;
  text-decoration: none;
}
.rbc-row-content {
  position: relative;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-select: none;
  z-index: 4;
}
.rbc-today {
  background-color: transparent;
}
.rbc-toolbar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 0;
  font-size: 16px;
  justify-content: space-between;
  padding: 11px 25px;
}
.rbc-toolbar .rbc-toolbar-label {
  -ms-flex-positive: 1;
  flex-grow: 1;
  line-height: 23px;
  padding: 0 10px 0 0;
  text-align: center;
  font-size: 18px;
  color: #4f4f4f;
}
.rbc-toolbar .rbc-toolbar-label b {
  font-weight: normal;
  color: #f0f0f0;
}
.rbc-toolbar button {
  color: #4f4f4f;
  display: inline-block;
  margin: 0;
  text-align: center;
  vertical-align: middle;
  background: none;
  background-image: none;
  border: 0;
  padding: 0;
  margin: 0 7px 0 7px !important;
  border-radius: 0;
  line-height: normal;
  white-space: nowrap;
  font-family: "EuclidFlex-Medium";
  font-size: 12px;
  padding-bottom: 3px !important;
}
.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active {
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  /* box-shadow: 2px 2px 20px rgba(62, 132, 219, 0.08); */
  background-color: transparent;
  border-color: transparent;
  color: #2a3688;
  font-size: 12px;
  margin-right: 0;
  border-radius: 0;
  border-bottom: 1px solid #2a3688;
  padding-bottom: 5px;
  box-shadow: none;
}
.rbc-toolbar button:active:hover,
.rbc-toolbar button.rbc-active:hover,
.rbc-toolbar button:active:focus,
.rbc-toolbar button.rbc-active:focus {
  color: #2a3688;
  background-color: transparent;
  border-color: #2a3688;
}
.rbc-toolbar button:hover {
  color: #2a3688;
  background-color: transparent;
  border-color: transparent;
}
.rbc-btn-group {
  display: inline-block;
  white-space: nowrap;
}
.rbc-btn-group > button:first-child:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.rbc-btn-group > button:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.rbc-rtl .rbc-btn-group > button:first-child:not(:last-child) {
  border-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.rbc-rtl .rbc-btn-group > button:last-child:not(:first-child) {
  border-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.rbc-btn-group > button:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.rbc-btn-group button + button {
  margin-left: -1px;
}
.rbc-rtl .rbc-btn-group button + button {
  margin-left: 0;
  margin-right: -1px;
}
.rbc-btn-group + .rbc-btn-group,
.rbc-btn-group + button {
  margin-left: 10px;
}
.rbc-event {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin: 0;
  padding: 2px 5px;
  background-color: #6dcdb8;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  width: 100%;
  text-align: left;
  font-size: 13px;
  font-family: "EuclidFlex-Medium";
}
.rbc-slot-selecting .rbc-event {
  cursor: inherit;
  pointer-events: none;
}
.rbc-event.rbc-selected {
  background-color: #265985;
}
.rbc-event-label {
  font-size: 13px;
}
.rbc-event-overlaps {
  -webkit-box-shadow: -1px 1px 5px 0px rgba(51, 51, 51, 0.5);
  box-shadow: -1px 1px 5px 0px rgba(51, 51, 51, 0.5);
}
.rbc-event-continues-prior {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.rbc-event-continues-after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.rbc-event-continues-earlier {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.rbc-event-continues-later {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.rbc-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
}
.rbc-row-segment {
  padding: 0 1px 1px 1px;
}
.rbc-selected-cell {
  background-color: rgba(0, 0, 0, 0.1);
}
.rbc-show-more {
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 4;
  font-weight: bold;
  font-size: 85%;
  height: auto;
  line-height: normal;
  white-space: nowrap;
}
.rbc-month-view {
  position: relative;
  border: 0;
  border-top: 1px solid #e0e0e0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex: 1 0;
  flex: 1 0;
  width: 100%;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-select: none;
  height: 100%;
}
.rbc-month-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
}
.rbc-month-row {
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex: 1 0;
  flex: 1 0;
  -ms-flex-preferred-size: 0px;
  flex-basis: 0px;
  overflow: hidden;
  height: 100%;
  min-height: 100px;
}
.rbc-month-row + .rbc-month-row {
  border-top: 1px solid #f2f2f2;
}
.rbc-date-cell {
  -ms-flex: 1 1;
  flex: 1 1;
  min-width: 0;
  padding-right: 5px;
  text-align: right;
}
.rbc-date-cell.rbc-now {
  font-weight: bold;
}
.rbc-date-cell > a,
.rbc-date-cell > a:active,
.rbc-date-cell > a:visited {
  color: inherit;
  text-decoration: none;
}
.rbc-row-bg {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex: 1 0;
  flex: 1 0;
  overflow: hidden;
}
.rbc-day-bg {
  -ms-flex: 1 0;
  flex: 1 0;
}
.rbc-day-bg + .rbc-day-bg {
  border-left: 1px solid #f2f2f2;
}
.rbc-rtl .rbc-day-bg + .rbc-day-bg {
  border-left-width: 0;
  border-right: 1px solid #f2f2f2;
}
.rbc-overlay {
  position: absolute;
  z-index: 5;
  border: 1px solid #e5e5e5;
  background-color: #fff;
  -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
  padding: 10px;
}
.rbc-overlay > * + * {
  margin-top: 1px;
}
.rbc-overlay-header {
  border-bottom: 1px solid #e5e5e5;
  margin: -10px -10px 5px -10px;
  padding: 2px 10px;
}
.rbc-agenda-view {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex: 1 0;
  flex: 1 0;
  /* overflow: auto; */
}
.rbc-agenda-view table.rbc-agenda-table {
  width: 100%;
  border: 1px solid #ddd;
  border-spacing: 0;
  border-collapse: collapse;
}
.rbc-agenda-view table.rbc-agenda-table tbody > tr > td {
  padding: 5px 10px;
  vertical-align: top;
}
.rbc-agenda-view table.rbc-agenda-table .rbc-agenda-time-cell {
  padding-left: 15px;
  padding-right: 15px;
  text-transform: lowercase;
}
.rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
  border-left: 1px solid #ddd;
}
.rbc-rtl .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
  border-left-width: 0;
  border-right: 1px solid #ddd;
}
.rbc-agenda-view table.rbc-agenda-table tbody > tr + tr {
  border-top: 1px solid #ddd;
}
.rbc-agenda-view table.rbc-agenda-table thead > tr > th {
  padding: 3px 5px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}
.rbc-rtl .rbc-agenda-view table.rbc-agenda-table thead > tr > th {
  text-align: right;
}
.rbc-agenda-time-cell {
  text-transform: lowercase;
}
.rbc-agenda-time-cell .rbc-continues-after:after {
  content: " »";
}
.rbc-agenda-time-cell .rbc-continues-prior:before {
  content: "« ";
}
.rbc-agenda-date-cell,
.rbc-agenda-time-cell {
  white-space: nowrap;
}
.rbc-agenda-event-cell {
  width: 100%;
}
.rbc-time-column {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 100%;
}
.rbc-time-column .rbc-timeslot-group {
  -ms-flex: 1 1;
  flex: 1 1;
}
.rbc-timeslot-group {
  border-bottom: 1px solid #f2f2f2;
  min-height: 45px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
}
.rbc-time-gutter,
.rbc-header-gutter {
  -ms-flex: none;
  flex: none;
}
.rbc-label {
  padding: 0 18px;
  color: #2a3688;
  font-size: 12px;
  opacity: 0.6;
  font-weight: bold;
  font-family: "ProximaSoft-Regular";
  /* line-height: 44px; */
}
.rbc-day-slot {
  position: relative;
}
.rbc-day-slot .rbc-events-container {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  margin-right: 0;
  top: 0;
}
.rbc-day-slot .rbc-events-container.rbc-is-rtl {
  left: 10px;
  right: 0;
}
.rbc-day-slot .rbc-event {
  /* border: 1px solid #265985; */
  display: -ms-flexbox;
  display: flex;
  max-height: 100%;
  min-height: 44px;
  -ms-flex-flow: column wrap;
  flex-flow: column wrap;
  -ms-flex-align: start;
  align-items: flex-start;
  overflow: hidden;
  position: absolute;
  padding-top: 5px;
}
.rbc-day-slot .rbc-event-label {
  -ms-flex: none;
  flex: none;
  padding-right: 5px;
  width: auto;
}
.rbc-day-slot .rbc-event-content {
  width: 100%;
  -ms-flex: 1 1;
  flex: 1 1;
  word-wrap: break-word;
  line-height: 1;
  height: 100%;
  min-height: 1em;
  font-size: 12px;
  font-family: "ProximaSoft-Regular";
}
.rbc-day-slot .rbc-time-slot {
  border-top: 1px solid #f7f7f7;
}
.rbc-time-view-resources .rbc-time-gutter,
.rbc-time-view-resources .rbc-time-header-gutter {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  background-color: white;
  border-right: 1px solid #ddd;
  z-index: 10;
  margin-right: -1px;
}
.rbc-time-view-resources .rbc-time-header {
  overflow: hidden;
}
.rbc-time-view-resources .rbc-time-header-content {
  min-width: auto;
  -ms-flex: 1 0;
  flex: 1 0;
  -ms-flex-preferred-size: 0px;
  flex-basis: 0px;
}
.rbc-time-view-resources .rbc-day-slot {
  min-width: 140px;
}
.rbc-time-view-resources .rbc-header,
.rbc-time-view-resources .rbc-day-bg {
  width: 140px;
  -ms-flex: 1 1;
  flex: 1 1;
  -ms-flex-preferred-size: 0 px;
  flex-basis: 0 px;
}
.rbc-time-header-content + .rbc-time-header-content {
  margin-left: -1px;
}
.rbc-time-slot {
  -ms-flex: 1 0;
  flex: 1 0;
}
.rbc-time-slot.rbc-now {
  font-weight: bold;
}
.rbc-day-header {
  text-align: center;
}
.rbc-slot-selection {
  z-index: 10;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 75%;
  width: 100%;
  padding: 3px;
}
.rbc-slot-selecting {
  cursor: move;
}
.rbc-time-view {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex: 1 1;
  flex: 1 1;
  width: 100%;
  border-top: 1px solid #e0e0e0;
  min-height: 0;
  height: 600px;
}
.rbc-time-view .rbc-time-gutter {
  white-space: nowrap;
}
.rbc-time-view .rbc-allday-cell {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  position: relative;
}
.rbc-time-view .rbc-allday-cell + .rbc-allday-cell {
  border-left: 1px solid #ddd;
}
.rbc-time-view .rbc-allday-events {
  position: relative;
  z-index: 4;
}
.rbc-time-view .rbc-row {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  min-height: 20px;
}
.rbc-time-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -ms-flex-direction: row;
  flex-direction: row;
}
.rbc-time-header.rbc-overflowing {
  border-right: 1px solid #ddd;
}
.rbc-rtl .rbc-time-header.rbc-overflowing {
  border-right-width: 0;
  border-left: 1px solid #ddd;
}
.rbc-time-header > .rbc-row:first-child {
  border-bottom: 1px solid #ddd;
}
.rbc-time-header > .rbc-row.rbc-row-resource {
  border-bottom: 1px solid #ddd;
}
.rbc-time-header-content {
  -ms-flex: 1 1;
  flex: 1 1;
  display: -ms-flexbox;
  display: flex;
  min-width: 0;
  -ms-flex-direction: column;
  flex-direction: column;
  border-left: 1px solid #f2f2f2;
}
.rbc-rtl .rbc-time-header-content {
  border-left-width: 0;
  border-right: 1px solid #f2f2f2;
}
.rbc-time-content {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1 0;
  flex: 1 0;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 100%;
  /* border-top: 2px solid #E0E0E0; */
  overflow-y: auto;
  position: relative;
}
.rbc-time-content > .rbc-time-gutter {
  -ms-flex: none;
  flex: none;
}
.rbc-time-content > * + * > * {
  border-left: 1px solid #f2f2f2;
}
.rbc-rtl .rbc-time-content > * + * > * {
  border-left-width: 0;
  border-right: 1px solid #f2f2f2;
}
.rbc-time-content > .rbc-day-slot {
  width: 100%;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-select: none;
}
.rbc-current-time-indicator {
  position: absolute;
  z-index: 3;
  left: 0;
  right: 0;
  height: 1px;
  /* background-color: #74ad31; */
  pointer-events: none;
}
.rbc-calendar {
  /* min-height: 584px; */
}
.rbc-header {
  padding: 10px 3px;
}
/*** appointment edit message page css start ***/
.automatic-msg-main > h2 {
  color: #343642;
  border-bottom: 1px solid #e0e0e0;
  padding: 17px 30px;
}
.automatic-msg-main .accordion-container {
  height: auto;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  float: left;
  width: 61%;
}
.automatic-msg-main .accordion-container .set > a {
  padding: 15px;
  background: url(../images/select-arrow.svg) no-repeat 98%;
  border-bottom: 1px solid #e0e0e0;
}
.automatic-msg-main .accordion-container h5 {
  font-size: 14px;
  font-family: "EuclidFlex-Medium";
  margin: 0;
}
.automatic-msg-main .accordion-container p {
  line-height: 28px;
  padding-bottom: 10px;
}
.selected-text {
  background: rgba(156, 169, 226, 0.3);
  font-size: 13px;
  padding: 5px;
  border-radius: 2px;
  margin: 0 5px;
  font-family: "EuclidFlex-Medium";
}
.automatic-msg-main .accordion-container .set {
  margin-bottom: 0;
}
.automatic-msg-main .accordion-container .set:last-child > a {
  border: 0;
}
.automatic-msg-main .accordion-container .content {
  padding: 20px;
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
  border-bottom: 1px solid #e0e0e0;
}

.editorClassName {
  padding: 20px;
}
.innermsg-info {
  padding: 20px 30px 20px 30px;
  display: inline-block;
  width: 100%;
  vertical-align: middle;
}
.new-response {
  float: left;
  width: 39%;
  padding-left: 45px;
}
.tag-templates h5 span {
  height: 17px;
  width: 17px;
  border-radius: 100%;
  background: #2a3688;
  display: inline-block;
  color: #fff;
  line-height: 16px;
  text-align: center;
  margin-left: 5px;
}
.tag-templates {
  margin: 30px 0 0 0;
}
.tag-templates h5 {
  font-size: 14px;
  font-family: "EuclidFlex-Medium";
}
.tag-templates .selected-text {
  margin: 0 0 6px 0;
  display: inline-block;
}
.automatic-msg-main.white-box {
  height: calc(100vh - 145px);
}
.automatic-msg-col {
  padding: 30px 0 0 0;
}
/*** appointment event details css start ***/
.common-input.full-width {
  width: calc(100% - 125px);
  min-width: calc(100% - 125px);
}
.app-event-details-form {
  padding-right: 40px;
}
.half-group {
  float: left;
  width: 50%;
}
.half-group.left {
  padding-right: 25px;
}
.half-group.right {
  padding-left: 25px;
  float: right;
  text-align: right;
}
.app-event-details-form .form-group {
  margin-bottom: 15px;
  display: inline-block;
  width: 100%;
  vertical-align: middle;
}
.half-group.right label {
  text-align: left;
}
.app-event-details-form textarea {
  line-height: 24px;
}
.symtoms-checklist [type="radio"]:checked + label::before,
.symtoms-checklist [type="radio"]:not(:checked) + label::before,
.cancel-event-popup [type="radio"]:checked + label::before,
.cancel-event-popup [type="radio"]:not(:checked) + label::before {
  border: 1px solid #bdbdbd;
  border-radius: 2px;
  width: 8px;
  height: 8px;
}
.symtoms-checklist [type="radio"]:checked + label::after,
.symtoms-checklist [type="radio"]:not(:checked) + label::after,
.cancel-event-popup [type="radio"]:checked + label::after,
.cancel-event-popup [type="radio"]:not(:checked) + label::after {
  border-radius: 1px;
  left: 1px;
  width: 8px;
  height: 8px;
  top: 3px;
}
.symtoms-checklist [type="radio"]:checked + label,
.symtoms-checklist [type="radio"]:not(:checked) + label {
  font-size: 14px;
}
.symtoms-checklist .p-info {
  clear: both;
  width: 100%;
  padding: 10px 0 10px 0;
}
.create-office-main .form-group .first-symptoms label {
  width: 200px;
}
.create-office-main .symtoms-checklist .checkbox-custom-label {
  width: 100%;
}
.symtoms-checklist .checkbox-custom + .checkbox-custom-label::before {
  width: 8px;
  height: 8px;
}
.symtoms-checklist .checkbox-custom:checked + .checkbox-custom-label::after {
  width: 8px;
  height: 8px;
  background: #4f4f4f;
  border-radius: 1px;
  /* border: 1px solid #BDBDBD; */
  left: 1px;
  top: 3px;
}
.create-office-main .other-symptoms textarea,
.create-office-main .more-details textarea {
  min-height: 60px;
  margin: 15px 0 0 35px;
  min-width: 367px;
}
.create-office-main .full-label label {
  width: 100%;
}
.create-office-main .more-details textarea {
  margin-left: 0;
  min-width: 405px;
}
.anycontact {
  padding: 20px 0;
}
.personal-contact-details {
  padding: 50px 0 40px 0;
}
.personal-contact-details h5,
.symtoms-checklist h5 {
  font-size: 14px;
  margin-bottom: 20px;
  font-family: "EuclidFlex-Medium";
}
.other-actions {
  background: #f2f2f2;
  border-radius: 5px;
  height: 35px;
  min-width: 123px;
  text-align: center;
  display: inline-block;
  line-height: 35px;
}
.other-actions span {
  font-family: "EuclidFlex-Medium";
  font-size: 13px;
  background: url(../images/select-arrow.svg) no-repeat right;
  padding-right: 15px;
}
.event-head {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  color: #343642;
  border-bottom: 1px solid #e0e0e0;
  padding: 17px 30px;
  -moz-align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
.event-head h2 {
  margin: 0;
}
.event-btns li {
  display: inline;
  margin-left: 11px;
  position: relative;
}
.event-btns li:first-child {
  margin-left: 0;
}
.event-btns li a.blue-btn {
  min-width: 70px;
}
.other-actions-open .archive-show {
  display: block;
}
.event-btns .archive-show li {
  margin: 3px 0;
}
.event-btns .archive-show {
  min-width: 155px;
  right: 0;
  left: auto;
  margin-top: 11px;
}
.symtoms-checklist .form-group {
  margin-bottom: 10px;
}
.app-event-details-form .common-input {
  min-width: 213px;
  resize: none;
}
.cancel-event-popup p {
  color: #343642;
  line-height: 17px;
}
.cancel-event-popup .p-info {
  padding: 10px 0 30px 0;
}
.app-event-details-form .half-group .common-input {
  width: calc(100% - 125px);
}
.app-event-details-form .first-symptoms .common-input {
  width: calc(50% - 200px);
}
/*** calendar added css start ***/
.rbc-time-header-cell .rbc-header a span b,
.rbc-month-header .rbc-header span,
.rbc-allday-cell .rbc-header.rbc-today a span b {
  display: block;
  font-weight: normal;
  font-size: 10px;
  color: #2a3688;
  opacity: 0.6;
  font-family: "EuclidFlex-Medium";
}
.rbc-header span {
  font-size: 20px;
  color: #2a3688;
  font-family: "ProximaSoft-Regular";
}
.rbc-header span i {
  font-style: normal;
}
.rbc-header.rbc-today span i {
  background: rgba(156, 169, 226, 0.2);
  width: 28px;
  height: 28px;
  border-radius: 100%;
  display: inline-block;
  line-height: 28px;
}
.noborder {
  border: 0 !important;
  padding: 0 5px !important;
}
.noborder:hover,
.noborder:focus {
  background: transparent !important;
}
.month-wek-day-btn {
  display: inline-block;
  padding: 7px 11px;
  margin: 0;
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  box-shadow: 2px 2px 20px rgba(62, 132, 219, 0.08);
  border-radius: 5px;
}
.rbc-toolbar button.today {
  border-radius: 5px !important;
  color: #4f4f4f !important;
  background: #fff !important;
  font-family: "EuclidFlex-Medium";
  font-size: 12px;
  padding: 10px 13px !important;
  border: 1px solid #f2f2f2 !important;
  box-sizing: border-box;
  box-shadow: 2px 2px 20px rgba(62, 132, 219, 0.08);
}
.calendar-headings .dashboard-title h2 {
  float: left;
}
.dashboard-top.calendar-headings .dashboard-title {
  min-width: 500px;
}
.new-event-btn {
  float: left;
  margin-left: 15px;
}
.appointment-setting-popup {
  position: relative;
  margin-left: 10px;
  float: left;
}
.appointment-setting-popup a > img {
  max-width: 33px;
}
.automatic-msg span {
  background: url(../images/edit.png) no-repeat 15px center;
  padding: 10px 10px 10px 33px !important;
}
.export-data span {
  background: url(../images/plus.png) no-repeat 15px center;
  padding: 10px 10px 10px 33px !important;
}
.print-data span {
  background: url(../images/print-icon.png) no-repeat 15px center;
  padding: 10px 10px 10px 33px !important;
}
.download-data span {
  background: url(../images/down-arrow.png) no-repeat 15px center;
  padding: 10px 10px 10px 33px !important;
}
.calendar-headings .archive-show li a {
  padding: 10px 0 !important;
}
.calendar-headings .dashboard-title.with-right-icon .archive-show {
  width: 170px;
}
.calendar-main .custom-scrl-content {
  height: 495px;
}
.calendar-main .mCSB_inside > .mCSB_container {
  margin-right: 0;
  position: absolute !important;
  right: 0;
}
.calendar-main .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #c4c4c4 !important;
  width: 7px !important;
}
.rbc-month-view .rbc-month-header .rbc-header {
  padding: 18px 3px;
}
.rbc-month-view .rbc-date-cell {
  font-size: 12px;
  text-align: center;
  padding-top: 10px;
  color: #2a3688;
  font-family: "ProximaSoft-Regular";
  margin-bottom: 5px;
}
.rbc-month-view .rbc-off-range a {
  color: #2a3688;
  opacity: 0.2;
}
.rbc-month-row .rbc-event {
  background: #f2994a;
  font-size: 11px;
  min-height: 22px;
}
.refresh {
  background: url(../images/refresh.svg) no-repeat;
  width: 11px;
  height: 10px;
  display: inline-block;
  opacity: 0.6;
  margin-left: 2px;
}
.monthly-event {
  font-size: 11px;
  color: #4f4f4f;
  font-family: "ProximaSoft-Regular";
  padding: 5px;
}
.monthly-event li span {
  font-family: "EuclidFlex-Medium";
}
.monthly-event li {
  background: url(../images/green-dots.svg) no-repeat left;
  padding-left: 10px;
}
.more-events {
  font-family: "EuclidFlex-Medium";
}
.rbc-month-row .rbc-date-cell.rbc-now.rbc-current a {
  background: rgba(156, 169, 226, 0.2);
  border-radius: 100%;
  height: 18px;
  width: 18px;
  display: inline-block;
  line-height: 18px;
}
.rbc-day-slot .refresh {
  background: url(../images/refresh-white.svg) no-repeat;
  opacity: 1;
  width: 13px;
  height: 11px;
}
.rbc-time-header-content .rbc-allday-cell {
  justify-content: start;
  display: flex;
}
.rbc-allday-cell .rbc-header {
  border: 0;
  padding-left: 60px;
}
.select-dt {
  float: left;
  margin: 8px 10px 0 0;
}
.calendar-datetime {
  padding: 5px 0 20px 0;
}
.to {
  float: left;
  margin: 9px 13px 0 0;
}
.appointment-setting-popup.open-archive .archive-show {
  display: block;
}
.print-checbk {
  font-family: "ProximaSoft-Regular";
  padding-bottom: 20px;
}
.print-checbk > label {
  width: 98px;
  float: left;
}
.print-checbk .checkbox-custom + .checkbox-custom-label::before {
  margin-right: 13px;
}
.print-checbk .form-group {
  margin-right: 46px;
}
.connected-main {
  position: absolute;
  left: 15px;
  bottom: 100px;
  margin-top: 100px;
}
.connected {
  background: url(../images/connected.svg) no-repeat 10px center;
  color: #fff;
  font-size: 12px;
  background-color: rgba(156, 169, 226, 0.15);
  padding: 8px 10px 8px 27px;
  border-radius: 5px;
}
.close-menu .connected span {
  display: none;
}
.close-menu .connected-main {
  left: 10px;
}
.close-menu .connected {
  padding-right: 6px;
}
.appointment-popups {
  background: #ffffff;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  min-width: 340px;
  padding: 18px 21px 18px 21px;
  z-index: 999;
  position: absolute;
  left: 100%;
  top: 0;
  display: none;
}

.popup-left {
  left: -100%;
}
.open-cal-popup .appointment-popups {
  display: block;
}
.office-text {
  background: url(../images/office.png) no-repeat;
  color: rgba(79, 79, 79, 0.5);
  padding-left: 25px;
}
.prints-type li {
  display: inline;
  margin-left: 15px;
}
.prints-type li:first-child {
  margin-left: 0;
}
.prints-type li a {
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  font-size: 12px;
  padding: 7px 11px;
}
.prints-type li a span {
  background: url(../images/print-icon.png) no-repeat right center;
  padding-right: 20px;
}
.prints-type li a:hover,
.prints-type li a:focus {
  color: #2a3688;
}
.prints-type {
  margin: 30px 0 10px 0;
}
.rbc-day-slot .appointment-popups .refresh {
  background: url(../images/refresh.svg) no-repeat;
  width: 16px;
  height: 15px;
  opacity: 0.3;
  background-size: 100%;
}
.top-info > h4 {
  float: left;
}
.set-options {
  float: right;
}
.top-info {
  display: inline-block;
  width: 100%;
  vertical-align: middle;
}
.set-options li {
  display: inline;
  margin-left: 10px;
}
.set-options li a img {
  vertical-align: middle;
}
.closepopup {
  margin-left: 30px;
}
.appointment-popups p {
  padding-bottom: 7px;
}
.stage4-main #export-data-popup .border-btn,
#print-data-popup .border-btn {
  min-width: auto !important;
}
.print-checbk .checkbox-custom-label {
  vertical-align: middle;
}
.print-checbk .checkbox-custom:checked + .checkbox-custom-label::after {
  width: 9px;
  height: 9px;
  top: 2.8px;
  left: 1px;
  border-radius: 12px;
}
.print-checbk .checkbox-custom + .checkbox-custom-label::before {
  width: 9px;
  height: 9px;
}

.rbc-allday-cell {
  display: none !important;
}

.calendar-headings .dashboard-title.with-right-icon .archive-show {
  /* width: 154px; */
  right: 0;
  left: auto;
  margin-top: 0;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 0;
}

.calendar-headings .dashboard-title.with-right-icon .archive-show li a {
  display: block;
  border-top: 1px solid #e0e0e0;
}

.archive-show li:first-child a {
  border-top: 0 !important;
}

.archive-show li:nth-child(3) {
  border-top: 0;
  padding: 0px 0 0 0;
  margin-top: 0px;
}

.new-event-btn a {
  font-family: "EuclidFlex-Regular";
}

.office-form .common-input {
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
}

.half-group .common-input {
  width: calc(100% - 125px);
  height: 35px;
}

.common-input.full-width {
  width: calc(100% - 125px);
  min-width: calc(100% - 125px);
}
.app-event-details-form {
  padding-right: 40px;
}
.half-group {
  float: left;
  width: 50%;
}
.half-group.left {
  padding-right: 25px;
}
.half-group.right {
  padding-left: 25px;
  float: right;
  text-align: right;
}
.app-event-details-form .form-group {
  margin-bottom: 15px;
  display: inline-block;
  width: 100%;
  vertical-align: middle;
}
.half-group.right label {
  text-align: left;
}
.app-event-details-form textarea {
  line-height: 24px;
}
.symtoms-checklist [type="radio"]:checked + label::before,
.symtoms-checklist [type="radio"]:not(:checked) + label::before,
.cancel-event-popup [type="radio"]:checked + label::before,
.cancel-event-popup [type="radio"]:not(:checked) + label::before {
  border: 1px solid #bdbdbd;
  border-radius: 2px;
  width: 8px;
  height: 8px;
}
.symtoms-checklist [type="radio"]:checked + label::after,
.symtoms-checklist [type="radio"]:not(:checked) + label::after,
.cancel-event-popup [type="radio"]:checked + label::after,
.cancel-event-popup [type="radio"]:not(:checked) + label::after {
  border-radius: 1px;
  left: 1px;
  width: 8px;
  height: 8px;
  top: 3px;
}
.symtoms-checklist [type="radio"]:checked + label,
.symtoms-checklist [type="radio"]:not(:checked) + label {
  font-size: 14px;
}
.symtoms-checklist .p-info {
  clear: both;
  width: 100%;
  padding: 10px 0 10px 0;
}
.create-office-main .form-group .first-symptoms label {
  width: 200px;
}
.create-office-main .symtoms-checklist .checkbox-custom-label {
  width: 100%;
}
.symtoms-checklist .checkbox-custom + .checkbox-custom-label::before {
  width: 8px;
  height: 8px;
}
.symtoms-checklist .checkbox-custom:checked + .checkbox-custom-label::after {
  width: 8px;
  height: 8px;
  background: #4f4f4f;
  border-radius: 1px;
  /* border: 1px solid #BDBDBD; */
  left: 1px;
  top: 3px;
}
.create-office-main .other-symptoms textarea,
.create-office-main .more-details textarea {
  min-height: 60px;
  margin: 15px 0 0 35px;
  min-width: 367px;
}
.create-office-main .full-label label {
  width: 100%;
}
.create-office-main .more-details textarea {
  margin-left: 0;
  min-width: 405px;
}
.anycontact {
  padding: 20px 0;
}
.personal-contact-details {
  padding: 50px 0 40px 0;
}
.personal-contact-details h5,
.symtoms-checklist h5 {
  font-size: 14px;
  margin-bottom: 20px;
  font-family: "EuclidFlex-Medium";
}
.other-actions {
  background: #f2f2f2;
  border-radius: 5px;
  height: 35px;
  min-width: 123px;
  text-align: center;
  display: inline-block;
  line-height: 35px;
}
.other-actions span {
  font-family: "EuclidFlex-Medium";
  font-size: 13px;
  background: url(../images/select-arrow.svg) no-repeat right;
  padding-right: 15px;
}
.event-head {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  color: #343642;
  border-bottom: 1px solid #e0e0e0;
  padding: 17px 30px;
  -moz-align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
.event-head h2 {
  margin: 0;
}
.event-btns li {
  display: inline;
  margin-left: 11px;
  position: relative;
}
.event-btns li:first-child {
  margin-left: 0;
}
.event-btns li a.blue-btn {
  min-width: 70px;
}
.other-actions-open .archive-show {
  display: block;
}
.event-btns .archive-show li {
  margin: 3px 0;
}
.event-btns .archive-show {
  min-width: 155px;
  right: 0;
  left: auto;
  margin-top: 11px;
}
.symtoms-checklist .form-group {
  margin-bottom: 10px;
}
.app-event-details-form .common-input {
  min-width: 213px;
  resize: none;
}
.cancel-event-popup p {
  color: #343642;
  line-height: 17px;
}
.cancel-event-popup .p-info {
  padding: 10px 0 30px 0;
}
.app-event-details-form .half-group .common-input {
  width: calc(100% - 125px);
}
.app-event-details-form .first-symptoms .common-input {
  width: calc(50% - 200px);
}

.office-form {
  padding: 20px 110px 0 30px !important;
}

.half-group {
  margin: 0;
}

.create-office-main .form-group label {
  width: 125px;
  float: left;
  margin-top: 10px;
}

.app-event-details-form .common-input {
  height: 35px;
}

.checkbox-custom-label span {
  color: #4f4f4f;
  font-size: 14px;
  line-height: 17px;
  font-family: "ProximaSoft-Regular";
  font-size: 14px;
}

.anycontact label {
  width: 100% !important;
}

.header-accordion {
  padding: 15px;
  background: url(../images/select-arrow.svg) no-repeat 98%;
  border-bottom: 1px solid #e0e0e0;
}
.header-accordion-title {
  font-size: 14px;
  font-family: "EuclidFlex-Medium";
  margin: 0;
}

.react-datepicker-wrapper {
  width: calc(100% - 125px) !important;
  float: left !important;
}

.react-datepicker-wrapper input {
  width: 100% !important;
}

.attachment-msg {
  float: left;
  padding: 0 13px;
  cursor: pointer;
}
.attachment {
  background: url(../images/attachment.png) no-repeat;
  width: 17px;
  height: 14px;
  display: inline-block;
}

.rbc-events-container .rbc-event-label:first-of-type {
  display: none;
}

.rbc-event-content .rbc-event-label {
  display: block !important;
}

.appointment-popups {
  display: block;
}

.rbc-day-slot .rbc-event {
  overflow: visible;
  min-height: 22px !important;
}

.top-info h4 {
  color: black;
}

.rbc-event-content {
  padding: 0;
}

.rbc-event {
  top: 0;
  left: 0;
}

.ReactModal__Overlay {
  -webkit-backface-visibility: hidden;

  height: 100%;
  left: 0;
  outline: none;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 44px;
  position: absolute;
  text-align: center;
  top: 0;
  transition-property: transform, opacity;
  white-space: normal;
  width: 100%;
  z-index: 99994;
}

#export-data-popup .react-datepicker-wrapper {
  width: 100% !important;
  position: relative;
  margin-right: 10px;
}

#export-data-popup .react-datepicker-wrapper input {
  width: 100% !important;
  min-width: 100% !important;
}

#export-data-popup .login-submit {
  width: 100%;
  margin-top: 41px;
}

#export-data-popup .login-submit button {
  margin-left: 15px;
}

.rbc-time-view {
  height: 584px;
}

.rbc-current-time-indicator {
  background-color: black;
}

.rbc-month-view {
  height: 100%;
}

.rbc-month-row .rbc-event {
  background-color: transparent;
}

.rbc-month-row .rbc-event .rbc-event-label {
  background-color: transparent;
  font-family: "EuclidFlex-Medium";
  color: #4f4f4f;
  font-size: 11px;
  /* float: right; */
  float: right;
  margin-right: 0px;
}

.rbc-month-row .event-stuff {
  background: url(../images/green-dots.svg) no-repeat left;
  padding-left: 10px;
  font-family: "EuclidFlex-Medium";
  color: #4f4f4f;
  font-size: 11px;
  float: left;
  /* display: inline-block !important; */
  /* margin-left: 20px; */
}
.event-btns .archive-show ul li {
  display: block;
  /* padding: 9px 15px; */
}
.event-btns .archive-show {
  min-width: 185px;
  /* padding: 9px 15px; */
  border: 1px solid #e0e0e0;
  padding: 0;
  border-radius: 5px;
}

.event-btns .archive-show ul li a {
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.03em;
  color: #4f4f4f;
  position: relative;
  padding-left: 15px;
}

.archive-show li a {
  border-top: 1px solid #e0e0e0;
  width: 100%;
  display: block;
  padding: 9px 15px;
  width: 100%;
  border-top: 1px solid #e0e0e0;
  display: block;
  padding: 9px 15px !important;
}

.react-datepicker-popper {
  z-index: 9999999999 !important;
}

.innermsg-info textarea {
  height: 100%;
  width: 100%;
  border: 0px;
  resize: none;
}

.anycontact span {
  color: #4f4f4f;
  font-size: 14px;
  line-height: 17px;
  font-family: "ProximaSoft-Regular";
  font-size: 14px;
}

.event-head h2 {
  font-family: "EuclidFlex-Medium";
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 30px;
  display: flex;
  align-items: center;

  /* Charcoal */

  color: #343642;
}

.innermsg-info textarea {
  font-family: "ProximaSoft-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 200%;
  /* or 28px */

  /* Gray 2 */

  color: #4f4f4f;
}

.matched-text {
  background: rgba(156, 169, 226, 0.3);
  border-radius: 2px;
  font-family: "EuclidFlex-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;

  /* Gray 2 */
  display: inline;
  color: #4f4f4f;
  padding: 2px;
}
.chat-text.screenshot-download {
  max-width: 100%;
}
.screenshot-download .chatbox {
  /* max-width: 100%; */
  background-image: url(../images/export-arrow.svg);
  background-repeat: no-repeat;
  background-position: 96% 35%;
  padding: 0;
  padding-right: 38px;
}
.screenshot-download .chatbox a {
  padding: 12px;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  color: white;
  font-weight: bold;
  text-align: right;
}

.upload-imgs-main {
  padding: 10px 25px 10px 25px;
  width: 100%;
  vertical-align: bottom;
  position: absolute;
  left: 0;
  bottom: 106px;
}
.upload-imgs {
  background: #f2f2f2;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  position: relative;
  padding: 11px;
  overflow: hidden;
}
.upload-imgs #custom-text {
  font-size: 12px;
  color: #4f4f4f;
  top: 13px;
  left: 12px;
  padding-left: 16px;
}
.upload-imgs #custom-text:before {
  position: absolute;
  left: 0;
  top: 4px;
  content: "";
  /* background: url(../images/close-primary.png) no-repeat; */
  width: 8px;
  height: 8px;
}
.upload-imgs #custom-button {
  position: absolute;
  right: 0;
  top: 0;
  min-width: 100px;
  font-size: 10px;
  font-family: "EuclidFlex-Regular";
  color: #4f4f4f;
  background: transparent;
  border-radius: 0;
  margin: 0;
  background: #fff url(../images/upload-arrow.png) no-repeat 89% center;
  padding: 2px;
}

.half-group .right p {
  text-align: left !important;
}

.rbc-agenda-event-cell {
  position: relative;
}

.rbc-agenda-event-cell .appointment-popups {
  /* position: relative; */
  top: 30%;
  left: 50%;
}

.rbc-day-slot .appointment-popups {
  /* position: relative; */
  top: 30%;
  left: 0%;
}

.snooze-datetime-main {
  margin: 0 0 5px 0;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}

/* .rbc-day-slot .rbc-event {
  width: 25% !important;
  background-color: #6dcdb8;
} */
/* .rbc-time-slot {
  min-height: 8px;
} */

.rbc-day-slot .rbc-event {
  height: 100%;
}

.reserved {
  background-color: grey;
}

/* .delete-reserved {
  margin: 30px 0 10px 0;
} */

.delete-reserved a {
  padding: 10px;

  display: inline-block;
  border: 1px solid grey;
  border-radius: 8px;
}

.red-input {
  border-color: red !important;
}

.office-form .common-input {
  color: black;
}
.archive-show {
  width: 161px !important;
}

.switch-main {
  padding: 10px;
}

.patients-only-bg {
  height: 40px;
}

.notify {
  font-size: 12px;
}

.waittingroom-main .archive-show {
  left: auto !important;
  right: -50px !important;
}

.patients-only-bg .notify {
  margin-left: 10px;
}

.booked_patient {
  background-color: #2a3688;
}

.snooze-date-time .office-time.o-date {
  width: 25%;
}

.office-time input.form-control {
  font-size: 11px;
}

/*** bulletin page css start ***/
.stage3-pages .dashboard-title h2 {
  color: #2a3688;
}
.addnew {
  color: #fff;
  min-width: 107px;
}
.addnew-post-main {
  margin: 8px 0 35px 0;
}
.stage3-pages .patients-table table th {
  color: #2a3688;
  text-transform: unset;
}
.bulletin-table table th,
.bulletin-table table tr td {
  padding-left: 60px;
  padding-right: 60px;
}
.delete-post {
  background: url(../images/delete.svg) no-repeat;
  width: 14px;
  height: 16px;
  display: inline-block;
}
.post-delete {
  text-align: right;
}
.post-error {
  background: #fee398;
  border-radius: 5px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 11px;
  height: 35px;
  padding: 10px 15px;
}
.post-error p strong {
  font-weight: 500;
}
.post-error p {
  font-size: 13px;
  line-height: 15px;
  color: #333;
  padding: 0;
}
.switch {
  position: relative;
  width: 30px;
  height: 17px;
  float: left;
}
.app-settings-main > h4 {
  font-family: "EuclidFlex-Medium";
  padding: 23px 10px 23px 33px;
  margin: 0;
  font-weight: 500;
  font-size: 18px;
  color: #343642;
}
.app-settings-main li a {
  font-size: 14px;
  font-family: "ProximaSoft-Regular";
  color: #4f4f4f;
  padding: 14px 14px 14px 33px;
  display: inline-block;
  vertical-align: middle;
  border-top: 1px solid #f2f2f2;
  width: 100%;
}
.app-settings-main li a:hover,
.app-settings-main li a:focus,
.app-settings-main li.active a {
  background: rgba(242, 242, 242, 0.5);
}
.main-appointment-page .col-7 {
  width: 72.2%;
  padding-left: 20px;
}
.main-appointment-page .app-setting-left .white-box,
.main-appointment-page .app-setting-right .white-box {
  height: 100%;
  min-height: 547px;
}
.app-setting-right .white-box {
  padding: 41px 35px;
}
.app-setting-head {
  display: inline-block;
  width: 100%;
  margin-bottom: 20px;
  vertical-align: middle;
}
.app-setting-head h4 {
  float: left;
  width: calc(100% - 107px);
  padding-right: 20px;
  font-family: "EuclidFlex-Medium";
  font-size: 18px;
  font-weight: 500;
  color: #343642;
}
.app-setting-head .addnew {
  float: right;
  margin-top: -4px;
}
.automatic-rpl p {
  line-height: 17px;
}
.automatic-rpl table tr {
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  height: 46px;
  border-bottom: 10px solid #fff;
}
.automatic-rpl table .first-td {
  padding-left: 20px;
}
.edit-app a {
  background: url(../images/edit-app.svg) no-repeat;
  width: 14px;
  height: 14px;
  display: block;
}
.close-app a {
  background: url(../images/close-app.svg) no-repeat center center;
  width: 30px;
  height: 30px;
  display: block;
  border-left: 1px solid rgba(224, 224, 224, 0.5);
  padding: 0 25px;
}
.close-app {
  width: 45px;
}
.close-app a:hover,
.close-app a:focus,
.edit-app a:hover,
.edit-app a:focus {
  opacity: 0.5;
}
.d-none {
  display: none;
}
/* appointment-settings */

.automatic-rpl-box {
  width: 100%;
  display: table;
}
.automatic-rpl-row {
  display: flex;
  font-family: "ProximaSoft-Regular";
  font-weight: 400;
  width: 100%;
  align-items: center;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 8px 0px 8px 52px;
  margin-bottom: 10px;
}
.automatic-rpl-title {
  width: 33%;
  text-transform: capitalize;
  padding-right: 15px;
}
.automatic-rpl-date {
  width: 61%;
  padding-right: 15px;
}
.automatic-rpl-edit {
  float: left;
  margin-right: 16px;
}
.edit-app {
  display: table;
}
.dashboard-top {
  margin-bottom: 28px;
  margin-top: 4px;
}
.leftbar {
  /* min-height: 100vh; */
  bottom: 0;
}
.go-back {
  margin-bottom: -2px;
  margin-right: 14px;
}

/*End appointment-settings */

/* new-message */

.message-titlebox {
  display: flex;
  align-items: center;
}
/* .feild-label {text-transform: capitalize;color: #4F4F4F;min-width: 103px;padding: 10px 0;display: inline-block;} */
.feild-label {
  text-transform: capitalize;
  color: #4f4f4f;
  min-width: 103px;
  padding: 0;
  display: inline-flex;
  align-items: center;
  height: 37px;
}
.message-title {
  margin-right: 21px;
  float: left;
}
.form-feild {
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 9px 10px;
  display: block;
  /* text-transform: capitalize; */
}
span.created-date {
  color: #bdbdbd;
  font-family: "ProximaSoft-Regular";
  font-size: 12px;
}
.w-100 {
  width: 100% !important;
}
.field-space {
  margin-top: 29px;
  display: inline-block;
  width: 100%;
}
.half-field {
  width: 50%;
  float: left;
}
.message-titlebox input.form-feild {
  width: calc(100% - 124px);
}

/* Switch */

.switch-custom {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
  outline: 0 !important;
}
.switch-custom input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch-custom .radio-span {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e0e0e0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 20px;
}
.switch-btn::before {
  background: #f2f2f2;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.switch-custom .radio-span:before {
  position: absolute;
  content: "";
  height: 11px;
  width: 11px;
  left: 4px;
  bottom: 3px;
  background-color: #828282;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 20px;
}
input:checked + .switch-btn {
  background-color: #bdbdbd;
}
input:checked + .switch-btn:before {
  -webkit-transform: translateX(12px);
  -ms-transform: translateX(12px);
  transform: translateX(12px);
  background-color: #4f4f4f;
  background-color: #4f4f4f;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.switch-custom input:checked + .radio-span:before {
  background: #3e4896;
}
.switch-custom input:focus + .radio-span {
  box-shadow: 0 0 1px #2196f3;
}
.switch-custom input:checked + .radio-span:before {
  -webkit-transform: translateX(10px);
  -ms-transform: translateX(10px);
  transform: translateX(10px);
}
.switch-custom .radio-span.round {
  border-radius: 34px;
}
.switch-custom .radio-span.round:before {
  border-radius: 50%;
}
.time-period {
  display: table;
  width: 100%; /* margin-top: 27px; */
  border: 1px solid #e0e0e0;
  padding: 13px 19px;
  border-radius: 5px;
}
.time-period label.switch {
  float: right;
}
.time-period label.switch-custom {
  float: right;
}
.half-field .form-feild,
.half-field-second .form-feild {
  width: 412px;
  max-width: 100%;
  font-family: "ProximaSoft-Regular";
}

.form-feild::-webkit-input-placeholder {
  color: #4f4f4f !important;
  opacity: 0.5 !important;
}
.form-feild:-ms-input-placeholder {
  color: #4f4f4f !important;
  opacity: 0.5 !important;
}
.form-feild::placeholder {
  color: #4f4f4f !important;
  opacity: 0.5 !important;
}

/*End Switch */

.tag-box {
  width: 100%;
  margin-top: 5px;
}
.tag-box-template {
  padding-top: 30px;
}
.tag-box-template label.feild-label img {
  margin-left: 10px;
  margin-bottom: -3px;
}
.tag-box-item {
  display: table;
  background-color: rgb(156 169 226 / 30%);
  text-transform: capitalize;
  color: #4f4f4f;
  padding: 7px 7px 3px 7px;
  font-size: 13px;
  margin-bottom: 9px;
  border-radius: 2px;
}
.half-field-second {
  padding-left: 30px;
  width: 50%;
  display: inline-block;
}
.btn {
  outline: 0 !important;
  font-size: 13px;
  font-family: "EuclidFlex-Regular";
  border-radius: 5px;
  font-weight: 400;
  box-shadow: 0 0 !important;
}
.btn-primary {
  background: #3e4896;
  border: 1px solid #3e4896;
  color: #fff;
}
.text-capitalize {
  text-transform: capitalize;
}
.btn-sm {
  padding: 11px 10px 9px 10px;
  line-height: 1;
}
.btn-white {
  color: #4f4f4f;
  background: transparent;
  border: 1px solid #e0e0e0;
}
.buttonbox {
  margin-top: 40px;
}
.buttonbox .btn {
  margin-right: 13px;
}
.buttonbox .btn:last-child {
  margin-right: 0;
}
.btn-primary:hover {
  background: transparent;
  border-color: #3e4896;
  color: #3e4896;
}
.btn-white:hover {
  color: #fff;
  background: #4f4f4f;
  border-color: #4f4f4f;
}
.tag-box-template .feild-label {
  font-family: "EuclidFlex-Medium";
}
.resize-icon {
  background-repeat: no-repeat;
  background-position: 98% 98%;
}
.textarea-icon {
  position: relative;
  max-width: 100%;
  display: inline-block;
}
.textarea-icon::after {
  background-image: url("../images/textarea.svg");
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  right: 2px;
  bottom: 2px;
}
.main-appointment-page .app-setting-left .white-box,
.main-appointment-page .app-setting-right .white-box {
  height: inherit;
  margin-bottom: 50px;
}

.time-box-active {
  display: block;
}
.time-picker,
.date-picker {
  position: relative;
}
.time-box .time-picker,
.time-box .date-picker {
  width: 107px;
  float: left;
}
.time-box label {
  float: left;
  padding-right: 13px;
  padding-top: 12px;
}
.time-box .date-picker {
  margin-right: 13px;
}
.time-box {
  padding-top: 26px;
}
.date-picker .form-feild,
.time-picker .form-feild {
  background-color: rgb(242 242 242 / 50%);
  border-color: transparent !important;
  padding-bottom: 10px;
}
.date-picker input[type="date"]::-webkit-calendar-picker-indicator,
.time-picker input[type="time"]::-webkit-calendar-picker-indicator {
  background-image: none;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9999 !important;
}
.date-picker .bootstrap-datetimepicker-widget.dropdown-menu.top,
.time-picker .bootstrap-datetimepicker-widget.dropdown-menu.top {
  left: 0 !important;
  right: inherit !important;
}
.buttonbox .btn {
  float: left;
}
.submit-message {
  display: inline-block;
  font-weight: 400;
  padding: 0;
  max-width: 243px;
  width: 100%;
  margin-left: 18px;
  margin-top: -2px;
}
.unsave-model .sub-heading {
  padding: 23px 20px;
}
.unsave-contant {
  padding: 20px;
}
.model-footerbuttonbox {
  padding: 4px 24px 24px 24px;
  text-align: right;
}
.model-footerbuttonbox .btn {
  margin-right: 12px;
}
.unsave-contant h3 {
  font-size: 18px;
  margin-bottom: 5px;
  color: #343642;
  font-family: "EuclidFlex-Medium";
}
.forgot-pass-form .sub-heading h3 {
  font-family: "ProximaSoft";
  font-weight: 600;
}
.unsave-contant p {
  padding-bottom: 17px;
}
.resize-x {
  resize: vertical;
}
/* general-information */
.general-information-ul {
  margin-top: 0;
}
.general-information-ul li {
  font-size: 14px;
  padding: 8px 31px;
  margin-bottom: 5px;
  position: relative;
}
.general-information-ul li:after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  left: 0;
  background: #6dcdb8;
  top: 9px;
  border-radius: 50%;
}
.info-02:after {
  background: #308572 !important;
}
.info-03:after {
  background: #6475bd !important;
}
.info-04:after {
  background: #c66f6f !important;
}
.head-general-information h4 {
  font-family: "EuclidFlex-Medium";
  margin-bottom: 12px;
}
.general-information-whitebox {
  min-height: 562px !important;
}
.field-space label.feild-label {
  width: 100%;
}
.timeslot-box {
  width: 100%;
}
.timeslot-box-row {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
}
.timeslot-starttime {
  margin-right: 21px;
  width: 103px;
  text-transform: capitalize;
}
label.timeslot-endtime {
  width: 73px;
  text-align: center;
  margin-right: 5px;
  margin-left: 5px;
}
.timeslot-box-time-piker {
  width: 87px;
}
.timeslot-box-time-piker .form-control {
  width: 100%;
  font-size: 14px;
  padding: 9px 10px;
  background: transparent !important;
  border: 1px solid #e0e0e0 !important;
}
.form-fieldbox input[type="text"] {
  color: #4f4f4f !important;
}
.section-head p {
  padding-bottom: 0;
}
.timeslot-box-time-piker
  .date-picker
  .bootstrap-datetimepicker-widget.dropdown-menu,
.timeslot-box-time-piker
  .time-picker
  .bootstrap-datetimepicker-widget.dropdown-menu {
  left: -60px !important;
  right: inherit !important;
}
.covid-timeslot {
  display: inline-block;
  padding-top: 23px;
}
.secondart-title h3 {
  font-size: 18px;
  color: #343642;
  font-family: "EuclidFlex-Medium";
  margin-bottom: 21px;
}
.timeslot-row .feild-label {
  min-width: 78px;
  text-transform: none;
  float: left;
  width: 78px;
  margin-right: 44px;
  padding: 0;
}
.timeslot-row {
  display: inline-block;
  margin-bottom: 20px;
}
.ui-selectmenu-button {
  background: transparent;
  padding: 9px 8px;
}
.timeslot-row .ui-selectmenu-button {
  width: 289px !important;
  margin: 0;
  background: transparent;
  padding: 9px 8px !important;
}
.ui-icon-triangle-1-s {
  background-position: -65px 0px !important;
}
.ui-selectmenu-button {
  border-radius: 5px !important;
  border-color: #e0e0e0 !important;
}
.ui-selectmenu-button.ui-button:focus {
  background: transparent;
  outline: 0;
  border-color: #e0e0e0;
}
.ui-state-active,
.ui-menu .ui-menu-item:hover .ui-menu-item-wrapper {
  background: #3e4896 !important;
  border-color: #3e4896 !important;
}
.timeslot-buttonbox {
  margin-top: 46px;
}
.btn-primary[disabled="true"] {
  opacity: 0.5;
}
.secondart-title p {
  padding-bottom: 23px;
}
.ui-selectmenu-button.ui-button:hover,
.ui-selectmenu-button.ui-button:active {
  background: transparent;
  border-color: #e0e0e0;
  color: #4f4f4f;
}
.ui-selectmenu-text {
  color: #4f4f4f !important;
  font-family: "ProximaSoft-Regular" !important;
  text-transform: capitalize;
}
.timeslot-buttonbox .btn {
  width: 110px;
  text-align: center;
}
.ui-selectmenu-button-open .ui-icon {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
}
.half-field .form-feild {
  width: 100%;
  color: #4f4f4f;
  font-size: 14px; /* font-family: 'ProximaSoft-Regular'; */
}
.half-field .textarea-icon {
  width: 100%;
}
.unsave-model-popup {
  padding: 0;
}
/*End general-information */

/* new-appointment */

.title-goback h2,
.app-settings-main > h4,
.app-settings-main li a,
.app-setting-head h4 {
  text-transform: capitalize;
}
.form-select + .ui-selectmenu-button {
  background: transparent;
  font-family: "ProximaSoft-Regular";
  width: 100% !important;
  height: 35px;
  margin: 0;
  padding: 7px 15px;
}
.feild-row {
  display: table;
  margin-bottom: 15px;
}
.field-left {
  width: 103px;
  float: left;
  margin-right: 21px;
}
.form-fieldbox {
  width: calc(100% - 144px);
  display: inline-block;
}
.form-fieldbox .time-picker {
  width: 100%;
}
.sidebar-with-dropdown .white-box {
  margin-bottom: 20px !important;
  min-height: 432px !important;
}
.sidebar-dropdownbox {
  padding: 13px;
  background: #fff;
  border-radius: 5px;
}
.menu-deopdownsub-ul {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  overflow: hidden;
  background: #fff;
  margin-bottom: 13px;
}
.menu-deopdown-li > ul {
  width: 100%;
  right: 0;
  left: 0;
  top: -126px;
  position: absolute;
  display: none;
}
.btn-lg {
  display: table;
  padding: 14px 20px 10px;
  text-align: center;
}
.sidebar-dropdownbox .btn-lg {
  width: 100%;
  margin: 0;
}
.sidebar-dropdownbox .dropdown-menu {
  margin-top: 13px;
}
.menu-deopdown-li:hover > ul {
  display: block !important;
  position: absolute;
  width: 100%;
}
.menu-deopdown-li:hover .btn-white {
  color: #fff;
  background: #4f4f4f;
  border-color: #4f4f4f;
}
.menu-deopdownsub-ul li {
  display: table;
  width: 100%;
  margin: 0 !important;
}
.menu-deopdownsub-ul li a {
  font-size: 12px;
  text-transform: capitalize;
  display: block;
  padding: 11px 15px;
  border-bottom: 1px solid #f2f2f2;
}
li.menu-deopdown-li {
  position: relative;
}
.menu-deopdownsub-ul li:last-child a {
  border: 0;
}
.menu-deopdownsub-ul li:hover a {
  background: #343642;
  color: #fff;
}
.arrow-hover {
  display: none;
}
.btn:hover .arrow-normal,
.menu-deopdown-li:hover .btn-white .arrow-normal {
  display: none;
}
.btn:hover .arrow-hover,
.menu-deopdown-li:hover .btn-white .arrow-hover {
  display: inline-block;
}
.arrow-image {
  margin-left: 5px;
}
.sidebar-with-dropdown-innerbox {
  min-height: 574px !important;
}
.print-model .sub-heading {
  padding: 13px 17px 10px 17px;
}
.print-model .sub-heading .fancybox-close-small {
  top: 13px;
  right: 20px;
  width: 13px;
  height: 12px;
}
.print-model .unsave-contant {
  padding: 29px 19px 35px 19px;
}
.print-type {
  margin-top: 22px;
  display: table;
}
label.print-type-label {
  float: left;
  font-family: "ProximaSoft-Regular";
  display: inline-block;
}
.francy-radiobuttonbox {
  margin-left: 94px;
  display: inline-block;
}
.radiobuttonbox-label input,
.radiobuttonbox-label input + span:after,
.checkbox-them input[type="checkbox"],
.checkbox-them input[type="checkbox"] + span:after {
  left: 0 !important;
  appearance: radio;
  -webkit-appearance: radio;
  position: absolute;
  top: 4px;
  width: 9px;
  content: "";
  width: 7px;
  height: 7px;
}
.radiobuttonbox-label input,
.checkbox-them input[type="checkbox"] {
  opacity: 0;
}
label.radiobuttonbox-label {
  font-family: "ProximaSoft-Regular";
  text-transform: capitalize;
}
.francy-radiobuttonbox label.radiobuttonbox-label {
  margin-right: 69px;
}
label.radiobuttonbox-label,
.checkbox-them {
  padding-left: 22px;
  position: relative;
  cursor: pointer;
}
.radiobuttonbox-label input + span:after,
.checkbox-them input[type="checkbox"] + span:after {
  border: 1px solid #bdbdbd;
}
.radiobuttonbox-label input:checked + span:after,
.checkbox-them input[type="checkbox"]:checked + span:after {
  background: #4f4f4f;
}
.print-model .model-footerbuttonbox {
  padding-top: 0;
}
.print-model .sub-heading h3 {
  font-family: "ProximaSoft-Regular";
}
/*End new-appointment */

.cancel-event {
  padding: 29px 19px 36px;
}
.model-h3 {
  font-size: 19px;
  color: #343642;
}
.cancel-event .print-type {
  margin: 0;
}
.cancel-event .print-type p {
  padding-bottom: 0;
}
.cancel-event-radiobox {
  padding: 15px 0;
  margin-bottom: 22px;
  display: inline-block;
}
.cancel-event-radiobox label.radiobuttonbox-label:nth-child(2) {
  float: right;
}
.cancel-event .print-type p,
.cancel-event-radiobox {
  padding-right: 46px;
}
.cancel-event-radiobox label.radiobuttonbox-label {
  text-transform: inherit;
}
.ui-selectmenu-open {
  z-index: 99999 !important;
}

/* patient-details */

.form-radiobuttonbox {
  width: 100%;
  padding-top: 10px;
}
.form-radiobuttonbox label.radiobuttonbox-label:last-child {
  float: right;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.form-field-space {
  margin-bottom: 38px;
}
.inner-header h5 {
  font-size: 13px;
  color: #343642;
  font-family: Euclid Flex;
  margin-bottom: 16px;
}
.fieldvalue-full {
  max-width: 289px;
}
.field-full .feild-label {
  text-transform: inherit;
}
.field-full {
  margin-bottom: 18px;
}
.fieldvalue-full label.radiobuttonbox-label:last-child {
  float: right;
  margin-right: 96px;
}
.field-full .ui-selectmenu-button.ui-button {
  margin-bottom: 12px;
}
.checkbox-them input[type="checkbox"] {
  appearance: checkbox;
  -webkit-appearance: checkbox;
}
.checkboxitems label.checkbox-them {
  margin-top: 6px;
  margin-bottom: 6px;
  display: inline-block;
  width: 100%;
}
.form-field-space .checkboxitems {
  width: 660px;
  max-width: 100%;
}

.details-box-header {
  margin-top: 71px;
}
.text-transform-inherit,
.text-transform-inherit + .ui-selectmenu-button .ui-selectmenu-text {
  text-transform: inherit;
}

/*End patient-details */

.appoinment select {
  width: 100%;
}

.timeslot-box-time-piker input {
  width: 100%;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

/* Add Office Page */
.add-office-page .col-7.app-setting-right {
  padding-left: 0;
}

.add-office-page .col-3.app-setting-left {
  padding-left: 20px;
}

.add-office-page .feild-row {
  width: 51% !important;
}

.add-office-page .inner-header h5 {
  color: #4f4f4f;
}
.updated-section .col-7.app-setting-right {
  padding-left: 0;
}

.updated-section .col-3.app-setting-left {
  padding-left: 20px;
}

.tabber-group .item {
  background-color: #4f5895;
  color: #fff;
  flex: 1;
  font-family: "EuclidFlex-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 15px 10px;
  border: 1.5px solid #38417c;
  justify-content: center;
}

.tabber-group {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.add-office-page .white-box {
  padding: 23px;
}
.updated-section .white-box {
  padding: 23px;
}

.tabber-group .item.active {
  background: #38417c;
}

.add-office-page .content-wrapper {
  width: 93%;
}
.add-office-page .content-group {
  width: 93%;
}
.updated-section .content-wrapper {
  width: 93%;
}
.updated-section .content-group {
  padding: 0 5px;
}
.tabber-group .item:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.tabber-group .item:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.mb-23 {
  margin-bottom: 23px;
}
.tabber-group .item span {
  opacity: 0.5;
}
.tabber-group .item.active span {
  opacity: 1;
}
.common-btn-updated {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  text-align: center;
  padding: 10px 23px;
  font-family: "EuclidFlex-Medium";
  box-shadow: none;
  border: 0;
  min-width: 95px;
}

.bottom-buttons {
  display: flex;
}
.error.form-feild {
  border-color: red;
}
.input-error {
  border-color: red;
}
.flex-end {
  justify-content: flex-end;
}
.common-btn-updated.white-btn.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.common-btn-updated.white-btn {
  color: #4f4f4f;
  background: #fff;
  border: 1px solid #e0e0e0;
}

.mr-15 {
  margin-right: 15px;
}

.mb-26 {
  margin-bottom: 26px;
}

.main-appointment-page.add-office-page .white-box {
  margin-bottom: 0;
  height: 100%;
}

.mt-44 {
  margin-top: 44px;
}
.add-office-page select {
  background: url("../images/select-arrow.svg") no-repeat 97%;
}

.add-office-page .switch-btn {
  background-color: #f2f2f2 !important;
}

.add-office-page .switch-btn::before {
  background-color: #4f4f4f;
}

.feild-row.admin-access {
  display: flex;
  align-items: center;
}

.question-tooltip-con {
  position: relative;
  padding-left: 10px;
}

.tooltip-box {
  background: #f2f2f2;
  padding: 8px;
  width: 214px;
  position: absolute;
  top: 0;
  left: 50px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s linear;
}

.tooltip-box p {
  padding: 0;
  line-height: 115%;
  color: #4f4f4f;
  font-size: 11px;
}

.feild-row.admin-access .switch {
  float: none;
}

.feild-row.admin-access .form-fieldbox {
  display: flex;
  align-items: center;
}

.add-office-page p {
  color: #4f4f4f;
  line-height: 120%;
}

.top-heading {
  margin-bottom: 6px;
}
.common-btn-updated.yellow-btn {
  background: #fee398;
  padding: 10px 11px;
  display: inline-block;
}

.question-tooltip-con:hover .tooltip-box {
  opacity: 1;
  visibility: visible;
}
.order-summary-list {
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 5px;
}

.order-summary-list .item:not(:last-child) {
  border-bottom: 1px solid #f2f2f2;
}

.order-summary-list .item {
  font-size: 12px;
  color: #4f4f4f;
  font-family: "ProximaSoft-Regular";
  font-style: normal;
  line-height: 120%;
  display: flex;
  align-items: center;
  padding: 5px 14px 5px 16px;
  min-height: 44px;
}

.order-summary-list .label-content {
  font-weight: 500;

  text-transform: capitalize;
  width: calc(100% - 94px);
}

.order-summary-list .label {
  width: 73px;
  margin-right: 21px;
}

.heading {
  font-family: "EuclidFlex-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #4f4f4f;
  line-height: 23px;
  margin-bottom: 25px;
}

.subheading {
  font-family: "EuclidFlex-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 127.69%;
  color: #343642;
  margin: 10px 0;
}

.checkbox-wrapper [type="checkbox"]:checked + label::before,
.checkbox-wrapper [type="checkbox"]:not(:checked) + label::before {
  border: 1px solid #bdbdbd;
  border-radius: 2px;
  width: 8px;
  height: 8px;
}

.checkbox-wrapper [type="checkbox"]:checked + label::after,
.checkbox-wrapper [type="checkbox"]:not(:checked) + label::after {
  border-radius: 1px;
  left: 1px;
  width: 8px;
  height: 8px;
  top: 3px;
}

.checkbox-wrapper [type="checkbox"] + label {
  display: flex;
  align-items: center;
  padding-left: 22px;
}

.checkbox-wrapper p {
  padding: 0;
  position: relative;
  top: -2px;
}

.checkbox-wrapper {
  margin-top: 21px;
  margin-bottom: 36px;
}
.updated-wrapper {
  font-family: Proxima Soft;

  margin-top: 45px;
}
.add-new-appointments {
  counter-reset: section;
}
.updated-section {
  color: #4f4f4f;
}

.updated-section h4 {
  font-family: Euclid Flex;
}

.updated-section .accor-title {
  background-color: #f8f8fd;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  position: relative;
  border-radius: 5px;
  font-family: Euclid Flex;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  padding: 15px 18px;
  line-height: 127.69%;
  margin-bottom: 11px;
  cursor: pointer;
}
.del-btn {
  font-family: Proxima Soft;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 120%;
  width: 54px;
  height: 24px;
  position: absolute;
  right: 50px;
  top: 10px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.add-another-btn {
  background: #fee398;
  border-radius: 5px;
  padding: 16px 12px;
  cursor: pointer;
  display: block;
  font-size: 13px;
  margin-bottom: 19px;
  line-height: 16px;
  font-family: Euclid Flex;
  font-style: normal;
}
.updated-section .accor-title.active {
  margin-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.updated-section .accor-title span.arrow {
  background: url(../images/select-arrow-new.png) no-repeat;
  position: absolute;
  right: 22px;
  display: block;
  top: 50%;
  width: 10px;
  height: 8px;
  transform: translateY(-50%);
}

.updated-section .accor-title.active span.arrow {
  transform: translateY(-50%) rotate(180deg);
}

.blue-btn.common-btn-updated {
  background: #38417c;
  color: #fff;
}

.office-created-section {
  margin-top: 10px;
}
.office-created-section .common-btn-updated {
  min-width: 170px;
  margin-top: 24px;
}
.add-office-page input,
.add-office-page select {
  font-family: "ProximaSoft-Regular";
  font-weight: normal;
  font-size: 14px;
  line-height: 120%;
  color: #4f4f4f;
}
.order-summary-list .item.users {
  flex-wrap: wrap;
}

.order-summary-list .item-inner {
  width: 100%;
  display: flex;
  min-height: 44px;
  align-items: center;
}

.order-summary-list .item.users > div:last-child {
  margin-bottom: 5px;
}

.order-summary-list .users-list > div {
  margin-bottom: 5px;
}

.add-office-page input::placeholder {
  opacity: 1 !important;
}

.form-fieldbox.field-left.timefield {
  box-sizing: border-box;
  border-radius: 5px;
  width: 87px;
  margin-right: 0;
  display: block;
}

.feild-label.field-left.end {
  width: 78px;
  justify-content: center;
  text-transform: none;
  margin-right: 5px;
  display: flex;
  min-width: auto;
}

.week-item {
  display: flex;
  margin-bottom: 10px;
}

.week-item-wrapper {
  margin-bottom: 24px;
  border-top: 1px solid grey;
  padding-top: 20px;
}

.weekdays-list {
  padding-bottom: 44px;
  margin-top: 15px;
}
.forgot-pass-form.add-new-office-popup .sub-heading h3 {
  font-weight: 500;
  font-size: 14px;
  color: #38417c;
  line-height: 14px;
  font-family: "ProximaSoft-Regular";
}

.forgot-pass-form.add-new-office-popup .sub-heading {
  padding: 16px;
}

.forgot-pass-form.add-new-office-popup .inner-popup-content {
  padding: 20px;
}

.add-new-office-popup .form-head.forgot-pass-head {
  margin-bottom: 15px;
}

.common-form.add-new-office-popup {
  background: #ffffff;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
}

.common-form.add-new-office-popup .common-btn-updated {
  padding: 9px 12px;
}

.common-form.add-new-office-popup .common-btn-updated.blue-btn {
  background-color: #4f5895;
  line-height: normal;
}
@media (max-width: 991px) {
  .add-office-page .col-7.app-setting-right,
  .add-office-page .col-3.app-setting-left {
    width: 100%;
  }
  .add-office-page {
    flex-wrap: wrap;
  }
  .add-office-page .col-3.app-setting-left {
    padding-left: 0;
    margin-top: 20px;
  }
}

.create-new-office-button {
  background: #f2f2f2;
  border-radius: 5px;
  font-family: "EuclidFlex-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;

  /* Cyber Grape */

  color: #38417c;

  /* Inside Auto Layout */
  border: 0;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 10px 0px;
  padding: 9px 12px;
}
.updated-wrapper .accor-body {
  border: 1px solid #e0e0e0;
  border-top: 0;
  border-radius: 5px;
  margin-bottom: 19px;
  padding: 20px 20px 30px 20px;
}
.left_label .field-full.w-100 {
  display: flex;
  align-items: center;
}

.updated-wrapper input,
.updated-wrapper select {
  color: #4f4f4f;
  font-family: Proxima Soft;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 120%;
}
.counter-increment {
  padding-left: 42px;
  position: relative;
}

.counter-increment:before {
  counter-increment: section;
  content: counter(section);
  align-self: baseline;
  font-family: Euclid Flex;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;

  /* Cyber Grape */

  color: #38417c;

  /* Inside Auto Layout */
  border: 0;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 10px 0px;
  padding: 9px 12px;
}
/* calendar */
.rbc-day-slot .rbc-event .rbc-event {
  border: 2px solid #fff;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  padding: 5px 7px;
}

.rbc-event-content.event-stuff {
  text-transform: uppercase;
  height: auto;
}

.rbc-day-slot .rbc-event .rbc-event > div {
  width: 100%;
  height: auto;
  flex: none;
}
.rbc-timeslot-group {
  flex-flow: row;
  align-items: center;
}

.rbc-label {
  color: #bdbdbd;
  opacity: 1;
}

.rbc-calendar {
  flex-direction: row-reverse;
}

.rbc-time-view {
  width: 70%;
  border-top: 0;
  border-radius: 5px;
}

.white-box.calendar-main {
  box-shadow: none;
}

.rbc-calendar {
  flex-direction: row-reverse;
  position: relative;
  padding-top: 15px;
}

.rbc-toolbar {
  width: 30%;
  border-radius: 5px;
  padding: 0 0;
  display: block;
}

.rbc-time-view {
  width: 70%;
  border-top: 0;
  border-radius: 5px;
}

.white-box.calendar-main {
  box-shadow: none;
}
.toolbar-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
  width: 70%;
  border-bottom: 1px solid #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 57px;
  font-family: "EuclidFlex-Medium";
  border-right: 1px solid #f2f2f2;
  padding-right: 13px;
}

.toolbar-header .rbc-btn-group button.noborder {
  margin: 0 4px !important;
  padding: 0 !important;
}

.toolbar-header .button-wrap {
  margin-right: 17px;
  padding-right: 25px;
  border-right: 1px solid #f2f2f2;
  display: flex;
  align-items: center;
  padding-left: 17px;
  height: 57px;
}

.rbc-time-column .rbc-label {
  padding: 0 25px 0 25px;
}

.toolbar-header .right-btn {
  font-size: 12px;
  line-height: 15px;
}

.toolbar-header .right-btn span {
  cursor: pointer;
}
.toolbar-header .rbc-btn-group {
  display: flex;
  align-items: center;
}

.toolbar-sidebar {
  margin-top: -5px;
}

.search-wrapper .form-feild {
  background: url("../images/Search-black.svg") no-repeat 10px center;
  padding-left: 33px;
  font-family: "EuclidFlex-Medium";
  font-size: 12px;
  border: 1px solid #f2f2f2;
}

.search-wrapper .form-feild::placeholder {
  opacity: 1 !important;
}

input + .cross_icon {
  -webkit-appearance: none;
  background: url("../images/close_icon.png") no-repeat center;
  width: 17px;
  height: 17px;
  cursor: pointer;
  position: absolute;
  right: 5px;
  opacity: 0;
  top: 9px;
}
input.show_icon + .cross_icon {
  opacity: 1;
}
.toolbar-heading {
  background: url("../images/select-down-new.png") no-repeat right;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  font-family: Proxima Soft;
  padding: 13px 19px 13px 34px;
  background-position: 96% 50%;
  border-top: 1px solid #f2f2f2;
  border-bottom: 1px solid #f2f2f2;
}

.search-wrapper {
  width: 100%;
  position: relative;
}
.toolbar-subheader {
  display: flex;
  padding: 0 10px 10px 10px;
}

.toolbar-lists {
  font-family: Proxima Soft;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 13px;
  padding: 19px 35px 19px 35px;
  min-height: 254px;
  border-bottom: 1px solid #f2f2f2;
  max-height: 254px;
  overflow-y: scroll;
}

.toolbar-lists > div {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 15px;
}
.rectangle {
  background: #343642;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  box-shadow: 2px 2px 20px rgba(62, 132, 219, 0.08);
  border-radius: 3px;
  width: 11px;
  height: 11px;
  margin-right: 10px;
}

.toolbar-header .right-btn .active {
  text-decoration: underline;
}

.toolbar-maincontent {
  position: absolute;
  left: 0;
  background: #fff;
  font-size: 13px;
  width: 70%;
  min-height: 543px;
  top: 57px;
  max-height: 543px;
  overflow: auto;
  border-right: 1px solid #f2f2f2;
}

.simple-view .rbc-time-view,
.search-view .rbc-time-view {
  display: none;
}

.appointment-item .title {
  /* width: 19px;
  border-radius: 50%;
  height: 19px;
  align-items: center;
  display: flex;
  background: #f8f8fd;
  border: 1px solid #f2f2f2;
  justify-content: center;
  left: 0;
  position: absolute;
  margin-top: 10px; */
  margin-right: 21px;
}
.form-field-space.small {
  margin-bottom: 20px;
}

.updated-wrapper textarea {
  min-height: 101px;
  font-family: Proxima Soft;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 120%;
}
.d-flex {
  display: flex;
}
.updated-section select {
  background: url(../images/select-arrow.svg) no-repeat 95%;
}
.selectbox {
  background: url(/static/media/select-arrow.cce50bda.svg) no-repeat 97%;
  font-family: Proxima Soft;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #4f4f4f;
  line-height: 120%;
}

.message-wrap {
  margin-bottom: 36px;
}
.alc {
  align-items: center;
}

.updated-wrapper.half_layout {
  width: 54%;
}

.common-btn-updated.disabled {
  pointer-events: none;
  opacity: 0.5;
}
.mr-15 {
  margin-right: 15px;
}
.updated-appointment .top button {
  background: #f8f8fd;
  border: 0;
  border-bottom: 1px solid #e0e0e0;
  box-sizing: border-box;
  font-family: Euclid Flex;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  margin-right: 10px;
  margin-left: 13px;
}

.appointment-item .time {
  font-family: Proxima Soft;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
}

.appointment-item {
  padding: 8px 14px;
  background: #f8f8fd;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
  border-radius: 3px;
}
.search-appointment-list .appointment-item {
  margin-bottom: 13px;
}
.colour-appointment-list .appointment-item {
  margin-bottom: 6px;
}

.colour-appointment-list {
  padding: 14px 9px 14px 19px;
}
.rbc-header {
  border-bottom: 0 !important;
}
.toolbar-subheader .hidebtn {
  width: 47px;
  height: 56px;
  border-right: 1px solid #f2f2f2;
  margin-top: -10px;
  display: none;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  margin-right: 11px;
}
.toolbar-subheader.search-active .hidebtn {
  display: flex;
}

.toolbar-subheader.search-active span.rbc-btn-group {
  display: none;
}

.toolbar-subheader.search-active {
  padding-left: 0;
  padding-bottom: 0;
}

.toolbar-subheader.search-active .search-wrapper {
  width: calc(100% - 60px);
}

.search-list {
  font-family: Proxima Soft;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 120%;
  border: 1px solid #f2f2f2;
  background: #fff;
  box-shadow: 2px 2px 20px rgba(62, 132, 219, 0.08);
  border-radius: 5px;
  position: absolute;
  right: 0;
  left: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: 0;
  display: none;
}
@media (max-width: 1024px) {
  .calendar-view {
    width: 1024px;
  }
}
.search-list div {
  padding: 11px 12px;
}
.toolbar-lists > div.active {
  color: red;
}
.search-toolbar.toolbar-header .rbc-toolbar-label {
  flex: none;
}

.search-toolbar.toolbar-header {
  padding: 0 19px;
}
.search-list div:not(:last-child) {
  border-bottom: 1px solid #f2f2f2;
}

.toolbar-subheader.search-active .search-wrapper .form-feild {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.search-list.show {
  display: block;
}
.search-toolbar.toolbar-header .rbc-toolbar-label {
  flex: none;
}

.search-toolbar.toolbar-header {
  padding: 0 19px;
}

.appointment-item .top-title {
  font-family: Euclid Flex;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #38417c;
  padding: 6px 0;
  border-bottom: 1px solid #f2f2f2;
  margin-bottom: 12px;
}

.search-appointment-list {
  padding: 15px 19px 15px 19px;
}
.calendar-main ::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #c4c4c4;
}
.calendar-main ::-webkit-scrollbar-track {
  background: #e0e0e0;
}
/* Small Calendar*/

.react-calendar__navigation__label {
  order: 1;
}

button.react-calendar__navigation__arrow {
  order: 2;
  margin: 0 5px !important;
}

button.react-calendar__navigation__prev-button {
  background: url("../images/prev-calendar.svg") no-repeat;
}

button.react-calendar__navigation__next-button {
  background: url("../images/next-calendar.svg") no-repeat;
}

button.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
button.react-calendar__navigation__arrow {
  width: 18px;
  height: 18px;
  min-width: auto;
  font-size: 0;
  position: relative;
  top: -3px;
}

.react-calendar
  button:not(.react-calendar__navigation__arrow):not(.react-calendar__navigation__label) {
  font-family: Proxima Soft;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 120%;
  color: #828282;
  height: 31px;
  margin: 0 !important;
  border: 0;
  outline: 0;
}

.react-calendar__month-view__weekdays > div {
  font-family: Proxima Soft;
  font-style: normal;
  font-weight: normal;
  color: #bdbdbd;
  font-size: 12px;
  line-height: 120%;
  height: 21px;
  text-align: center;
}

.react-calendar__month-view__weekdays > div abbr {
  text-decoration: none;
}

.react-calendar {
  padding: 24px 21px;
}

/* .react-calendar__navigation__label {
  flex-grow: !important;
} */

.react-calendar__navigation {
  margin-bottom: 17px;
  padding: 0 5%;
}

button.react-calendar__navigation__label {
  text-align: left;
  margin: 0 !important;
}

button.react-calendar__navigation__next-button {
  margin-right: 0 !important;
}

.react-calendar
  button:not(.react-calendar__navigation__arrow):not(.react-calendar__navigation__label)
  abbr {
  border-radius: 50%;
  display: inline-block;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  padding: 8% 11%;
  min-width: 20px;
  min-height: 21px;
}
.react-calendar__tile--active abbr,
.react-calendar
  button:not(.react-calendar__navigation__arrow):not(.react-calendar__navigation__label):hover
  abbr {
  background: #3e4896 !important;
  color: #fff !important;
}

/*Edit popup*/
.appointment-popups h4 {
  font-family: Euclid Flex;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 127.69%;
  color: #343642;
}

.appointment-popups .edit {
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 4px 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-family: Proxima Soft;
  font-weight: 500;
}

.appointment-popups .set-options ul {
  display: flex;
  align-items: center;
}

.appointment-popups .set-options ul .closepopup {
  margin-left: 8px;
}

.appointment-popups p {
  line-height: 120%;
  color: #4f4f4f;
}
.appointment-popups .set-options li a img {
  vertical-align: baseline;
}

.appointment-popups h4 {
  font-family: Euclid Flex;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 127.69%;
  color: #343642;
}

.appointment-popups .edit span {
  margin-right: 5px;
}

.appointment-popups .set-options ul .closepopup {
  margin-left: 8px;
}

.appointment-popups p {
  line-height: 120%;
  color: #4f4f4f;
}

.appointment-popups .prints-type ul {
  display: flex;
  justify-content: flex-end;
}

.appointment-popups .prints-type ul li a {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  padding: 0;
  border-radius: 50%;
  width: 33px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.appointment-popups h4 {
  margin-bottom: 17px;
}

.event-type-text {
  border-radius: 5px;
  color: #fff;
  display: inline-block;
  padding: 10px;
  margin-bottom: 15px;
}

.mflex {
  display: flex;
  justify-content: space-between;
}

.appointment-popups .prints-type {
  margin: 0;
}

.appointment-popups p {
  padding: 0;
}
.simple_view_popup.appointment-popups {
  top: 30%;
  left: 10%;
}

.appointment-popups h4 {
  font-family: Euclid Flex;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 127.69%;
  color: #343642;
}
.spb {
  justify-content: space-between;
}

.alc {
  align-items: center;
}
.event-type-text img {
  width: 14px;
  margin-right: 8px;
}
.message-wrap {
  float: left;
  padding-right: 15px;
  border-right: 1px solid #dfe3e9;
  margin-right: 20px;
  color: #38417c;
  /* width: 50%; */
  /* padding: 11px; */
  line-height: 16px;
}

.br-tr-0 {
  border-top-right-radius: 0 !important;
}

.br-br-0 {
  border-bottom-right-radius: 0 !important;
}

.br-tl-0 {
  border-top-left-radius: 0 !important;
}

.br-bl-0 {
  border-bottom-left-radius: 0 !important;
}
.mr-11 {
  margin-right: 11px;
}
.updated-appointment .top button:last-child {
  border-left: 1px solid #e0e0e0;
}
.date-section-content {
  padding: 24px;
  text-align: center;
}

.date-section-content p {
  color: #828282;
  padding: 20px 0 0;
}
.date-section-content .react-datepicker-wrapper:first-child {
  margin-right: 29px;
}
.updated-appointment .bottom {
  padding: 14px 18px;
  border-top: 1px solid #e0e0e0;
}
.update-dateselector {
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 5px;
  height: 35px;
  text-align: center;
}
.updated-appointment {
  border: 1px solid #e0e0e0;
  margin-top: 20px;
  border-radius: 5px;
  overflow: hidden;
}
.common-btn.blue-btn.common-btn-updated.reload-btn {
  padding: 9px 36px 9px 10px;
  position: relative;
}
.common-btn-updated.blue-btn-light {
  background: #4f5895;
}
.lock-date-time {
  background: #f8f8fd;
  border-radius: 5px;
}
.appointment-summary-wrap .accor-title {
  font-family: Proxima Soft;
  background: #fff;
  border-color: #f2f2f2;
  margin-bottom: 21px;
}

.appointment-summary-wrap .accor-body {
  background: rgba(248, 248, 253, 0.1);
  padding: 21px 23px 20px 23px;
  pointer-events: none;
  border-color: #f2f2f2;
}
.appointment-summary-wrap > div:last-child .accor-title {
  margin-bottom: 0;
}
.reload-btn .img {
  position: absolute;
  right: 11px;
  top: 50%;
  transform: translateY(-61%);
  background: #9ca9e2;
  width: 16px;
  height: 16px;
  border-radius: 50%;
}

.reload-btn .img img {
  vertical-align: middle;
}
.lock-head {
  font-family: Euclid Flex;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #4f5895;
  border-bottom: 1px solid #e0e0e0;
  background-image: url("../images/lock_icon.png");
  background-repeat: no-repeat;
  padding: 17px 18px 17px 43px;
  background-position: 18px 46%;
}

.lock-content {
  padding: 12px 37px 12px 18px;
}

.lock-content .lock-row {
  display: flex;
  margin-bottom: 9px;
}

.lock-content .lock-row input.form-feild.w-100 {
  text-align: center;
  background: rgba(255, 255, 255, 0.5);
}
.common-btn-updated.yellow-btn {
  background: #fee398;
  color: #4f4f4f;
}
.notes-section .updated-heading {
  margin-bottom: 27px;
}

.updated-wrapper p {
  color: #4f4f4f;
}

.notes-section .left_label .field-full.w-100 {
  align-items: flex-start;
}

.updated-wrapper.notes-section textarea {
  min-height: 144px;
}

.notes-section .left_label {
  min-height: 220px;
}
.order-summary-list {
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 5px;
}

.order-summary-list .item:not(:last-child) {
  border-bottom: 1px solid #f2f2f2;
}

.order-summary-list .item {
  font-size: 12px;
  color: #4f4f4f;
  font-family: "ProximaSoft-Regular";
  font-style: normal;
  line-height: 120%;
  display: flex;
  align-items: center;
  padding: 5px 14px 5px 16px;
  min-height: 44px;
}

.order-summary-list .label-content {
  font-weight: 500;

  text-transform: capitalize;
  width: calc(100% - 94px);
}
.order-summary-list .label {
  width: 73px;
  margin-right: 21px;
}
.summary-box {
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  margin-bottom: 16px;
  border-radius: 5px;
}

.summary-list > div {
  min-height: 49px;
  display: flex;
  align-items: center;
  max-width: 390px;
}

.summary-list .label {
  min-width: 107px;
  margin-right: 9px;
}

.appointment-summary-wrap {
  font-family: Proxima Soft;
  margin-top: 0;
  padding: 7px 3px;
}

.summary-list {
  padding: 0 46px;
}
.summary-sidebar .label {
  width: 88px;
  margin-right: 18px;
}

.icon-button {
  min-width: 34px;
  height: 34px;
  border: 1px solid #e0e0e0;
  border-radius: 50%;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}
.summary-list:not(:last-child):not(.border-none) {
  border-bottom: 1px solid #f2f2f2;
}

.autoheight {
  min-height: auto !important;
}

.summary-list .autoheight {
  margin-bottom: 20px;
}
.add-new-appointments .white-box {
  box-shadow: none !important;
}
.close-btn {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.appointment-booked .common-subheading.sub-heading {
  position: relative;
  padding: 23px 20px 23px 20px;
}

.appointment-booked .inner-popup-content {
  padding: 20px;
}

/* Soonestappointment */
.day-head h4 {
  font-family: Euclid Flex;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  margin: 0;
}

.day-head {
  padding: 13px;
  text-align: center;
  border-bottom: 1px solid #e0e0e0;
}

.soonest-content .day:not(:last-child) {
  border-right: 1px solid #e0e0e0;
}

.soonest-content .button-wrapper button {
  font-family: Proxima Soft;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  background: #ffffff;
  line-height: 120%;
  border: 1px solid #d1d4e3;
  border-radius: 8px;
  display: block;
  width: 100%;
  padding: 11px 5px;
}

.soonest-content .day {
  flex: 1;
}

.soonest-content .button-wrapper {
  padding: 9px 18px 13px 18px;
  max-height: 214px;
  overflow: auto;
}

.soonest-content .button-wrapper button:not(:last-child) {
  margin-bottom: 10px;
}

.soonest-content ::-webkit-scrollbar-track {
  background: #e0e0e0;
}

.soonest-content ::-webkit-scrollbar {
  width: 4px;
}

.soonest-content ::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border-radius: 5px;
  box-shadow: none;
}
.soonest-content .button-wrapper button:hover,
.soonest-content .button-wrapper button:focus {
  border: 1px solid #4f5895;
}
input.disabled {
  pointer-events: none;
}
.updated-section ::placeholder {
  opacity: 1 !important;
}
/* Soonestappointment end */

/* Popup Form */

.updated-popup-form .sub-heading h3 {
  letter-spacing: 0.08em;
  text-transform: uppercase;
  font-weight: 500;
  font-family: Proxima Soft;
  color: #38417c;
  font-size: 14px;
  line-height: 14px;
}

.updated-popup-form .common-subheading.sub-heading {
  padding: 16px 17px 16px 17px;
}

.updated-popup-form {
  padding: 0 !important;
}

.mt-20 {
  margin-top: 20px !important;
}
.mt-25 {
  margin-top: 25px !important;
}
.updated-popup-form h4 {
  font-family: Euclid Flex;
  font-style: normal;
  font-weight: 500;

  color: #343642;
  font-size: 18px;
  line-height: 23px;
  margin-bottom: 5px;
}
.updated-popup-form.stage4-popup .inner-forgot-pass {
  padding: 29px 17px 25px 17px;
}
.updated-popup-form .inner-popup-content {
  padding: 29px 17px 25px 17px;
}
.updated-popup-form .snooze-datetime-main {
  padding: 0 0 0 0;
  margin-bottom: 20px;
}
.updated-popup-form .office-time input.form-control {
  font-size: 14px;
  line-height: 120%;
}

.updated-popup-form .office-time.o-date {
  width: 21%;
  min-width: auto;
}

.updated-popup-form label.select-dt,
.updated-popup-form .print-checbk > label {
  width: 103px;
  margin-right: 21px;
}

.updated-popup-form [type="checkbox"]:checked + label::before,
.updated-popup-form [type="checkbox"]:not(:checked) + label::before,
.updated-popup-form
  .print-checbk
  .checkbox-custom:checked
  + .checkbox-custom-label::after,
.updated-popup-form [type="checkbox"]:checked + label::after,
.updated-popup-form [type="checkbox"]:not(:checked) + label::after {
  border-radius: 2px;
}

.updated-popup-form [type="checkbox"]:checked + label::before,
.updated-popup-form [type="checkbox"]:not(:checked) + label::before {
  border: 1px solid #bdbdbd;
  top: 5px;
  width: 8px;
  height: 8px;
}

.updated-popup-form
  .print-checbk
  .checkbox-custom:checked
  + .checkbox-custom-label::after {
  top: 5px;
  width: 9px;
  height: 9px;
}

.updated-popup-form .print-checbk .checkbox-custom-label {
  padding-left: 23px;
}
.updated-popup-form .print-checbk .form-group {
  margin-right: 76px;
}

.popup-btn {
  font-family: Euclid Flex;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  border: 1px solid transparent;
  line-height: 16px;
  border: 0;
  padding: 8px 13px;
  background: #3e4896;
  border-radius: 5px;
  color: #fff;
  display: inline-block;
}

.popup-btn.whitebtn {
  border: 1px solid #e0e0e0;
  background-color: #fff;
  color: #4f4f4f;
}

.updated-popup-form .print-checbk {
  padding-bottom: 0;
  display: flex;
}

.updated-popup-form .print-checbk .form-group:not(:last-child) {
  margin-bottom: 11px;
}
.appointment-setting-popup.border {
  width: 33px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border-radius: 50%;
}

.hours-slot {
  padding-top: 15px;
  border-bottom: 1px solid #c4c4c4;
}

.settings-line {
  width: 100%;
  height: 5px;
  background-color: #e0e0e0;
  margin-bottom: 5px;
}

.msgs-popups .form-main {
  max-width: 1012px;
  width: 1012px;
  text-align: left;
}
.msgs-popups .col-3 {
  width: 32.4%;
  border-right: 1px solid #e0e0e0;
}
.msgs-popups .right-sec {
  width: 67.6%;
  padding: 0;
}
.backto-platforms a {
  text-align: center;
  background: #4f5895;
  border-radius: 5px;
  min-width: 162px;
  padding: 9px;
  display: inline-block;
  vertical-align: middle;
}
.backto-platforms a span {
  background: url(../images/backto-platform.svg) no-repeat right center;
  padding: 5px 35px 5px 5px;
  font-family: "EuclidFlex-Medium";
  font-size: 13px;
  color: #fff;
}
.backto-platforms a:hover,
.backto-platforms a:focus {
  background: #3e4896;
}
.msgshead {
  display: flex;
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  -moz-justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -moz-align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  margin-bottom: 27px;
}
.msgshead h2 {
  font-size: 30px;
  line-height: 38px;
  color: #fff;
  font-family: "EuclidFlex-Medium";
  margin-bottom: 0;
}
.msgs-popups .custom-scrl-content {
  height: 440px;
}

.internal-messaging {
  height: 440px !important;
}
.msgs-popups .chat-text .short-name {
  position: absolute;
  left: -44px;
  bottom: auto;
  top: 50%;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.msgs-popups .inbox-archive {
  padding: 25px 20px 25px;
}
.msgs-popups .new-msg-info h4 span {
  font-size: 12px;
  font-family: "ProximaSoft-Regular";
  margin-right: -16px;
}
.msgs-popups .inbox-info-right .chatbox {
  max-width: 74%;
}
.msgs-popups .new-msg-info h4 {
  color: #bdbdbd;
}
.search-form {
  margin: 0 20px 17px 20px;
}
.msgs-popups .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #c4c4c4 !important;
  width: 7px !important;
  height: 94px;
}
.msgs-popups .mCSB_scrollTools .mCSB_draggerRail {
  width: 7px !important;
}
.fancybox-bg {
  background: #38417c;
  opacity: 0.97 !important;
}
.msgs-popups .userimgs {
  float: left;
  padding-right: 10px;
  position: relative;
}
.withgreen-arw {
  position: absolute;
  background: url(../images/correct.svg) no-repeat;
  right: 8px;
  bottom: 5px;
  width: 9px;
  height: 9px;
}
.msg-datetime {
  color: rgba(79, 79, 79, 0.5);
  font-size: 14px;
  font-family: "EuclidFlex-Medium";
  text-align: center;
  padding: 18px 10px 0px;
}
.msgs-popups .mCSB_scrollTools {
  right: -4px !important;
}
.msgs-popups .d-flex {
  -moz-flex-direction: row !important;
  -webkit-flex-direction: row !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}
/* .msgs-popup-main.full-height {
    height: calc(100vh - 12px);
} */
.msg-infos {
  width: calc(100% - 55px);
  display: table;
}
.chat-days-info-main .custom-scrl-content {
  padding-bottom: 140px;
}
.leftbar .inbox-menu li:last-child a {
  background: #4f5895;
}
.msgs-popups .chatbox p {
  padding-bottom: 15px;
}
.msgs-popups .select-options li {
  font-weight: 600;
}
.msgs-popups .select-options li[rel="active"] {
  background: url(../images/correct-grey.svg) no-repeat 92% center;
}

.ReactModal__Overlay {
  z-index: 999999999999999999999999999999999999999999;
}
.updated-popup-form .inner-popup-content {
  padding: 29px 17px 25px 17px;
}

.new-update-popup .form-head {
  margin-bottom: 15px;
}

.new-update-popup p.description {
  padding-bottom: 0;
  margin-bottom: 9px;
}

.new-update-popup .p-info label {
  font-size: 14px;
  color: #4f4f4f;
  padding-left: 25px;
}

.new-update-popup .p-info label span {
  position: relative;
  top: -1px;
}

.new-update-popup .p-info-inner {
  width: 43%;
}

@media (max-width: 991px) {
  .updated-section .col-7.app-setting-right,
  .updated-section .col-3.app-setting-left {
    width: 100%;
  }
  .updated-section {
    flex-wrap: wrap;
  }
  .updated-section .col-3.app-setting-left {
    padding-left: 0;
    margin-top: 20px;
  }
}

.app-setting-head h5 {
  font-family: "Proxima Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  /* or 17px */

  /* Gray 2 */

  color: #4f4f4f;
}

.app-setting-head ul li {
  display: inline-block;
}

.app-setting-head ul li img {
  margin-right: 10px;
}
.app-setting-head .hours li {
  display: block;
}

.app-setting-head .hours {
  margin-bottom: 30px;
}

.app-setting-head .email {
  margin-bottom: 30px;
}

.app-setting-head .hours li p {
  display: inline-block;
  margin-left: 15px;
}

.profile-settings input {
  width: 50%;
  font-size: 14px;
}

.profile-settings h5 {
  font-weight: bold;
  text-decoration: underline;
}

.profile-settings select {
  width: 50%;
  font-size: 14px;
}

.read-receipt {
  background: rgba(156, 169, 226, 0.5);
  border-radius: 2px;
  font-family: "EuclidFlex-Medium";
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 127.19%;
  /* or 13px */

  text-align: center;

  /* Charcoal */

  color: #343642;
  margin-left: 8px;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.read-receipt svg {
  margin-right: 3px;
  margin-bottom: 2px;
}

.screenshot-download p {
  padding: 12px;
}
