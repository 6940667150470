.whiteBox {
  width: 100%;
  height: 300px;
  padding: 19px;
  padding-top: 25px;
  background: white;
  border-radius: 5px;
  margin-top: -120px;

  @media (max-width: 1700px) {
    margin-top: -80px;
  }
  @media (max-width: 1440px) {
    margin-top: -40px;
  }

  @media (max-width: 1200px) {
    margin-top: 50px;
    height: 100%;
  }

  ul li {
    display: inline;
    p {
      font-family: "ProximaSoft-Regular";
      font-style: normal;
      font-weight: normal;
      font-size: 36px;
      line-height: 34px;
      padding-bottom: 5px;
      /* Med Blue */

      color: #38417c;
      float: left;
    }
    img {
      float: left;
      margin-left: 10px;
      width: 16px;
      height: 16px;
      @media (max-width: 1440px) {
        display: none;
      }
    }
  }
}

.infoRight {
  font-family: "ProximaSoft-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 10px;
  text-align: right;
  letter-spacing: -0.2px;

  /* Highlight Blue */

  color: #9ca9e2;
}

.upcomingTitle {
  font-family: "EuclidFlex-Regular";
  font-style: bold;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;

  /* Charcoal */

  color: #343642;

  @media (max-width: 1520px) {
    // margin-top: 20px;
    line-height: 15px;
    font-size: 13px;
  }

  @media (max-width: 1190px) {
    // margin-top: 20px;
    font-size: 16px;
    line-height: 20px;
  }
}

.lastWeekRecapContainer {
  border: 1px solid rgba(224, 224, 224, 0.5);
  box-sizing: border-box;
  border-radius: 5px;

  p {
    font-family: "EuclidFlex-Regular";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 13px;
    text-align: center;
    padding-bottom: 15px;

    /* GRAY 2 */

    color: #4f4f4f;
    @media (max-width: 1520px) {
      // margin-top: 20px;
      line-height: 15px;
      font-size: 10px;
    }

    @media (max-width: 1190px) {
      // margin-top: 20px;
      font-size: 13px;
      line-height: 13px;
    }
  }

  hr {
    margin-top: 3px;
    margin-bottom: 15px;
  }
}

.lastWeekTitle {
  font-family: "ProximaSoft-Regular";
  font-style: normal;
  font-weight: 500;
  padding-top: 20px;
  font-size: 16px;
  line-height: 12px;

  text-align: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  margin-bottom: 35px;
  /* Gray 5 */

  color: #e0e0e0;
  @media (max-width: 1520px) {
    // margin-top: 20px;
    line-height: 15px;
    font-size: 12px;
    margin-bottom: 10px;
  }
  @media (max-width: 1190px) {
    // margin-top: 20px;
    font-size: 16px;
    line-height: 12px;
    margin-bottom: 35px;
  }
}
