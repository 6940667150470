.app-settings-main > h4 {
  font-family: "EuclidFlex-Medium";
  padding: 23px 10px 23px 33px;
  margin: 0;
  font-weight: 500;
  font-size: 18px;
  color: #343642;
}
.app-settings-main li a {
  font-size: 14px;
  font-family: "ProximaSoft-Regular";
  color: #4f4f4f;
  padding: 14px 14px 14px 33px;
  display: inline-block;
  vertical-align: middle;
  border-top: 1px solid #f2f2f2;
  width: 100%;
}
.app-settings-main li a:hover,
.app-settings-main li a:focus,
.app-settings-main li.active a {
  background: rgba(242, 242, 242, 0.5);
}
.main-appointment-page .col-7 {
  width: 72.2%;
  padding-left: 20px;
}
.main-appointment-page .app-setting-left .white-box,
.main-appointment-page .app-setting-right .white-box {
  height: 100%;
  min-height: 547px;
}
.app-setting-right .white-box {
  padding: 41px 35px;
}
.app-setting-head {
  display: inline-block;
  width: 100%;
  margin-bottom: 20px;
  vertical-align: middle;
}
.app-setting-head h4 {
  float: left;
  width: calc(100% - 107px);
  padding-right: 20px;
  font-family: "EuclidFlex-Medium";
  font-size: 18px;
  font-weight: 500;
  color: #343642;
}
.app-setting-head .addnew {
  float: right;
  margin-top: -4px;
}
.automatic-rpl p {
  line-height: 17px;
}
.automatic-rpl table tr {
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  height: 46px;
  border-bottom: 10px solid #fff;
}
.automatic-rpl table .first-td {
  padding-left: 20px;
}
.edit-app a {
  background: url(../images/version6-images/edit-app.svg) no-repeat;
  width: 14px;
  height: 14px;
  display: block;
}
.close-app a {
  background: url(../images/version6-images/close-app.svg) no-repeat center
    center;
  width: 30px;
  height: 30px;
  display: block;
  border-left: 1px solid rgba(224, 224, 224, 0.5);
  padding: 0 25px;
}
.close-app {
  width: 45px;
}
.close-app a:hover,
.close-app a:focus,
.edit-app a:hover,
.edit-app a:focus {
  opacity: 0.5;
}
.d-none {
  display: none;
}
/* appointment-settings */

.automatic-rpl-box {
  width: 100%;
  display: table;
}
.automatic-rpl-row {
  display: flex;
  font-family: "ProximaSoft-Regular";
  font-weight: 400;
  width: 100%;
  align-items: center;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 8px 0px 8px 52px;
  margin-bottom: 10px;
}
.automatic-rpl-title {
  width: 33%;
  text-transform: capitalize;
  padding-right: 15px;
}
.automatic-rpl-date {
  width: 61%;
  padding-right: 15px;
}
.automatic-rpl-edit {
  float: left;
  margin-right: 16px;
}
.edit-app {
  display: table;
}
.dashboard-top {
  margin-bottom: 28px;
  margin-top: 4px;
}
.leftbar {
  /* min-height: 100vh; */
  bottom: 0;
}
.go-back {
  margin-bottom: -2px;
  margin-right: 14px;
}

/*End appointment-settings */

/* new-message */

.message-titlebox {
  display: flex;
  align-items: center;
}
/* .feild-label {text-transform: capitalize;color: #4F4F4F;min-width: 103px;padding: 10px 0;display: inline-block;} */
.feild-label {
  text-transform: capitalize;
  color: #4f4f4f;
  min-width: 103px;
  padding: 0;
  display: inline-flex;
  align-items: center;
  height: 37px;
}
.message-title {
  margin-right: 21px;
  float: left;
}
.form-feild {
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 9px 10px;
  display: block;
  /* text-transform: capitalize; */
}
span.created-date {
  color: #bdbdbd;
  font-family: "ProximaSoft-Regular";
  font-size: 12px;
}
.w-100 {
  width: 100% !important;
}
.field-space {
  margin-top: 29px;
  display: inline-block;
  width: 100%;
}
.half-field {
  width: 50%;
  float: left;
}
.message-titlebox input.form-feild {
  width: calc(100% - 124px);
}

/* Switch */

.switch-custom {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
  outline: 0 !important;
}
.switch-custom input {
  opacity: 0;
  width: 0;
  height: 0;
}
.switch-custom .radio-span {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f2f2f2;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.switch-custom .radio-span:before {
  position: absolute;
  content: "";
  height: 11px;
  width: 11px;
  left: 4px;
  bottom: 3px;
  background-color: #4f4f4f;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.switch-custom input:checked + .radio-span:before {
  background: #3e4896;
}
.switch-custom input:focus + .radio-span {
  box-shadow: 0 0 1px #2196f3;
}
.switch-custom input:checked + .radio-span:before {
  -webkit-transform: translateX(10px);
  -ms-transform: translateX(10px);
  transform: translateX(10px);
}
.switch-custom .radio-span.round {
  border-radius: 34px;
}
.switch-custom .radio-span.round:before {
  border-radius: 50%;
}
.time-period {
  display: table;
  width: 100%; /* margin-top: 27px; */
  border: 1px solid #e0e0e0;
  padding: 13px 19px;
  border-radius: 5px;
}
.time-period label.switch {
  float: right;
}
.time-period label.switch-custom {
  float: right;
}
.half-field .form-feild,
.half-field-second .form-feild {
  width: 412px;
  max-width: 100%;
  font-family: "ProximaSoft-Regular";
}

.form-feild::-webkit-input-placeholder {
  color: #4f4f4f !important;
  opacity: 0.5 !important;
}
.form-feild:-ms-input-placeholder {
  color: #4f4f4f !important;
  opacity: 0.5 !important;
}
.form-feild::placeholder {
  color: #4f4f4f !important;
  opacity: 0.5 !important;
}

/*End Switch */

.tag-box {
  width: 100%;
  margin-top: 5px;
}
.tag-box-template {
  padding-top: 30px;
}
.tag-box-template label.feild-label img {
  margin-left: 10px;
  margin-bottom: -3px;
}
.tag-box-item {
  display: table;
  background-color: rgb(156 169 226 / 30%);
  text-transform: capitalize;
  color: #4f4f4f;
  padding: 7px 7px 3px 7px;
  font-size: 13px;
  margin-bottom: 9px;
  border-radius: 2px;
}
.half-field-second {
  padding-left: 30px;
  width: 50%;
  display: inline-block;
}
.btn {
  outline: 0 !important;
  font-size: 13px;
  font-family: "EuclidFlex-Regular";
  border-radius: 5px;
  font-weight: 400;
  box-shadow: 0 0 !important;
}
.btn-primary {
  background: #3e4896;
  border: 1px solid #3e4896;
  color: #fff;
}
.text-capitalize {
  text-transform: capitalize;
}
.btn-sm {
  padding: 11px 10px 9px 10px;
  line-height: 1;
}
.btn-white {
  color: #4f4f4f;
  background: transparent;
  border: 1px solid #e0e0e0;
}
.buttonbox {
  margin-top: 40px;
}
.buttonbox .btn {
  margin-right: 13px;
}
.buttonbox .btn:last-child {
  margin-right: 0;
}
.btn-primary:hover {
  background: transparent;
  border-color: #3e4896;
  color: #3e4896;
}
.btn-white:hover {
  color: #fff;
  background: #4f4f4f;
  border-color: #4f4f4f;
}
.tag-box-template .feild-label {
  font-family: "EuclidFlex-Medium";
}
.resize-icon {
  background-repeat: no-repeat;
  background-position: 98% 98%;
}
.textarea-icon {
  position: relative;
  max-width: 100%;
  display: inline-block;
}
.textarea-icon::after {
  background-image: url("../images/version6-images/textarea.svg");
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  right: 2px;
  bottom: 2px;
}
.main-appointment-page .app-setting-left .white-box,
.main-appointment-page .app-setting-right .white-box {
  height: inherit;
  margin-bottom: 50px;
}

.time-box-active {
  display: block;
}
.time-picker,
.date-picker {
  position: relative;
}
.time-box .time-picker,
.time-box .date-picker {
  width: 107px;
  float: left;
}
.time-box label {
  float: left;
  padding-right: 13px;
  padding-top: 12px;
}
.time-box .date-picker {
  margin-right: 13px;
}
.time-box {
  padding-top: 26px;
}
.date-picker .form-feild,
.time-picker .form-feild {
  background-color: rgb(242 242 242 / 50%);
  border-color: transparent !important;
  padding-bottom: 10px;
}
.date-picker input[type="date"]::-webkit-calendar-picker-indicator,
.time-picker input[type="time"]::-webkit-calendar-picker-indicator {
  background-image: none;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9999 !important;
}
.date-picker .bootstrap-datetimepicker-widget.dropdown-menu.top,
.time-picker .bootstrap-datetimepicker-widget.dropdown-menu.top {
  left: 0 !important;
  right: inherit !important;
}
.buttonbox .btn {
  float: left;
}
.submit-message {
  display: inline-block;
  font-weight: 400;
  padding: 0;
  max-width: 243px;
  width: 100%;
  margin-left: 18px;
  margin-top: -2px;
}
.unsave-model .sub-heading {
  padding: 23px 20px;
}
.unsave-contant {
  padding: 20px;
}
.model-footerbuttonbox {
  padding: 4px 24px 24px 24px;
  text-align: right;
}
.model-footerbuttonbox .btn {
  margin-right: 12px;
}
.unsave-contant h3 {
  font-size: 18px;
  margin-bottom: 5px;
  color: #343642;
  font-family: "EuclidFlex-Medium";
}
.forgot-pass-form .sub-heading h3 {
  font-family: "ProximaSoft";
  font-weight: 600;
}
.unsave-contant p {
  padding-bottom: 17px;
}
.resize-x {
  resize: vertical;
}
/* general-information */
.general-information-ul {
  margin-top: 0;
}
.general-information-ul li {
  font-size: 14px;
  padding: 8px 31px;
  margin-bottom: 5px;
  position: relative;
}
.general-information-ul li:after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  left: 0;
  background: #6dcdb8;
  top: 9px;
  border-radius: 50%;
}
.info-02:after {
  background: #308572 !important;
}
.info-03:after {
  background: #6475bd !important;
}
.info-04:after {
  background: #c66f6f !important;
}
.head-general-information h4 {
  font-family: "EuclidFlex-Medium";
  margin-bottom: 12px;
}
.general-information-whitebox {
  min-height: 562px !important;
}
.field-space label.feild-label {
  width: 100%;
}
.timeslot-box {
  width: 100%;
}
.timeslot-box-row {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
}
.timeslot-starttime {
  margin-right: 21px;
  width: 103px;
  text-transform: capitalize;
}
label.timeslot-endtime {
  width: 73px;
  text-align: center;
  margin-right: 5px;
  margin-left: 5px;
}
.timeslot-box-time-piker {
  width: 87px;
}
.timeslot-box-time-piker .form-control {
  width: 100%;
  font-size: 14px;
  padding: 9px 10px;
  background: transparent !important;
  border: 1px solid #e0e0e0 !important;
}
.form-fieldbox input[type="text"] {
  color: #4f4f4f !important;
}
.section-head p {
  padding-bottom: 0;
}
.timeslot-box-time-piker
  .date-picker
  .bootstrap-datetimepicker-widget.dropdown-menu,
.timeslot-box-time-piker
  .time-picker
  .bootstrap-datetimepicker-widget.dropdown-menu {
  left: -60px !important;
  right: inherit !important;
}
.covid-timeslot {
  display: inline-block;
  padding-top: 23px;
}
.secondart-title h3 {
  font-size: 18px;
  color: #343642;
  font-family: "EuclidFlex-Medium";
  margin-bottom: 21px;
}
.timeslot-row .feild-label {
  min-width: 78px;
  text-transform: none;
  float: left;
  width: 78px;
  margin-right: 44px;
  padding: 0;
}
.timeslot-row {
  display: inline-block;
  margin-bottom: 20px;
}
.ui-selectmenu-button {
  background: transparent;
  padding: 9px 8px;
}
.timeslot-row .ui-selectmenu-button {
  width: 289px !important;
  margin: 0;
  background: transparent;
  padding: 9px 8px !important;
}
.ui-icon-triangle-1-s {
  background-position: -65px 0px !important;
}
.ui-selectmenu-button {
  border-radius: 5px !important;
  border-color: #e0e0e0 !important;
}
.ui-selectmenu-button.ui-button:focus {
  background: transparent;
  outline: 0;
  border-color: #e0e0e0;
}
.ui-state-active,
.ui-menu .ui-menu-item:hover .ui-menu-item-wrapper {
  background: #3e4896 !important;
  border-color: #3e4896 !important;
}
.timeslot-buttonbox {
  margin-top: 46px;
}
.btn-primary[disabled="true"] {
  opacity: 0.5;
}
.secondart-title p {
  padding-bottom: 23px;
}
.ui-selectmenu-button.ui-button:hover,
.ui-selectmenu-button.ui-button:active {
  background: transparent;
  border-color: #e0e0e0;
  color: #4f4f4f;
}
.ui-selectmenu-text {
  color: #4f4f4f !important;
  font-family: "ProximaSoft-Regular" !important;
  text-transform: capitalize;
}
.timeslot-buttonbox .btn {
  width: 110px;
  text-align: center;
}
.ui-selectmenu-button-open .ui-icon {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
}
.half-field .form-feild {
  width: 100%;
  color: #4f4f4f;
  font-size: 14px; /* font-family: 'ProximaSoft-Regular'; */
}
.half-field .textarea-icon {
  width: 100%;
}
.unsave-model-popup {
  padding: 0;
}
/*End general-information */

/* new-appointment */

.title-goback h2,
.app-settings-main > h4,
.app-settings-main li a,
.app-setting-head h4 {
  text-transform: capitalize;
}
.form-select + .ui-selectmenu-button {
  background: transparent;
  font-family: "ProximaSoft-Regular";
  width: 100% !important;
  height: 35px;
  margin: 0;
  padding: 7px 15px;
}
.feild-row {
  display: table;
  margin-bottom: 15px;
}
.field-left {
  width: 103px;
  float: left;
  margin-right: 21px;
}
.form-fieldbox {
  width: calc(100% - 144px);
  display: inline-block;
}
.form-fieldbox .time-picker {
  width: 100%;
}
.sidebar-with-dropdown .white-box {
  margin-bottom: 20px !important;
  min-height: 432px !important;
}
.sidebar-dropdownbox {
  padding: 13px;
  background: #fff;
  border-radius: 5px;
}
.menu-deopdownsub-ul {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  overflow: hidden;
  background: #fff;
  margin-bottom: 13px;
}
.menu-deopdown-li > ul {
  width: 100%;
  right: 0;
  left: 0;
  top: -126px;
  position: absolute;
  display: none;
}
.btn-lg {
  display: table;
  padding: 14px 20px 10px;
  text-align: center;
}
.sidebar-dropdownbox .btn-lg {
  width: 100%;
  margin: 0;
}
.sidebar-dropdownbox .dropdown-menu {
  margin-top: 13px;
}
.menu-deopdown-li:hover > ul {
  display: block !important;
  position: absolute;
  width: 100%;
}
.menu-deopdown-li:hover .btn-white {
  color: #fff;
  background: #4f4f4f;
  border-color: #4f4f4f;
}
.menu-deopdownsub-ul li {
  display: table;
  width: 100%;
  margin: 0 !important;
}
.menu-deopdownsub-ul li a {
  font-size: 12px;
  text-transform: capitalize;
  display: block;
  padding: 11px 15px;
  border-bottom: 1px solid #f2f2f2;
}
li.menu-deopdown-li {
  position: relative;
}
.menu-deopdownsub-ul li:last-child a {
  border: 0;
}
.menu-deopdownsub-ul li:hover a {
  background: #343642;
  color: #fff;
}
.arrow-hover {
  display: none;
}
.btn:hover .arrow-normal,
.menu-deopdown-li:hover .btn-white .arrow-normal {
  display: none;
}
.btn:hover .arrow-hover,
.menu-deopdown-li:hover .btn-white .arrow-hover {
  display: inline-block;
}
.arrow-image {
  margin-left: 5px;
}
.sidebar-with-dropdown-innerbox {
  min-height: 574px !important;
}
.print-model .sub-heading {
  padding: 13px 17px 10px 17px;
}
.print-model .sub-heading .fancybox-close-small {
  top: 13px;
  right: 20px;
  width: 13px;
  height: 12px;
}
.print-model .unsave-contant {
  padding: 29px 19px 35px 19px;
}
.print-type {
  margin-top: 22px;
  display: table;
}
label.print-type-label {
  float: left;
  font-family: "ProximaSoft-Regular";
  display: inline-block;
}
.francy-radiobuttonbox {
  margin-left: 94px;
  display: inline-block;
}
.radiobuttonbox-label input,
.radiobuttonbox-label input + span:after,
.checkbox-them input[type="checkbox"],
.checkbox-them input[type="checkbox"] + span:after {
  left: 0 !important;
  appearance: radio;
  -webkit-appearance: radio;
  position: absolute;
  top: 4px;
  width: 9px;
  content: "";
  width: 7px;
  height: 7px;
}
.radiobuttonbox-label input,
.checkbox-them input[type="checkbox"] {
  opacity: 0;
}
label.radiobuttonbox-label {
  font-family: "ProximaSoft-Regular";
  text-transform: capitalize;
}
.francy-radiobuttonbox label.radiobuttonbox-label {
  margin-right: 69px;
}
label.radiobuttonbox-label,
.checkbox-them {
  padding-left: 22px;
  position: relative;
  cursor: pointer;
}
.radiobuttonbox-label input + span:after,
.checkbox-them input[type="checkbox"] + span:after {
  border: 1px solid #bdbdbd;
}
.radiobuttonbox-label input:checked + span:after,
.checkbox-them input[type="checkbox"]:checked + span:after {
  background: #4f4f4f;
}
.print-model .model-footerbuttonbox {
  padding-top: 0;
}
.print-model .sub-heading h3 {
  font-family: "ProximaSoft-Regular";
}
/*End new-appointment */

.cancel-event {
  padding: 29px 19px 36px;
}
.model-h3 {
  font-size: 19px;
  color: #343642;
}
.cancel-event .print-type {
  margin: 0;
}
.cancel-event .print-type p {
  padding-bottom: 0;
}
.cancel-event-radiobox {
  padding: 15px 0;
  margin-bottom: 22px;
  display: inline-block;
}
.cancel-event-radiobox label.radiobuttonbox-label:nth-child(2) {
  float: right;
}
.cancel-event .print-type p,
.cancel-event-radiobox {
  padding-right: 46px;
}
.cancel-event-radiobox label.radiobuttonbox-label {
  text-transform: inherit;
}
.ui-selectmenu-open {
  z-index: 99999 !important;
}

/* patient-details */

.form-radiobuttonbox {
  width: 100%;
  padding-top: 10px;
}
.form-radiobuttonbox label.radiobuttonbox-label:last-child {
  float: right;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.form-field-space {
  margin-bottom: 38px;
}
.inner-header h5 {
  font-size: 14px;
  color: #343642;
  font-family: "EuclidFlex-Medium";
  margin-bottom: 16px;
}
.fieldvalue-full {
  max-width: 289px;
}
.field-full .feild-label {
  text-transform: inherit;
}
.field-full {
  margin-bottom: 18px;
}
.fieldvalue-full label.radiobuttonbox-label:last-child {
  float: right;
  margin-right: 96px;
}
.field-full .ui-selectmenu-button.ui-button {
  margin-bottom: 12px;
}
.checkbox-them input[type="checkbox"] {
  appearance: checkbox;
  -webkit-appearance: checkbox;
}
.checkboxitems label.checkbox-them {
  margin-top: 6px;
  margin-bottom: 6px;
  display: inline-block;
  width: 100%;
}
.form-field-space .checkboxitems {
  width: 660px;
  max-width: 100%;
}
.details-box-header {
  margin-top: 71px;
}
.text-transform-inherit,
.text-transform-inherit + .ui-selectmenu-button .ui-selectmenu-text {
  text-transform: inherit;
}

/*End patient-details */
