@media screen and (min-width: 1431px) {
  #edit-office .forgot-pass-form {
    min-width: 708px;
  }
}
@media screen and (max-width: 1430px) {
  .patients-table table tr td {
    padding: 26px 15px;
  }
  .patients-table table th {
    padding: 0 15px;
  }
  /* .chat-text:nth-child(2n + 1) {
    margin-left: 10px;
    margin-right: 30px;
  }
  .chat-text:nth-child(2n) {
    margin-right: 10px;
    margin-left: 35px;
  } */

  .right-side {
    padding-right: 50px;
  }
}
@media screen and (max-width: 1330px) {
  .patients-table {
    overflow: scroll;
  }
  .patients-table.appointment-table {
    overflow: auto;
  }
  .patients-table table {
    width: 1400px;
  }
  .patients-table.appointment-table table {
    width: 100%;
  }
  .waitingroom-full-img {
    background-size: contain;
  }
  .waiting-table table td,
  .waiting-table table td a {
    padding: 0 7px;
  }
  .register-form {
    padding: 30px 0 30px 0;
  }
  .register-form .form-head h2 {
    margin: 0 0 25px 0;
  }
  .passwords-main {
    padding-top: 25px;
  }
  .common-form form p {
    padding-bottom: 20px;
  }
}
@media screen and (max-width: 1250px) {
  .left-sec {
    width: 100%;
  }
  .right-sec {
    margin: 25px 0 0 0;
    width: 100%;
    padding-left: 0;
  }
  .checkbox-custom + .checkbox-custom-label::before {
    margin-right: 10px;
  }
}
/*** ipad landscape css ***/
@media screen and (max-width: 1199px) {
  .dash-content-wrapper {
    max-width: 100%;
  }
  .patients-table table th {
    padding: 0 10px;
  }
  .patients-table table tr td {
    padding: 26px 10px;
    font-size: 15px;
  }

  .status-picker {
    width: 160px;
  }

  .status-picker .react-select-container {
    padding-left: 0px;
  }

  .whiteBox {
    margin-top: 0 !important;
  }
}
/*** ipad portrait css ***/
@media screen and (max-width: 991px) {
  .select-office {
    padding: 25px 15px;
  }
  .fields-row.half {
    width: 100%;
  }
  .btns-group li {
    width: 100%;
    border: 0;
    border-top: 1px solid #bdbdbd;
  }
  .create-office-form .office-name .common-input {
    width: calc(100% - 115px);
  }
  .patients-table {
    overflow: scroll;
  }
  .patients-table table {
    width: 950px;
  }
  .search-form-main {
    margin: 10px 0 0 0;
    width: 202px;
  }
  h2 {
    font-size: 28px;
  }
  .waiting-table.white-box table {
    width: 650px;
  }
  .waiting-table table th {
    padding: 15px 10px 16px 10px;
  }
  .waiting-table .mCSB_container {
    overflow: scroll !important;
  }
  .tabbing-main {
    margin-right: 10px;
  }
  .export-delete {
    clear: both;
  }
  #edit-office .mCSB_container {
    overflow: auto;
  }
  .dashboard-title {
    width: 300px;
  }
}
@media screen and (max-width: 767px) {
  body {
    width: 768px;
  }
  .big-popup {
    width: 768px;
  }
}

/*** stage7 css start ***/
@media screen and (min-width: 1431px) {
}
@media screen and (min-width: 1800px) {
}
@media screen and (max-width: 1600px) {
}
@media screen and (max-width: 1500px) {
}
@media screen and (max-width: 1435px) {
}
@media screen and (max-width: 1430px) {
}
@media screen and (max-width: 1366px) {
  .msgs-popups .custom-scrl-content {
    height: 300px;
  }
}
@media screen and (max-width: 1300px) {
  .msgs-screens > .d-flex {
    -moz-flex-direction: row !important;
    -webkit-flex-direction: row !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
}
@media screen and (max-width: 1250px) {
  .msgs-screens > .d-flex {
    -moz-flex-direction: column !important;
    -webkit-flex-direction: column !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
}
/*** ipad landscape css ***/
@media screen and (max-width: 1199px) {
}
/*** ipad portrait css ***/
@media screen and (max-width: 991px) {
  .msgs-popups .form-main {
    max-width: 750px;
    width: 750px;
  }
  .msgshead h2 {
    font-size: 25px;
    line-height: 33px;
  }
  .msgs-popups .custom-scrl-content {
    height: 380px;
  }
}
@media screen and (max-width: 767px) {
}
