.whiteBox {
  // height: 430px;
  background: white;
  width: 100%;
  padding: 10px 20px;
  border-radius: 5px;
  position: relative;
  margin-bottom: 100px;
  padding-bottom: 40px;

  @media (max-width: 1200px) {
    margin-top: 20px;
    height: 100%;
  }

  .mainTitle {
    font-family: "EuclidFlex-Regular";
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    display: flex;
    align-items: center;

    /* GRAY 2 */

    color: #4f4f4f;
  }

  .secondaryTitle {
    font-family: "ProximaSoft-Regular";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 12px;
    /* identical to box height */

    letter-spacing: -0.2px;

    /* Gray 4 */
    margin-bottom: 60px;
    color: #bdbdbd;
  }
}

.chartContainer {
  height: 100%;
}
