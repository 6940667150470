.whiteBox {
  width: 100%;
  // height: 300px;
  padding: 19px;
  background: white;
  border-radius: 5px;
  margin-top: -120px;

  @media (max-width: 1700px) {
    margin-top: -80px;
  }
  @media (max-width: 1440px) {
    margin-top: -40px;
  }
  @media (max-width: 1200px) {
    margin-top: 50px;
    height: 100%;
  }
}

.circle {
  width: 100%;
  border-radius: 100%;
  background-color: #f8f8fd;
  height: 183px;
  position: relative;

  @media (max-width: 1200px) {
    background-color: white;
  }
}

.percent {
  font-family: "ProximaSoft-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 46px;
  line-height: 40px;

  display: flex;

  justify-content: center;
  height: 100%;
  align-items: center;

  /* Med Blue */

  color: #38417c;
}

.scheduled {
  margin-top: 21px;
  font-family: "EuclidFlex-Regular";
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 110.3%;
  /* identical to box height, or 25px */
  margin-bottom: 5px;
  // display: flex;
  // align-items: center;
  text-align: center;
  width: 100%;
  /* Charcoal */

  color: #343642;
}

.finePrint {
  font-family: "EuclidFlex-Regular";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 110.3%;
  /* or 11px */

  // display: flex;
  // align-items: center;
  text-align: center;

  /* Gray 4 */

  color: #bdbdbd;
}
