.whiteBox {
  background-color: #fff;
  height: 308px;
  width: 100%;
  padding: 12px 20px;
  border-radius: 5px;
}

.upcomingDays {
  overflow: scroll;
  height: 190px;
}

.todaysAppointment {
  /* H4 */
  margin-top: 15px;
  font-family: "EuclidFlex-Regular";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  display: flex;
  align-items: center;

  /* Gray 2 */

  color: #4f4f4f;
}
.primaryButton {
  font-family: "EuclidFlex-Regular";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 10px 0px;
  background: #4f5895;
  border-radius: 5px;
  padding: 8.5px 12px;
  border: 0;
  float: right;
}

.skeletonContainer {
  width: 100%;
  height: 100%;
}
