.whiteBox {
  width: 100%;
  background-color: #fff;
  // height: 300px;
  padding: 12px;
  border-radius: 5px;
  margin-top: -120px;

  @media (max-width: 1700px) {
    margin-top: -80px;
  }
  @media (max-width: 1440px) {
    margin-top: -40px;
  }

  @media (max-width: 1200px) {
    margin-top: 20px;
    height: 100%;
    margin-bottom: 20px;
  }
}

.title {
  font-family: "EuclidFlex-Regular";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  display: flex;
  align-items: center;

  /* Gray 2 */

  color: #4f4f4f;
}
