.date {
  font-family: "EuclidFlex-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 23px;
  display: flex;
  align-items: center;

  /* Gray 2 */

  color: #4f4f4f;
}
