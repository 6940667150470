@font-face {
  font-family: "ProximaSoft-Regular";
  src: url("../fonts/ProximaSoft-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/ProximaSoft-Regular.otf") format("opentype"),
    url("../fonts/ProximaSoft-Regular.woff") format("woff"),
    url("../fonts/ProximaSoft-Regular.ttf") format("truetype"),
    url("../fonts/ProximaSoft-Regular.svg#ProximaSoft-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "EuclidFlex-Medium";
  src: url("../fonts/EuclidFlex-Medium.eot?#iefix") format("embedded-opentype"),
    url("../fonts/EuclidFlex-Medium.otf") format("opentype"),
    url("../fonts/EuclidFlex-Medium.woff") format("woff"),
    url("../fonts/EuclidFlex-Medium.ttf") format("truetype"),
    url("../fonts/EuclidFlex-Medium.svg#EuclidFlex-Medium") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "EuclidFlex-Regular";
  src: url("../fonts/EuclidFlex-Regular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/EuclidFlex-Regular.otf") format("opentype"),
    url("../fonts/EuclidFlex-Regular.woff") format("woff"),
    url("../fonts/EuclidFlex-Regular.ttf") format("truetype"),
    url("../fonts/EuclidFlex-Regular.svg#EuclidFlex-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}
* {
  -webkit-border-radius: 0;
  border-radius: 0;
  margin: 0;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
button,
select,
textarea {
  outline: 0;
}
a,
button {
  cursor: pointer;
}
input,
select {
  -webkit-appearance: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
input {
  transition: 0.5s all ease-in-out;
  -webkit-transition: 0.5s all ease-in-out;
  -moz-transition: 0.5s all ease-in-out;
  -ms-transition: 0.5s all ease-in-out;
}
select,
button,
input {
  outline: none;
}
body {
  margin: 0;
  padding: 0;
  color: #4f4f4f;
  font-size: 14px;
  font-family: "EuclidFlex-Regular";
  font-weight: normal;
  background: #f4f9ff;
  -webkit-tap-highlight-color: transparent;
}
ul {
  padding: 0;
  list-style: none;
  margin: 0;
}
a,
img {
  border: 0px;
}
img {
  max-width: 100%;
}
a,
ul,
li {
  text-decoration: none;
}
a {
  outline: 0;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  color: #4f4f4f;
}
a,
button {
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  cursor: pointer;
}
p {
  padding-bottom: 20px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #4f4f4f;
  margin: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 15px;
  font-weight: 400;
}
h1 {
  font-size: 60px;
  line-height: 71px;
  font-weight: 500;
}
h2 {
  font-size: 30px;
  line-height: 35px;
}
h3 {
  font-size: 24px;
}
h4 {
  font-size: 18px;
}
h5 {
  font-size: 17px;
}
h6 {
  font-size: 16px;
}
strong,
b {
  font-weight: 500;
}
em,
i {
  font-style: italic;
}
::-webkit-input-placeholder {
  color: #4f4f4f;
  opacity: 1 !important;
}
::-moz-placeholder {
  opacity: 1 !important;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: #4f4f4f;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: #4f4f4f;
  opacity: 1 !important;
}
.common-input::-webkit-input-placeholder {
  color: #4f4f4f;
  opacity: 1 !important;
}
.common-input::-moz-placeholder {
  opacity: 1 !important;
}
.common-input::-ms-input-placeholder {
  color: #4f4f4f;
}
.common-input::-moz-placeholder {
  color: #4f4f4f;
}
.active-field::-webkit-input-placeholder {
  color: #4f4f4f;
}
.active-field::-ms-input-placeholder {
  color: #4f4f4f;
}
.active-field::-moz-placeholder {
  color: #4f4f4f;
}
.active-field::-webkit-input-placeholder {
  color: #4f4f4f;
}
.office-invited .common-input::-webkit-input-placeholder {
  color: #bdbdbd;
  opacity: 1 !important;
}
.office-invited .common-input::-moz-placeholder {
  opacity: 1 !important;
}
.office-invited .common-input::-ms-input-placeholder {
  color: #bdbdbd;
}
.office-invited .common-input::-moz-placeholder {
  color: #bdbdbd;
}
/*** reset css end ***/
/*** log in page css start ***/
.full-height {
  height: 100vh;
}
/* .display-none {
  display: none;
} */
.form-bg {
  background: url(../images/forms-bg.png) no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
}
.vertical-middle {
  position: absolute;
  top: 50%;
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  left: 50%;
  text-align: center;
  width: 100%;
}
.common-form {
  background: #fff;
  border-radius: 5px;
}
.form-main {
  padding: 0 30px;
  max-width: 524px;
  width: 524px;
}
.login-form {
  padding: 50px 43px 38px 43px;
}
.form-logo {
  margin-bottom: 30px;
  display: inline-block;
  vertical-align: middle;
}
.form-logo-main {
  text-align: center;
}
.login-form .common-input {
  width: 100%;
}
.login-form .form-logo {
  max-width: 284px;
}
.form-head h2 {
  font-family: "EuclidFlex-Medium";
  font-size: 18px;
  line-height: 23px;
  color: #343642;
  margin: 0 0 45px 0;
}
.common-input {
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #4f4f4f;
  font-family: "ProximaSoft-Regular";
  padding: 5px 6px 5px 18px;
  height: 45px;
}
.common-form form p {
  padding-bottom: 30px;
}
.active-field {
  border: 1px solid #828282 !important;
}
.blue-btn {
  background: #3e4896;
  color: #fff;
}
.blue-btn.btn-hover {
  background: #2675da;
  color: #fff;
}
.common-btn {
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  display: inline-block;
  border: 0;
  vertical-align: middle;
  font-size: 13px;
  line-height: 16px;
  padding: 10px 12px;
  font-family: "EuclidFlex-Regular";
}
.login-form .common-btn {
  min-width: 75px;
}
.grey-btn {
  background: #d2d2d2;
  font-size: 12px;
  line-height: 14px;
  color: #323232;
  padding: 10px;
}
form label {
  color: #4f4f4f;
  font-size: 14px;
  line-height: 17px;
  font-family: "ProximaSoft-Regular";
}
.forgot-pass {
  text-align: left;
  margin-top: -17px;
}
.forgot-pass a {
  font-size: 12px;
  line-height: 15px;
  color: #2a3688;
  border-bottom: 1px solid #0c4491;
}
.login-submit {
  text-align: right;
  margin: 45px 0 0 0;
}
footer {
  margin: 18px 0 0 0;
}
footer ul li {
  display: inline;
  font-size: 12px;
  line-height: 14px;
  position: relative;
  margin: 0 2px;
}
footer ul li a {
  color: #fff;
}
.no-border-top {
  border-top: 0 !important;
}
.wrong-credentials {
  float: left;
  width: calc(100% - 96px);
  text-align: left;
  margin: 45px 0 0 0;
}
.forgot-pass-head {
  text-align: left;
  margin-bottom: 10px;
}
.forgot-pass-head h2 {
  margin-bottom: 10px;
}
.forgot-pass-form form .login-submit {
  margin-top: 23px;
  text-align: right;
}
.sub-heading {
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
  padding: 23px 20px;
}
.sub-heading.with-arrow {
  padding: 17px 20px;
}
.sub-heading.with-arrow h3 {
  background: url(../images/go-back.svg) no-repeat left;
  padding: 6px 0px 6px 32px;
}
.sub-heading h3 {
  color: #2a3688;
  font-size: 14px;
  line-height: 19px;
  font-weight: normal;
  margin-bottom: 0;
  letter-spacing: 0.08em;
  text-transform: uppercase;
}
.login-form.forgot-pass-form {
  padding: 0;
}
.inner-forgot-pass {
  padding: 40px 39px 38px 39px;
}
.not-registered-email {
  float: left;
  width: calc(100% - 143px);
  margin: 22px 0 0 0;
}
.not-registered-email p {
  text-align: left;
  letter-spacing: -0.4px;
  padding-right: 5px;
}
.backto-login {
  margin-top: -21px;
}
/*** log in page css end ***/
/*** register page css start ***/
.register-form {
  padding: 50px 0 38px 0;
}
.register-form .common-input {
  width: 100%;
}
.office-invited {
  padding: 0px 43px 15px 43px;
  border-bottom: 1px solid #e0e0e0;
  text-align: left;
}
.common-form form .office-invited p {
  padding-bottom: 17px;
}
.passwords-main {
  padding: 30px 43px 0 43px;
}
.office-invited .common-input {
  border: 1px solid #bdbdbd;
  color: #bdbdbd;
}
.register-form .login-submit {
  margin: 0;
}
.register-form .login-submit .common-btn {
  min-width: 130px;
}
.form-head p {
  line-height: 17px;
  padding-bottom: 10px;
}
/*** privacy policy page css start ***/
.sub-heading.privacy-policy-head {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  padding: 23px 20px 14px;
  position: relative;
}
.sub-heading.privacy-policy-head .privacy-logo {
  float: left;
}
.privacy-policy-head h3 {
  color: #828282;
  float: left;
  padding-left: 25px;
}
.privacy-policy-form {
  max-width: 770px;
  text-align: left;
}
.policy-content h2 {
  font-size: 18px;
  line-height: 21px;
  font-weight: 400;
}
/*** custom scrollbar css start ***/
.custom-scrollbar {
  padding: 70px 0;
}
#cstm-scrlbar {
  overflow: hidden;
}
.custom-scrl-content {
  overflow: auto;
  position: relative;
  width: 100%;
  max-width: 100%;
}
.privacy-policy-form .custom-scrl-content {
  height: 505px;
}
.custom-scrl-content.hidden {
  display: none;
}
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #828282 !important;
  border-radius: 5px !important;
  width: 9px !important;
  height: 103px;
}
.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #e0e0e0 !important;
  border-radius: 0 !important;
  width: 9px !important;
}
.mCSB_scrollTools {
  right: -3px;
}
/*** custom scrollbar css end ***/
.privacy-inner {
  padding: 0 0 20px 20px;
}
.policy-content {
  padding: 30px 0 0 0;
}
/*** dashboard page css start ***/
/* .dashboard-main {
	height: 100vh;
	overflow: hidden;
} */
.dashboard-main .middle-content {
  width: calc(100% - 277px);
  padding-right: 30px;
}
.leftbar {
  background: #38417c;
  top: 18px;
  border-radius: 0 10px 10px 0;
  height: calc(100vh - 90px);
  min-height: calc(100vh - 90px);
}
.leftbar .logo {
  max-width: 132px;
  display: inline-block;
  vertical-align: middle;
  margin: 15px 10px 15px;
}
.rightbar {
  padding: 18px 30px 50px 277px;
  position: relative;
  background: #f4f9ff;
}
.dashboard-title {
  float: left;
}
.user-profile-right {
  float: right;
  position: relative;
}
.user-prof {
  background: url(../images/toggle.png) no-repeat center right;
  padding: 5px 20px 5px 0;
}
.user-prof img {
  vertical-align: middle;
}
.user-profile-right span {
  line-height: 16px;
  color: #052d64;
  padding-right: 10px;
}
.sky-btn {
  border-radius: 5px;
  background: #2675da;
  color: #fff;
}
.sky-btn.btn-hover {
  background: #aed2ff;
  color: #052d64 !important;
}
.dashboard-top {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}
.white-box {
  box-shadow: 2px 2px 20px rgba(62, 132, 219, 0.08);
  background: #fff;
  border-radius: 5px;
}
.dash-content-wrapper {
  max-width: 70.4%;
  margin-top: 30px;
  padding-bottom: 20px;
}
.select-office h3 {
  color: #052d64;
  line-height: 28px;
  font-weight: 400;
  float: left;
  width: calc(100% - 164px);
  margin: 5px 20px 0 0;
}
.goto-office .common-btn span {
  font-weight: 700;
  background: url(../images/office-arrow.svg) no-repeat right center;
  padding: 9px 42px 7px 0;
}
.goto-office .common-btn {
  padding: 12px 7px 12px 20px;
}
.select-office {
  border-top: 1px solid #e0e0e0;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  padding: 35px 30px;
}
.select-office:first-child {
  border-top: 0;
}
.goto-office .common-btn.btn-hover {
  color: #fff;
}
.middle-content {
  position: absolute;
  top: 50%;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
}
.dashboard-main-title h2 {
  font-family: "EuclidFlex-Medium";
  font-size: 14px;
  line-height: 18px;
  color: #2a3688;
  margin: 0;
}
.dashboard-main-title h2 span {
  display: block;
  line-height: 16px;
  color: #052d64;
  font-weight: 400;
  font-size: 14px;
  margin-top: -2px;
}
.invitation .dashboard-main-title h2 {
  margin-bottom: 7px;
}
/* .invitation .white-box {
	padding: 22px 35px 22px 30px;
	min-height: 68px;
} */
.invitation h4 {
  margin: 0 20px 0 0;
  width: calc(100% - 160px);
  float: left;
  line-height: 21px;
  font-weight: normal;
  color: #343642;
}
.light-blue {
  background: #aed2ff;
  color: #052d64;
}
.light-blue.btn-hover {
  background: #052d64;
  color: #fff;
}
.invist-btns .common-btn {
  padding: 5px;
  min-width: 50px;
}
.invitation {
  padding: 47px 0 0 0;
}
.invist-btns ul li {
  margin-left: 7px;
  display: inline;
}
.create-new-office a {
  line-height: 16px;
  color: #052d64;
  font-weight: 700;
  text-align: center;
  padding: 14px 11px 11px 11px;
  display: inline-block;
  width: 100%;
  vertical-align: middle;
}
.create-new-office a span {
  background: url(../images/create-new-btn.png) no-repeat;
  padding: 5px 0 5px 33px;
}
.create-new-office {
  margin: 40px 0 0 0;
}
.create-new-office .white-box {
  float: left;
  margin-right: 25px;
}
#user-info {
  position: absolute;
  right: 0;
  top: 100%;
  background: #fff;
  box-shadow: none;
  width: 172px;
  z-index: 9;
  font-size: 13px;
  margin-top: 10px;
  margin: 18px auto 0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  display: none;
  border: 1px solid #e0e0e0;
  padding: 0;
  border-radius: 5px;
}
#user-info li {
  display: inline-block;
  width: 100%;
  position: relative;
  z-index: 99;
  vertical-align: middle;
  margin: 0;
  border-top: 1px solid #e0e0e0;
  padding: 3px 0;
}
#user-info li a {
  color: #4f4f4f;
  text-transform: capitalize;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  padding: 7px 10px 7px 35px;
  font-size: 12px;
  line-height: 15px;
}
#user-info li a:hover,
#user-info li a:focus {
  color: #2a3688;
}
.invist-btns p a {
  color: #2a3688;
  font-size: 13px;
  font-family: "EuclidFlex-Medium";
  line-height: 15px;
  padding: 5px 0 0 0;
  float: right;
}
/* .close-box {
	position: absolute;
	right: 20px;
	top: 21px;
} */
.fancybox-content {
  background: transparent;
  padding: 0;
  width: auto;
  overflow: hidden;
}
.fancybox-button.fancybox-close-small svg {
  display: none;
}
.overlay .fancybox-bg {
  background: transparent;
}
.overlay .fancybox-slide--html .fancybox-button {
  padding: 0;
  top: 22px;
  right: -7px;
  opacity: 1;
  border: 0;
}
.create-new-office-popup .sub-heading h3 {
  font-size: 14px;
  line-height: 16px;
}
.fancybox-bg {
  background: rgba(52, 54, 66, 0.7);
}
.box-with-shadow {
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.08);
}
.create-office-form .sub-heading {
  padding: 14px 15px 12px;
}
.small-popup {
  max-width: 464px;
}
.fancybox-can-pan .fancybox-content,
.fancybox-can-swipe .fancybox-content {
  cursor: inherit;
}
/* .create-new-office-popup {
	left: 123px;
} */
.office-heading p {
  color: #052d64;
  font-size: 14px;
  line-height: 20px;
}
.create-office-form .inner-forgot-pass {
  padding: 30px 20px 25px 20px;
}
.office-heading h3 {
  font-size: 24px;
  line-height: 28px;
  color: #052d64;
  font-weight: 400;
  margin-bottom: 10px;
}
.login-submit.ok .common-btn {
  font-size: 13px;
  line-height: 15px;
  color: #f2f2f2;
  text-transform: uppercase;
  min-width: 42px;
}
.login-submit.ok {
  margin-top: 0;
}
#create-new-office3 .create-office-form {
  min-width: 524px;
}
.fancybox-slide--html .fancybox-button {
  padding: 0;
  top: 25px;
  right: 15px;
  opacity: 1;
  border: 0;
}
.create-office-form form label {
  color: #333;
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
  width: 115px;
  padding-right: 20px;
  float: left;
  vertical-align: middle;
  margin: 9px 0 0 0;
}
.big-popup {
  max-width: 768px;
  width: 768px;
}
.create-office-form {
  margin-left: 244px;
}
.create-office-form .office-name .common-input {
  width: 233px;
}
/*** custom radio button css start ***/
[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 22px;
  cursor: pointer;
  color: #4f4f4f;
  font-size: 16px;
  font-weight: 500;
}
[type="radio"]:checked + label::before,
[type="radio"]:not(:checked) + label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 2px;
  width: 9px;
  height: 9px;
  border: 1px solid #4f4f4f;
  border-radius: 100%;
  background: #fff;
  margin: 0;
}
[type="radio"]:checked + label::after,
[type="radio"]:not(:checked) + label::after {
  content: "";
  width: 10px;
  height: 10px;
  background: #4f4f4f;
  position: absolute;
  top: 2px;
  left: 0;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
  opacity: 0;
}
[type="radio"]:checked + label:after {
  opacity: 1;
}
/*** custom radio button css end ***/
.create-office-form .common-input {
  height: 35px;
  border: 1px solid #bdbdbd;
  width: 100%;
  color: #333;
  padding-left: 10px;
}
.member-main-form {
  background: #f4f9ff;
  border-radius: 5px;
  padding: 10px 10px 15px;
  float: left;
  width: calc(100% - 115px);
  margin: 22px 0 0 0;
}
.create-office-form .fields-row-main label {
  font-size: 12px;
  line-height: 14px;
  color: #333;
  font-weight: 400;
  margin-bottom: 10px;
}
.fields-row.half {
  float: left;
  width: 50%;
  padding: 0 11px;
}
.fields-row {
  padding: 0 10px;
}
.btns-group {
  background: #fff;
  border-radius: 5px;
  border: 1px solid #bdbdbd;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}
.create-office-form .fields-row-main .btns-group label {
  color: #4f4f4f;
  padding: 0 0 0 20px;
  text-transform: capitalize;
  margin: 0;
  width: auto;
  float: none;
  display: block;
}
.btns-group li {
  border-left: 1px solid #bdbdbd;
  display: inline-block;
  padding: 9px 20px;
  vertical-align: middle;
}
.no-border {
  border: 0 !important;
}
.fields-row-main {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  margin-bottom: 5px;
}
.create-office-form .fields-row-main .btns-group label {
  font-size: 14px;
  line-height: 16px;
}
.add-another {
  margin: 25px 0 0 115px;
  display: inline-block;
  vertical-align: middle;
}
.add-another a {
  background: url(../images/add-another.png) no-repeat;
  font-size: 13px;
  line-height: 15px;
  font-weight: 500;
  color: #4f4f4f;
  padding: 10px 0 10px 50px;
}
.create-office-form.big-popup .login-submit .common-btn {
  min-width: 224px;
}
.add-another-fields {
  margin: 10px 0 25px 115px;
  padding-bottom: 0px;
  position: relative;
}
.invite-members > label {
  margin-top: 50px !important;
}
.close-fields {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 0px;
  top: 12px;
}
.close {
  background: url(../images/close.png) no-repeat;
  display: block;
  height: 8px;
  background-size: 8px;
}
/*** dashboard page css end ***/
/*** settings page css start ***/
.left-menu {
  margin: 110px 0 100px 0;
}
.left-menu li a {
  color: #f4f9ff;
  font-size: 16px;
  line-height: 19px;
  padding: 12px 13px 12px 23px;
  width: 100%;
  display: inline-block;
  vertical-align: middle;
  color: #f4f9ff;
  opacity: 0.3;
  font-family: "ProximaSoft-Regular";
}
.left-menu li a span {
  padding-left: 31px;
}
.left-menu li a.btn-hover,
.left-menu li.active a {
  background-color: #0c4491;
  color: #fff;
  opacity: 1;
}
.patients {
  opacity: 1 !important;
}
.left-menu li a.patients span {
  background: url(../images/patients.png) no-repeat left;
}
.left-menu li a.inbox span {
  background: url(../images/inbox.png) no-repeat left;
}
.left-menu li a.appointments span {
  background: url(../images/calendar.png) no-repeat left;
}
.left-menu li a.bulletin span {
  background: url(../images/bulletin.svg) no-repeat left;
}
.waiting-room {
  border-top: 1px solid #0c4491;
  border-bottom: 1px solid #0c4491;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}
.waiting-room a {
  font-size: 16px;
  line-height: 19px;
  color: #fff;
  padding: 12px 13px 12px 23px;
}
.waiting-room a:hover,
.waiting-room a:focus,
.waiting-room a.active {
  background-color: #0c4491;
}
.waiting-room a span {
  background: url(../images/waiting-room.svg) no-repeat left;
  padding-left: 31px;
}
.past-appointments a {
  font-size: 14px;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.7);
  padding: 12px 13px 12px 23px;
  width: 100%;
  display: inline-block;
  vertical-align: middle;
}
.past-appointments a:hover,
.past-appointments a:focus,
.past-appointments a.active {
  background: #0c4491;
}
.past-appointments a span {
  background: url(../images/clock.png) no-repeat left;
  padding-left: 20px;
}
.patients-table.white-box {
  padding: 20px;
}
.patients-table table tr {
  border-bottom: 1px solid #e0e0e0;
  background: #fff;
}
.patients-table table tr td {
  padding: 26px 5px;
}
.patients-table table tr:first-child {
  background: transparent;
}
table {
  width: 100%;
  border-collapse: collapse;
}
.patients-table table tr td {
  padding: 26px 27px;
}
table tr td {
  font-size: 14px;
  line-height: 17px;
  color: #4f4f4f;
  font-family: "ProximaSoft-Regular";
}
/* .patients-table td:first-child {
	font-size: 16px;
} */
.patients-table table th {
  text-align: left;
  font-size: 14px;
  line-height: 16px;
  color: #2a3688;
  padding: 0 30px;
  font-weight: 400;
  text-transform: capitalize;
  font-family: "EuclidFlex-Medium";
}
.patients-table table tr:first-child th {
  padding-bottom: 20px;
}
.patients-table table tr:nth-child(2),
.patients-table table tr:last-child {
  box-shadow: 0px 2px 10px rgba(62, 132, 219, 0.08);
}
.patients-table table tr:nth-child(2) td:first-child,
.patients-table table tr:last-child td:first-child {
  border-radius: 5px 0 0 0 !important;
}
.patients-table table tr:nth-child(2) td:last-child,
.patients-table table tr:last-child td:last-child {
  border-radius: 0 5px 0 0 !important;
}
.loadmore {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  text-align: center;
  padding: 40px 0 0 0;
}
.loadmore a {
  background: url(../images/dots.svg) no-repeat center;
  display: block;
  height: 7px;
}
.middle-content.main-content-wrapper {
  position: static;
  transform: none;
  width: 100%;
  padding-right: 0;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 20px;
}
.tabbing-main {
  background: #fff;
  border-radius: 5px;
  font-size: 13px;
  line-height: 15px;
  font-family: "EuclidFlex-Medium";
  min-width: 253px;
  float: left;
  margin: 10px 25px 43px 0;
  overflow: hidden;
}
.tabbing-main li a.active-tab {
  background: #3e4896;
  color: #fff;
}
.tabbing-main li a {
  float: left;
  vertical-align: middle;
  width: 50%;
  text-align: center;
  color: #2a3688;
  padding: 10px;
  position: relative;
}
.tabbing-main li {
  display: inline;
}
.search-form-main {
  margin: 10px 0 0 0;
  width: 341px;
  float: left;
}
.search-form {
  position: relative;
  background: #fff;
  height: 35px;
  border-radius: 3px;
  padding: 7px 0;
}
.search-form .form-control {
  border: 0;
  font-size: 14px;
  line-height: 16px;
  height: auto;
  padding-left: 38px;
  width: 100%;
  padding-right: 15px;
  color: #4f4f4f;
  font-family: "ProximaSoft-Regular";
}
.search-form input[type="submit"] {
  position: absolute;
  top: 11px;
  border: 0;
  left: 15px;
  background-color: transparent;
  width: 14px;
  height: 14px;
  background: url(../images/search.svg) no-repeat center left;
  padding: 0;
}
/*** patinets requested page css start ***/
.patients-table table td .common-btn {
  min-width: 65px;
  height: 25px;
  text-transform: capitalize;
  padding: 5px;
  margin-left: 5px;
}
.patients-table table td .common-btn:first-child {
  margin-left: 0;
}
.border-btn {
  border: 1px solid #e0e0e0;
  color: #4f4f4f;
}
.border-btn:hover {
  background: #e0e0e0;
}
/*** patinets confirmed page css start ***/
.patients-table table tr.patients-confirmed {
  background: #6dcdb8;
  border-bottom: 1px solid #6dcdb8;
  border-top: 2px solid #6dcdb8;
}
.patients-table.white-box.confirmed-patients {
  padding: 0;
}
.patients-table table tr td.accepted-pt {
  color: #2a3688;
}
/*** patients dwtails css start ***/
.patients-details-table .white-box {
  padding: 25px 25px 10px 25px;
}
.patients-details-table table {
  text-align: left;
}
.patients-details-table table th {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  width: 130px;
  padding-bottom: 15px;
  color: #333;
}
.patients-details-table table tr td {
  padding-bottom: 15px;
}
.patients-details-table table tr td a {
  color: #333;
}
.patients-details-table table tr td a:hover,
.patients-details-table table tr td a:focus {
  color: #2675da;
}
.patients-details-table table tr td {
  padding-bottom: 15px;
}
.patients-name {
  margin: 32px 0 0 0;
}
.patients-name h3 {
  line-height: 28px;
  color: #052d64;
  font-weight: 400;
}
.patients-details-table {
  margin: 33px 0 0 0;
}
.patients-details-table > h4 {
  font-size: 14px;
  line-height: 16px;
  color: #052d64;
  font-weight: 400;
}
/*** edit office page css start ***/
select.common-input {
  color: #4f4f4f;
  background: url(../images/select-arrow.svg) no-repeat 95%;
  cursor: pointer;
}
.member-main-form.member-main-table {
  background: transparent;
  padding: 0;
}
.member-main-form.member-main-table table {
  border-spacing: 0 10px;
  border-collapse: unset;
}
.member-main-table table tr {
  background: #f4f9ff;
}
.member-main-table table td {
  padding: 10px 15px;
  height: 45px;
}
.close-member {
  background: url(../images/close-grey.svg) no-repeat;
  height: 10px;
  width: 20px;
  display: block;
}
.edit-office-main .common-btn {
  min-width: auto;
}
#edit-office .common-btn {
  min-width: auto !important;
  margin-left: 10px;
}
#edit-office .custom-scrl-content {
  height: 221px;
}
#edit-office .inner-forgot-pass {
  padding-right: 0;
}
#edit-office .login-submit {
  padding-right: 30px;
}
#edit-office .office-name {
  padding-right: 30px;
}

/*** new added css start ***/
/*** waiting room page css start ***/
.waiting-table.white-box table {
  text-align: left;
}
.waiting-table table th {
  color: #4f4f4f;
  padding: 12px 10px 12px 15px;
  font-size: 12px;
  font-weight: normal;
}
.waiting-table table td,
.waiting-table table td a {
  font-size: 12px;
  line-height: 16px;
  color: #4f4f4f;
  padding: 0 10px;
}
.waititng-dots {
  height: 12px;
  width: 12px;
  border-radius: 100%;
  margin-right: 10px;
  float: left;
}
.dots-orange {
  background: #f2994a;
}
.dots-green {
  background: #6fcf97;
}
.dots-red {
  background: #ce0000;
}
.dots-yellow {
  background: #f2c94c;
}
.waiting-table table tr {
  border-top: 1px solid rgba(224, 224, 224, 0.3);
  background: rgba(242, 242, 242, 0.5);
  border-bottom: 7px solid #fff;
  vertical-align: middle;
}
.waiting-table table tr:first-child {
  border-top: 0;
}
.waiting-table table td.calls a {
  background: url(../images/table-call.svg) no-repeat 12px center;
  color: #4f4f4f;
  padding-left: 7px;
  border-radius: 3px;
  height: 25px;
  display: block;
  font-size: 12px;
  min-width: 90px;
  line-height: 14px;
}
.waiting-table table td.calls a span {
  padding: 6px 0 6px 0;
  height: 30px;
  display: block;
  text-align: center;
}
.msg-patient a {
  background: url(../images/Messenger.svg) no-repeat;
  display: block;
  width: 18px;
  height: 16px;
  position: relative;
}
.patient-file a {
  background: url(../images/file-grey.svg) no-repeat;
  width: 16px;
  height: 17px;
  display: block;
}
.left-sec {
  width: 70.4%;
  float: left;
}
.waiting-table.white-box {
  padding: 15px;
  min-height: 183px;
  position: relative;
  font-family: "ProximaSoft-Regular";
}
.waiting-table table td.close-waiting a {
  background: url(../images/table-close.svg) no-repeat center center;
  width: 10px;
  height: 10px;
  display: block;
  border-left: 1px solid #e0e0e0;
  height: 35px;
  padding: 0 18px;
}
.waiting-table .custom-scrl-content {
  height: 142px;
}
.waiting-table .mCSB_inside > .mCSB_container {
  margin-right: 0;
}
.waiting-table .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  height: 30px;
}
.waiting-table .short-name {
  height: 24px;
  width: 24px;
  line-height: 22px;
  margin-left: -40px;
}
.waiting-table table th.withshort-name {
  padding: 11px 10px 12px 50px;
}
.withshort-name span {
  padding: 3px 0 0 0;
  display: block;
}
.waiting-title h3 {
  font-size: 14px;
  line-height: 16px;
  color: #052d64;
  font-weight: 400;
  margin-bottom: 10px;
}
.video-call-content {
  margin: 25px 0 0 0;
}
.videcall-img img {
  width: 100%;
}
.waitting-tabbing {
  background: #f4f9ff;
  /* border-radius: 5px; */
  font-size: 13px;
  line-height: 15px;
  font-weight: 500;
  width: 100%;
  float: left;
  padding: 0px 0 15px 0;
  overflow: hidden;
}
.waitting-tabbing li {
  display: inline;
}
.waitting-tabbing li.active-tabs a {
  background: #3e4896;
  color: #fff;
}
.waitting-tabbing li a {
  float: left;
  vertical-align: middle;
  width: 50%;
  text-align: center;
  color: #3e4896;
  padding: 10px;
  position: relative;
  border-radius: 5px 0 0 5px;
  background: #fff;
}
.right-sec {
  float: left;
  width: 29.6%;
  padding-left: 30px;
}
.patients-info-main {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  /* height: 100%; */
}
.patients-info-heading {
  padding: 15px 18px;
  border-bottom: 1px solid #e0e0e0;
  display: inline-block;
  width: 100%;
  vertical-align: middle;
}
/* .patients-info-heading h3 {
	font-size: 16px;
	line-height: 19px;
	color: #0C4491;
	font-weight: 700;
	float: left;
	width: calc(100% - 17px);
	padding-right: 15px;
	margin-bottom: 0;
} */
.patients-title {
  background: #f2f2f2;
  padding: 12px 25px;
  margin-bottom: 5px;
}
.patients-title h4 {
  font-size: 12px;
  line-height: 14px;
  color: #4f4f4f;
  margin-bottom: 0;
  font-family: "EuclidFlex-Medium";
}
.patients-info label {
  font-size: 14px;
  line-height: 19px;
  width: 100%;
  color: #4f4f4f;
  float: left;
  margin-bottom: 11px;
  font-family: "ProximaSoft-Regular";
}
.patients-info {
  padding: 15px 25px 15px 15px;
}
.patients-info ul li .p-info {
  padding: 0 0 0 15px;
}
.patients-info .common-input {
  border: 1px solid #e0e0e0;
  width: 100%;
  min-height: 65px;
  padding: 15px;
}
.patients-info-main [type="radio"]:checked + label,
.patients-info-main [type="radio"]:not(:checked) + label {
  color: #4f4f4f;
  font-size: 14px;
  line-height: 16px;
}
.patients-info-main [type="radio"]:checked + label::before,
.patients-info-main [type="radio"]:not(:checked) + label::before {
  border: 1px solid #828282;
  background: #bdbdbd;
}
.patients-info li {
  margin-top: 10px;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}
.patients-info li:first-child {
  margin-top: 0;
}
.patients-info-main .custom-scrl-content {
  height: 595px;
}
.patients-info-main .mCSB_inside > .mCSB_container {
  margin-right: 0;
}
.waittingroom-main .middle-content {
  margin: 15px 0 0 0;
  padding-bottom: 30px;
}
.patients-info-row {
  margin-top: 10px;
}
.patients-info-row:first-child {
  margin-top: 0;
}
.videcall-img > img {
  border-radius: 5px;
}
.waiting-table table td.calls.end-call a {
  background: #eb5757 url(../images/phone-white.svg) no-repeat 10px center;
  color: #fff;
}
.waiting-table table td.calls.rejoin-call a {
  background-color: #ffecb3;
}
.waitingroom-full-img img {
  width: 100%;
}
.waitingroom-full-img {
  background: url(../images/full-img.png) no-repeat center center;
  background-size: cover;
  height: 100vh;
  width: 100%;
  position: relative;
}
.chatbox {
  padding: 12px;
  border-radius: 5px;
  float: left;
  width: 100%;
  position: relative;
  margin-bottom: 5px;
}
.chatbox p {
  color: #fff;
  line-height: 17px;
  padding-bottom: 0;
}
.chat-time {
  font-size: 10px;
  line-height: 13px;
  color: #4f4f4f;
}
.short-name {
  border-radius: 100%;
  height: 30px;
  width: 30px;
  background: #aed2ff;
  color: #0c4491;
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  float: left;
  line-height: 32px;
  text-transform: uppercase;
}
.blue-bx {
  background: #2675da;
}
.grey-bx {
  background: transparent;
  border: 1px solid #f2f2f2;
}
.grey-bx p {
  color: #052d64;
}
.chat-text.chat-right {
  margin-left: 50px;
  margin-right: 40px;
}
.chat-text.chat-left {
  margin-right: 60px;
  margin-left: 40px;
}
.chat-content {
  padding: 15px;
  position: relative;
}
.chat-text.chat-right .chat-time {
  text-align: right;
  display: block;
}
.chat-text .short-name {
  position: absolute;
  left: -38px;
  bottom: 0;
  right: auto;
}
.chat-text.chat-right .short-name {
  right: -38px;
  left: auto;
}
.chat-text {
  margin: 25px 50px 0;
}
.chat-text:first-child {
  margin-top: 10px;
}
.type-msg .common-input {
  font-size: 14px;
  color: #828282;
  border: 1px solid #e0e0e0;
  width: 100%;
  letter-spacing: 0.05em;
}
.type-msg {
  position: absolute;
  bottom: 18px;
  left: 15px;
  width: calc(100% - 30px);
}
/*** past appointment page css start ***/
.select-multiple-main {
  float: left;
  min-width: 135px;
  height: 35px;
  margin: 10px 20px 0 20px;
}
.select-multiple-main a {
  line-height: 16px;
  background: #fee398;
  color: #343642;
  width: 100%;
  display: block;
  text-align: center;
  font-family: "EuclidFlex-Medium";
  height: 35px;
  padding: 10px;
  border-radius: 5px;
}
.select-multiple-main a:hover,
.select-multiple-main a:focus {
  background: #052d64;
  color: #fff;
}
.appointment-table {
  margin: 45px 0 20px 0;
}
/*** custom checkbox button css start ***/
.checkbox-custom {
  display: none;
}
.checkbox-custom-label {
  position: relative;
  cursor: pointer;
}
.checkbox-custom + .checkbox-custom-label:before {
  content: "";
  background: transparent;
  border-radius: 2px;
  border: 1px solid #bdbdbd;
  display: inline-block;
  vertical-align: middle;
  width: 8px;
  height: 8px;
  margin-right: 20px;
  text-align: center;
  margin-top: 0;
}
.checkbox-custom:checked + .checkbox-custom-label::after {
  content: "";
  text-align: center;
  position: absolute;
  width: 9px;
  height: 9px;
  top: 5px;
  left: 1px;
  background: #828282;
}
/*** custom checkbox button css end ***/
.appointment-table table th .checkbox-custom + .checkbox-custom-label::before {
  border: 1px solid #2a3688;
}
.appointment-table
  table
  th
  .checkbox-custom:checked
  + .checkbox-custom-label::after {
  top: 4px;
  background: #2a3688;
}
.export-delete .common-btn {
  min-width: 65px;
  height: 25px;
  padding: 5px;
  text-transform: capitalize;
  margin-left: 9px;
}
.export-delete .common-btn:first-child {
  margin-left: 0;
}
.export-delete {
  float: left;
  margin: 15px 0 0 0;
}
.patients-export-option .patients-name {
  float: left;
  width: calc(100% - 100px);
  padding-right: 20px;
  margin: 1px 0 0 0;
}
.exports .common-btn {
  min-width: 95px;
  text-transform: capitalize;
  text-align: center;
}
.exports .common-btn span {
  background-image: url(../images/dropdown-arrow.svg);
  background-repeat: no-repeat;
  background-position: right center;
  padding-right: 16px;
  transition: 0.4s;
}
.exports .common-btn.btn-hover:hover,
.exports .common-btn.btn-hover:focus {
  color: #fff !important;
}
.past-appoint-data {
  padding-top: 15px;
  padding-bottom: 25px;
}
.past-appoint-data .patients-info {
  padding: 15px 60px 15px 30px;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}
.patients-data {
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  line-height: 16px;
  color: #4f4f4f;
  padding: 15px;
  width: 100%;
  float: left;
  font-family: "ProximaSoft-Regular";
}
.patients-data p {
  line-height: 16px;
  color: #4f4f4f;
  padding-bottom: 15px;
}
.physical-exam > strong {
  text-transform: uppercase;
  padding-bottom: 10px;
  display: block;
}
.patients-export-option {
  padding: 30px 0 30px 0;
}
.appointment-data p {
  padding-top: 10px;
  clear: both;
}
.appointment-data .patients-info {
  padding-left: 25px;
}
.patients-info [type="radio"]:checked + label::after,
.patients-info [type="radio"]:not(:checked) + label::after {
  background: #fff;
  border: 1px solid #828282;
}
.d-flex {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
}
.eql-height {
  background: #fff;
  height: 100%;
  position: relative;
  border-radius: 5px;
}
/*** past appointment page css end ***/
/*** new added css end ***/
/*** 14-4-20 added css start ***/
.msg-patient.msg-notification a:after {
  height: 6px;
  width: 6px;
  position: absolute;
  content: "";
  right: 1px;
  top: -2px;
  border-radius: 100%;
  background: #ce0000;
}
.messanger-notification span {
  position: relative;
}
.messanger-notification span:after {
  height: 6px;
  width: 6px;
  position: absolute;
  content: "";
  right: -13px;
  top: 0px;
  border-radius: 100%;
  background: #ce0000;
}
.waitting-tabbing li:last-child a {
  border-radius: 0 5px 5px 0;
  box-shadow: 2px 2px 20px rgba(62, 132, 219, 0.08);
}
.patients-info-main.white-box {
  box-shadow: none;
  margin-bottom: 20px;
}
.videocall {
  width: 100%;
  display: inline-block;
  vertical-align: top;
  height: 100%;
}
.videcall-img {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
}
.video-options {
  background: rgba(52, 54, 66, 0.9);
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 13px;
}
.mic {
  background: url(../images/mic.svg) no-repeat;
}
.endcall {
  background: url(../images/end-call.svg) no-repeat;
}
.video {
  background: url(../images/Video-icon.svg) no-repeat;
}
.video-options li {
  display: inline;
  margin-left: 20px;
}
.video-options li:first-child {
  margin-left: 0;
}
.video-options li a {
  height: 35px;
  width: 35px;
  display: inline-block;
  vertical-align: middle;
}
.video-on {
  position: absolute;
  right: 27px;
  top: 27px;
  border-radius: 5px;
  overflow: hidden;
  width: 162px;
}
.small-video {
  border-radius: 5px;
  width: 100%;
}
.mic-left {
  position: absolute;
  left: 24px;
  top: 24px;
}
.mic-left a {
  width: 24px;
  height: 24px;
  display: block;
}
.mic-left a.unmute {
  background: url(../images/mic-green.svg) no-repeat;
}
.mic-left a.mute {
  background: url(../images/mic-mute.svg) no-repeat;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  visibility: hidden;
}
.mic-left.mute-mic a.mute {
  opacity: 1;
  visibility: visible;
}
.video-settings {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 55px;
  background: #343642;
}
.video-settings-main {
  display: none;
}
.video-call-content.open-setting .video-settings-main.des-video-setting {
  display: block;
}
.video-settings a {
  background: url(../images/horizontal-dots.svg) no-repeat center center;
  display: block;
  height: 100%;
  width: 100%;
  z-index: 9;
  position: relative;
}
/*** waiting room settings page css start ***/
.source-main {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}
.source h4 {
  color: rgba(56, 65, 124, 0.7);
  margin-bottom: 7px;
}
.source select {
  background: rgba(242, 242, 242, 0.5) url(../images/select-arrow.svg) no-repeat
    95%;
  border-radius: 5px;
  border: 0;
  font-size: 13px;
  line-height: 16px;
  color: #4f4f4f;
  padding: 12px;
  width: 100%;
  font-weight: bold;
  cursor: pointer;
}
.player {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  margin: 30px 0 0 0;
}
.vol-info {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  margin: 0;
}
.vol-info h4 {
  float: left;
  margin: 6px 0 0 0;
}
.source h4 {
  color: #343642;
  margin-bottom: 7px;
  font-size: 12px;
  line-height: 16px;
}
.speaker-vol {
  background: #38417c;
  border-radius: 5px;
  float: right;
  color: #fff;
  font-size: 9px;
  line-height: 17px;
  text-align: center;
  width: 81px;
  height: 22px;
  line-height: 21px;
}
.speaker-vol span {
  background: url(../images/speaker.svg) no-repeat left 1px;
  padding-left: 10px;
}
input[type="range"]::-moz-range-thumb {
  border: none;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: #4f4f4f;
}
.vol-range .vol {
  float: left;
  color: #828282;
  font-size: 9px;
  line-height: 17px;
  margin: 0;
  font-family: "ProximaSoft-Regular";
}
.vol-range .vol:last-child {
  float: right;
}
.source.right-s {
  padding: 20px 0 0 0;
}
.slider {
  -webkit-appearance: none;
  height: 2px;
  width: 100%;
  background: #4f4f4f;
  position: relative;
  outline: none;
}
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 12px;
  height: 12px;
  border-radius: 20px;
  background: #4f4f4f;
  position: absolute;
  cursor: pointer;
  outline: none;
  cursor: pointer;
  border: 0;
}

.slider::-moz-range-thumb {
  width: 12px;
  height: 12px;
  border-radius: 20px;
  background: #4f4f4f;
  position: absolute;
  cursor: pointer;
  outline: none;
  cursor: pointer;
  border: 0;
}
/***  range slider css ***/
input[type="range"] {
  /*removes default webkit styles*/
  -webkit-appearance: none;

  /*fix for FF unable to apply focus style bug */
  border: 1px solid white;

  /*required for proper track sizing in FF*/
  width: 100%;
}
input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 2px;
  background: #4f4f4f;
  border: none;
  border-radius: 3px;
}
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: #4f4f4f;
  margin-top: -5px;
}
input[type="range"]:focus {
  outline: none;
}
input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #4f4f4f;
}

input[type="range"]::-moz-range-track {
  width: 100%;
  height: 2px;
  background: #4f4f4f;
  border: none;
  border-radius: 3px;
}
input[type="range"]::-moz-range-thumb {
  border: none;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: #4f4f4f;
}

/*hide the outline behind the border*/
input[type="range"]:-moz-focusring {
  outline: 1px solid white;
  outline-offset: -1px;
}

input[type="range"]::-ms-track {
  width: 100%;
  height: 2px;

  /*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
  background: transparent;

  /*leave room for the larger thumb to overflow with a transparent border */
  border-color: transparent;
  border-width: 6px 0;

  /*remove default tick marks*/
  color: transparent;
}
input[type="range"]::-ms-fill-lower {
  background: #4f4f4f;
  border-radius: 10px;
}
input[type="range"]::-ms-fill-upper {
  background: #4f4f4f;
  border-radius: 10px;
}
input[type="range"]::-ms-thumb {
  border: none;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: #4f4f4f;
}
.micro-vol {
  background: url(../images/micro.png) no-repeat;
  width: 47px;
  height: 33px;
  display: block;
  float: right;
  background-size: 47px;
}
.video-settings-main {
  position: absolute;
  left: 55px;
  top: 50%;
  transform: translateY(-50%);
  width: calc(100% - 110px);
  margin: 0 auto;
}
.ipad-video-setting {
  display: none;
}
.settings-btn li {
  display: inline;
  margin-left: 3px;
}
.settings-btn li a {
  background: #fff;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 500;
  color: #828282;
  width: 53px;
  height: 25px;
  display: inline-block;
  text-align: center;
  line-height: 23px;
  vertical-align: middle;
  border: 1px solid transparent;
  text-transform: capitalize;
}
.settings-btn li a.save {
  background: #38417c;
  color: #fff;
}
.settings-btn li a.save:hover,
.settings-btn li a.save:focus {
  background: transparent;
  color: #38417c;
  border: 1px solid #38417c;
}
.settings-btn li a:hover,
.settings-btn li a:focus {
  background: #38417c;
  color: #fff;
}
.settings-btn {
  text-align: right;
  margin: 20px 0 0 0;
}
/*** stage2 css start ***/
/*** inbox page css start ***/
.col-3 {
  float: left;
  width: 27.2%;
}
.col-7 {
  float: left;
  width: 72.8%;
  padding-left: 25px;
}
.inbox-info-left .search-form {
  background: #f2f2f2;
  border-radius: 5px;
  height: 42px;
  line-height: 30px;
  margin: 0 20px 25px 20px;
}
.inbox-info-left .search-form .form-control {
  background: transparent;
}
.inbox-icon {
  background: url(../images/inbox-icon.png) no-repeat right;
  height: 14px;
  float: left;
  width: 14px;
}
.archives-inbox {
  background: url(../images/view-archive.png) no-repeat right;
  width: 13px;
  float: right;
  height: 13px;
}
.inbox-archive {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  padding: 15px 20px 20px;
}
.inbox-archive ul li {
  float: right;
  position: relative;
}
.inbox-archive ul li.archive-main {
  margin-left: 12px;
}
.archive-show {
  position: absolute;
  top: 100%;
  width: 105px;
  left: 0;
  text-align: left;
  background: #fff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  z-index: 999;
  /* display: none; */
  margin: 10px 0 0 0;
  /* padding:8px 10px; */
}
.archive-show ul li a {
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.03em;
  color: #4f4f4f;
  position: relative;
  padding-left: 15px;
}
.archive-show ul li a:hover,
.archive-show ul li a:focus {
  color: #2a3688;
}
.new-msg-info .archive-show {
  left: auto;
  right: 0;
}
.inbox-archive ul li ul li {
  float: none;
}
.inbox-info-left .search-form input[type="submit"] {
  top: 16px;
}
.inbox-msgs {
  border-top: 1px solid #e0e0e0;
}
.new-msg-info h3 {
  font-size: 16px;
  line-height: 19px;
  color: #4f4f4f;
  margin-bottom: 7px;
  font-weight: normal;
}
.new-msg-info h4 {
  color: #828282;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 0;
  font-weight: normal;
}
.new-msg-info h4 span {
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.05em;
  color: #bdbdbd;
  float: right;
}
.new-msg-info {
  padding: 12px 20px;
  transition: 0.4s;
  border-top: 1px solid #f2f2f2;
  font-family: "ProximaSoft-Regular";
}
.new-msg-info:first-child {
  border-top: 0;
}
.new-msg-info:hover,
.new-msg-info.active {
  background: rgba(242, 242, 242, 0.5);
}
.msg-dots {
  height: 7px;
  width: 7px;
  border-radius: 100%;
  background: #6dcdb8;
  display: inline-block;
  position: absolute;
  right: 0;
  top: 7px;
}
.new-msg-info-inner {
  position: relative;
}
/* .archive-main.open-archives .archive-show {
	display: block;
} */
.new-msg-info .conversations {
  position: absolute;
  top: 2px;
  right: 0;
}
.archive-show li a.followup {
  background: url(../images/followup.png) no-repeat left;
  padding-left: 15px;
}
.archive-show li a.important {
  background: url(../images/important.png) no-repeat left;
  padding-left: 15px;
}
.archive-show li {
  margin: 3px 0;
}
.archive-show li:nth-child(3) {
  border-top: 1px solid #e0e0e0;
  padding: 7px 0 0 0;
  margin-top: 7px;
}
.inbox-main .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #c4c4c4 !important;
  width: 7px !important;
}
.inbox-msgs .custom-scrl-content {
  height: 587px;
}
.inbox-msgs .mCSB_inside > .mCSB_container {
  margin-right: 0;
}
.inbox-hd {
  float: left;
  width: 39%;
}
.inbox-info-right {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  height: 100%;
  position: relative;
}
.inbox-right-head .conversations {
  float: right;
  position: relative;
}
.inbox-hd h4 {
  font-size: 18px;
  line-height: 19px;
  color: #4f4f4f;
  margin-bottom: 7px;
  font-family: "EuclidFlex-Medium";
}
.inbox-right-head {
  display: flex;
  border-bottom: 1px solid #e0e0e0;
  padding: 14px 30px 14px 20px;
  align-items: center;
  justify-content: space-between;
}
/* .inbox-right-head .archive-show li:last-child {
	border-top: 1px solid #E0E0E0;
	padding-top: 6px;
	margin-top: 6px;
} */
.inbox-hd h5 {
  font-size: 14px;
  line-height: 16px;
  color: #828282;
  margin-bottom: 0;
  font-weight: normal;
  font-family: "ProximaSoft-Regular";
}
.inbox-right-head .conversations .archive-show {
  left: auto;
  right: 0;
  margin-top: 15px;
}
.dark-blue {
  background: #3e4896;
}
.inbox-info-right .chat-text {
  float: right;
  margin-top: 4px;
  width: 100%;
  margin-bottom: 15px;
  position: relative;
}
.inbox-info-right .chat-text.chat-left .chatbox {
  float: left;
}
.inbox-info-right .chatbox {
  padding: 12px;
  border-radius: 3px;
  margin-bottom: 10px;
  max-width: 56%;
  float: right;
}
.inbox-info-right .grey-bx p {
  color: #333;
}
.inbox-info-right .chat-text .short-name {
  background: #bb6bd9;
  color: #fff;
}
.inbox-info-right .chat-content {
  padding: 15px 25px 155px 25px;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}
.inbox-info-right .type-msg .common-input {
  color: #4f4f4f;
  border: 0;
  background: transparent;
  padding-left: 40px;
}
.inbox-info-right .type-msg {
  float: left;
  width: calc(100% - 50px);
  position: absolute;
  left: 25px;
  bottom: 25px;
  margin: 0;
  border: 1px solid #e0e0e0;
  border-radius: 15px;
  overflow: hidden;
}
.copy {
  /* background: url(../images/copy.png) no-repeat left; */
  display: block;
  height: 16px;
}
.copy-msg {
  background: #f2f2f2;
  height: 36px;
  padding: 11px 0 0 20px;
  position: relative;
}
.inbox-info-right .type-msg .common-input.active-field {
  border: 0 !important;
}
.important-msg h3 {
  position: relative;
  padding-left: 20px;
}
.important-msg h3:before {
  position: absolute;
  left: 0;
  top: 4px;
  content: "";
  background: url(../images/important.png) no-repeat left;
  width: 10px;
  height: 9px;
}
.copy-msg-open,
.cal-msg-open {
  font-family: "ProximaSoft-Regular";
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.03em;
  color: #4f4f4f;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  position: absolute;
  top: -30px;
  left: 10px;
  width: 126px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  opacity: 0;
  transition: 0.4s;
}
.copymsg .copy-msg-open {
  opacity: 1;
}
.inbox-info-right .checkbox-custom + .checkbox-custom-label::before {
  border-radius: 100%;
  width: 14px;
  height: 14px;
}
.inbox-info-right .checkbox-custom:checked + .checkbox-custom-label::after {
  width: 14px;
  height: 14px;
  top: 1px;
  left: 1px;
  background: #4f4f4f;
  border-radius: 100%;
}
.msg-select {
  position: absolute;
  right: 0;
  bottom: 42px;
}
.inbox-info-right .chat-text.chat-left {
  margin-right: 0;
  padding-left: 40px;
}
.inbox-info-right .chat-text.chat-right {
  padding-right: 45px;
  margin: 0;
}
.copy-selected-msg {
  background: #333333;
  border-radius: 5px;
  font-weight: 500;
  font-size: 11px;
  width: 44px;
  height: 18px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #f2f2f2;
  line-height: 17px;
  float: right;
}
.copy-selected-msg:hover,
.copy-selected-msg:focus {
  background: #3e4896;
}
.selected-msgs > span {
  font-size: 11px;
  line-height: 127.19%;
  letter-spacing: 0.05em;
  color: #343642;
  font-family: "ProximaSoft-Regular";
}
.selected-msgs {
  background: rgba(224, 224, 224, 0.9);
  padding: 7px 25px;
  position: absolute;
  width: 100%;
  z-index: 9;
}
.inbox-info-right .chat-time {
  color: #4f4f4f;
  clear: both;
  display: block;
}
.multiple-msg .msg-select {
  right: -107px;
  bottom: 30px;
}
.inbox-options-main select {
  position: relative;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #4f4f4f;
  padding-left: 0;
  border-top: 1px solid #f2f2f2;
  width: 100%;
  display: inline-block;
  padding: 11px 20px;
}
.inbox-options-main a.inbox-archv {
  border: 0;
  width: auto;
  padding: 12px 20px;
}
.inbox-archv::before {
  position: absolute;
  right: 0;
  top: 16px;
  content: "";
  background: url(../images/select-arrow.svg) no-repeat right center;
  width: 17px;
  height: 11px;
}
.inbox-archive-link {
  padding: 15px 10px 15px 25px;
}
.open-links .archive-show,
.open-links2 .archive-show,
.open-links3 .archive-show {
  display: block;
}
/*** stage3 css ***/
/*** bulletin page css start ***/
.stage3-pages .dashboard-title h2 {
  color: #2a3688;
}
.addnew {
  color: #fff;
  min-width: 107px;
}
.addnew-post-main {
  margin: 8px 0 35px 0;
}
.stage3-pages .patients-table table th {
  color: #2a3688;
  text-transform: unset;
}
.bulletin-table table th,
.bulletin-table table tr td {
  padding-left: 60px;
  padding-right: 60px;
}
.delete-post {
  background: url(../images/delete.svg) no-repeat;
  width: 14px;
  height: 16px;
  display: inline-block;
}
.post-delete {
  text-align: right;
}
.post-error {
  background: #fee398;
  border-radius: 5px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 11px;
  height: 35px;
  padding: 10px 15px;
}
.post-error p strong {
  font-weight: 500;
}
.post-error p {
  font-size: 13px;
  line-height: 15px;
  color: #333;
  padding: 0;
}
.switch {
  position: relative;
  width: 30px;
  height: 17px;
  float: left;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.switch-btn {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e0e0e0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 20px;
}
.switch-btn::before {
  position: absolute;
  content: "";
  height: 11px;
  width: 11px;
  left: 4px;
  bottom: 3px;
  background-color: #828282;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 20px;
}
input:checked + .switch-btn {
  background-color: #bdbdbd;
}
input:checked + .switch-btn:before {
  -webkit-transform: translateX(12px);
  -ms-transform: translateX(12px);
  transform: translateX(12px);
  background-color: #4f4f4f;
}
/*** switch css end ***/
.lightblue {
  background: #3e4896;
  color: #fff;
}
.lightblue:hover,
.lightblue:focus {
  background: #052d64 !important;
}
.new-post-main {
  padding: 60px 0 0 0;
}
.new-post-main form .common-btn {
  min-width: 67px;
  text-transform: capitalize;
  margin: 15px 0 0 0;
}
.new-post-main .white-box {
  border: 0;
  padding: 20px;
  width: 100%;
}
.title-post .form-group {
  float: left;
  min-width: 48.8%;
}
.new-post-right {
  float: right;
}
.new-post-main form .common-btn.post-btn {
  margin-left: 15px;
}
.title-post {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  margin-bottom: 25px;
}
.new-post-main form textarea {
  min-height: 470px;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #4f4f4f;
}
.new-post-main .col-7 {
  padding: 0 25px 0 0;
}
.title-post .form-group input[type="text"] {
  font-size: 24px;
  color: #4f4f4f;
}
.post-dt {
  font-size: 14px;
  color: #4f4f4f;
  display: block;
  margin-bottom: 30px;
  font-family: "ProximaSoft-Regular";
}
.notify {
  color: #4f4f4f;
  padding-left: 10px;
  font-family: "EuclidFlex-Medium";
}
.post-created-date.white-box {
  padding: 23px 30px;
}
.post-created-date h3 {
  color: #2a3688;
  font-weight: 400;
  font-size: 14px;
}
/*** appointments page css start ***/
.stage3-pages .tabbing-main li a {
  color: #2a3688;
  width: auto;
  min-width: 123px;
}
.stage3-pages .tabbing-main li a.active-tab,
.stage3-pages .tabbing-main li a:hover,
.stage3-pages .tabbing-main li a:focus {
  color: #fff;
  background: #2a3688;
}
.stage3-pages .search-form-main {
  float: right;
}
.appointment-table-stage3 table td .common-btn {
  font-size: 11px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  border-radius: 3px;
  min-width: 80px;
}
.purple-btn {
  background: #660c91;
}
.purple-btn:hover,
.purple-btn:focus {
  background: #830ebb !important;
}
.green-btn {
  background: #27ae60;
}
.green-btn:hover,
.green-btn:focus {
  background: #2ac16a;
}
.orange-btn {
  background: #f2994a;
}
.orange-btn:hover,
.orange-btn:focus {
  background: #f58e32;
}
.yellow-btn {
  background: #f2c94c;
}
.yellow-btn:hover,
.yellow-btn:focus {
  background: #f2cf66;
}
.appointment-table-stage3 table tr td,
.appointment-table-stage3 table tr th {
  padding-left: 60px;
}
/*** appointments details requested ***/
.appointment-details-stage3 .patients-name h3 {
  color: #2a3688;
}
.appointment-details-stage3 .patients-name {
  width: auto;
}
.appointment-details-stage3 .exports {
  float: right;
}
.appointment-details-stage3 .patients-export-option {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}
.appointment-details-stage3 .exports .common-btn {
  margin-left: 8px;
}
.appointment-details-stage3 .waiting-title h3 {
  color: #2a3688;
}
.app-details-stage3 {
  display: inline-block;
  vertical-align: middle;
  padding: 30px 30px 23px 30px;
  width: 100%;
  margin-bottom: 40px;
}
.app-details .btns-group {
  width: auto;
  overflow: hidden;
}
.app-details .btns-group li {
  cursor: pointer;
}
.app-details {
  margin-top: 30px;
}
.app-details:first-child {
  margin-top: 0;
}
.app-details label {
  font-size: 16px;
  color: #333;
  min-width: 90px;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}
.btns-group li.radio-selected {
  background: #3e4896;
}
.btns-group li.radio-selected label {
  color: #fff;
}
.app-details [type="radio"]:checked + label,
.app-details [type="radio"]:not(:checked) + label {
  padding-left: 0;
  font-size: 14px;
  font-weight: 400;
  left: auto !important;
}
.app-details [type="radio"]:checked + label::before,
.app-details [type="radio"]:not(:checked) + label::before {
  left: auto;
}
.app-details
  .btns-group
  li.radio-selected
  [type="radio"]:checked
  + label::before,
.app-details
  .btns-group
  li.radio-selected
  [type="radio"]:not(:checked)
  + label::before {
  border: 1px solid #fff;
  background: transparent;
}
.app-details
  .btns-group
  li.radio-selected
  [type="radio"]:checked
  + label::after {
  background: transparent;
}
.app-details .btns-group li [type="radio"]:checked + label::after,
.app-details .btns-group li [type="radio"]:not(:checked) + label::after {
  background: transparent;
}
.app-details label span {
  padding-left: 20px;
}
.app-details > label {
  text-align: left;
}
.app-details .add-another {
  display: inline-block;
  margin: 12px 0 0 -7px;
  vertical-align: middle;
  width: 100%;
}
.patients-info li.patients-data-stage3 label {
  width: auto;
}
.patients-data-stage3 .p-info .patients-data {
  min-height: 74px;
  margin-left: 30px;
}
.patients-info-stage3 .patients-info li {
  margin-top: 30px;
}
.patients-data-stage3 .p-info {
  display: table;
  width: calc(100% - 155px);
}
.patients-info ul li .p-info.sub {
  padding-left: 20px;
}
.patients-info-stage3 .patients-info li:first-child {
  margin-top: 0;
}
.patients-info-stage3 .patients-info {
  padding: 30px;
}
.app-status .common-btn {
  text-transform: uppercase;
  color: #fff;
  height: 25px;
  min-width: 80px;
  font-size: 11px;
  padding: 6px;
}
.appointment-data span {
  min-width: 65px;
  display: inline-block;
}
.appointment-data span.dt-app {
  display: block;
  font-size: 16px;
  padding: 15px 0 20px 0;
}
.app-status {
  margin-bottom: 20px;
}
.app-btn.lightbtn .common-btn {
  color: rgba(79, 79, 79, 0.3);
  border: 1px solid #e0e0e0;
}
.app-btn .common-btn {
  width: 100%;
  padding: 8px;
  border-radius: 3px;
}
.appointment-data {
  margin-top: 25px;
  min-height: 203px;
}
.pat-info-stage3 table th {
  font-size: 14px;
}
.appointment-data .appointment-info {
  padding: 20px;
}
.stage3-popup .sub-heading h3 {
  color: #2a3688;
  font-size: 14px;
}
.app-details .add-another a {
  background: url(../images/plus.svg) no-repeat center center;
  border: 1px solid #bdbdbd;
  border-radius: 5px;
  padding: 9px;
  min-width: 35px;
  display: inline-block;
  vertical-align: middle;
  min-height: 35px;
  font-weight: 400;
}
.app-details .add-another li {
  padding: 7px;
  float: left;
}
.app-details .add-another li:first-child {
  margin-left: 0;
}
.choose-options {
  display: block;
  color: #333;
  padding: 5px 0 0 0;
}
.stage3-popup .app-details .common-input {
  min-height: 76px;
  color: #333;
  display: block;
  width: 100%;
  resize: none;
  padding: 10px 15px;
  height: auto;
  margin-top: 15px;
}
.space-left-popup {
  margin-left: 244px;
}
.stage3-popup .big-popup {
  min-width: 768px;
}
.stage3-popup .common-btn {
  font-size: 14px;
  margin-left: 10px;
  min-width: 42px;
}
.stage3-popup .border-btn {
  color: #4f4f4f;
  padding: 9px;
}
.stage3-popup .border-btn:hover,
.stage3-popup .border-btn:focus {
  color: #fff;
}
.stage3-popup .sub-heading {
  padding: 14px 15px 12px;
}
.stage3-popup .app-details-stage3 {
  margin-bottom: 0;
  padding: 25px 20px;
}
/*** appointments details pending page css start ***/
.app-btn .common-btn {
  border: 1px solid #e0e0e0;
  color: #4f4f4f;
  font-size: 14px;
}
.app-details .add-another .with-app-info a {
  background: url(../images/cross.svg) no-repeat 10px;
  padding-left: 35px;
  min-width: 197px;
  position: relative;
}
.app-dots {
  height: 6px;
  width: 6px;
  border-radius: 100%;
  display: inline-block;
  vertical-align: middle;
  margin-left: 7px;
}
.disable-app-btn {
  opacity: 0.5;
}
.red-btn {
  background: #ce0000;
}
.btns-group li {
  float: left;
}
.app-details .add-another .with-app-info a:hover {
  background-color: #f2f2f2;
}
.tooltiptext {
  visibility: hidden;
  width: 97px;
  text-align: center;
  padding: 11px 0;
  position: absolute;
  z-index: 1;
  background: #fff;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  font-size: 12px;
  letter-spacing: 0.03em;
  color: #4f4f4f;
  top: 100%;
  right: -57px;
  margin: 8px auto 0;
}
.app-details .add-another .with-app-info a:hover .tooltiptext {
  visibility: visible;
}
.stage3-popup .popup-content h3 {
  color: #2a3688;
  line-height: 28px;
  font-weight: 400;
}
.stage3-popup .common-form form p {
  padding-bottom: 17px;
  color: #2a3688;
}
.forgot-pass-form form .popup-content .login-submit {
  margin-top: 15px;
}
.app-details .add-another .with-app-info.appoint-confirmed a {
  background: url(../images/cross-white.svg) no-repeat 10px;
  background-color: #3e4896;
  color: #fff;
  border: 1px solid #3e4896;
}
/*** date time picker css start ***/
.bootstrap-datetimepicker-widget.dropdown-menu.bottom {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 999999;
  background: #ffffff;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  margin-top: 20px;
}
.bootstrap-datetimepicker-widget table td.today::before {
  content: none;
}
.bootstrap-datetimepicker-widget table tr td {
  font-size: 11px;
  line-height: 16px;
  color: #333333;
}
.bootstrap-datetimepicker-widget table td.active {
  background-color: #3e4896;
  color: #fff;
  text-shadow: none;
  border-radius: 100%;
  height: 22px;
  width: 22px;
  display: inline-block;
  line-height: 14px;
}
.bootstrap-datetimepicker-widget table td.active:hover,
.bootstrap-datetimepicker-widget table td.day.active:hover {
  background-color: #3e4896;
  color: #fff;
}
.bootstrap-datetimepicker-widget table th {
  color: #bdbdbd;
  text-transform: uppercase;
  padding: 2px;
}
.bootstrap-datetimepicker-widget table td.old,
.bootstrap-datetimepicker-widget table td.new {
  color: #bdbdbd;
}
.bootstrap-datetimepicker-widget table td.day:hover,
.bootstrap-datetimepicker-widget table td.hour:hover,
.bootstrap-datetimepicker-widget table td.minute:hover,
.bootstrap-datetimepicker-widget table td.second:hover {
  border-radius: 100%;
  background: transparent;
}
.bootstrap-datetimepicker-widget table th.prev::after,
.bootstrap-datetimepicker-widget table th.next::after {
  content: none;
}
.bootstrap-datetimepicker-widget table th.prev,
.bootstrap-datetimepicker-widget table th.prev:hover,
.bootstrap-datetimepicker-widget table th.prev:focus {
  background: url(../images/prev-dt.svg) no-repeat center center !important;
}
.bootstrap-datetimepicker-widget table th.next,
.bootstrap-datetimepicker-widget table th.next:hover,
.bootstrap-datetimepicker-widget table th.next:focus {
  background: url(../images/next-dt.svg) no-repeat center center !important;
}
.bootstrap-datetimepicker-widget table td.day {
  height: 22px;
  line-height: 23px;
  width: 22px;
  padding: 0 !important;
}
.bootstrap-datetimepicker-widget table th.picker-switch {
  color: #4f4f4f;
  text-transform: capitalize;
}
.app-details .add-another a .form-control {
  border: 0;
  line-height: 10px;
}
.bootstrap-datetimepicker-widget table th.picker-switch:hover {
  background: transparent !important;
}
.bootstrap-datetimepicker-widget.dropdown-menu.bottom::before {
  border-left: none;
  border-right: none;
}
.bootstrap-datetimepicker-widget.dropdown-menu.bottom::after {
  border: none !important;
  background: url(../images/datetime-arrow.png) no-repeat center center !important;
  width: 68px;
  height: 21px;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: -20px;
}
.bootstrap-datetimepicker-widget.dropdown-menu {
  width: 205px;
}
.app-details .add-another table a {
  background: transparent;
  border: 0;
}
.app-details .add-another table.table-condensed tr:first-child a i.fa-clock-o,
.app-details
  .add-another
  table.table-condensed
  tr:first-child
  a
  i.fa-clock-o:hover {
  background: url(../images/clock-blue.svg) no-repeat center center !important;
  width: 13px;
  background-size: 100% !important;
}
.app-details .add-another table.table-condensed tr:first-child a i.fa-calendar,
.app-details
  .add-another
  table.table-condensed
  tr:first-child
  a
  i.fa-calendar:hover {
  background: url(../images/appointments-blue.png) no-repeat center center !important;
  width: 13px;
  background-size: 100% !important;
  padding-top: 50px !important;
}
.app-details .add-another table.table-condensed tr:first-child a {
  padding: 0;
}
.picker-switch.accordion-toggle {
  margin-top: -10px;
  text-align: center;
  width: 100%;
  padding: 0 !important;
}
.bootstrap-datetimepicker-widget table td span:hover,
.bootstrap-datetimepicker-widget table td i:hover {
  background: transparent;
}

.collapse {
  display: none;
}

.collapse.show {
  display: block;
}
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.35s ease;
  transition: height 0.35s ease;
}
tr.collapse.show {
  display: table-row;
}

tbody.collapse.show {
  display: table-row-group;
}
.app-details .add-another table.table-condensed tr:first-child a.btn,
.app-details .add-another table.table-condensed tr:first-child a.btn:hover {
  background: transparent !important;
}
.app-details .add-another table.table-condensed tr a.btn .fa-chevron-up {
  background: url(../images/select-down.png) no-repeat center center !important;
}
.app-details .add-another table.table-condensed tr a.btn .fa-chevron-down {
  background: url(../images/select-blue.png) no-repeat center center !important;
}
.bootstrap-datetimepicker-widget table td span,
.bootstrap-datetimepicker-widget table td i {
  width: 24px;
  height: 24px;
  line-height: 24px;
}

.fancybox-content {
  overflow: visible;
}
.fancybox-inner {
  overflow: visible !important;
}
/*** stage4 css start ***/
/* body{
color: #343642;
} */
p {
  color: #4f4f4f;
  font-weight: normal;
  font-family: "ProximaSoft-Regular";
}
/* .stage4-main{
	font-family: 'EuclidFlex-Medium';
} */
/* .leftbar {
  background: #2a3688;
  transition: 0.4s;
  z-index: 9999;
} */
.leftbar .logo {
  max-width: 100px;
  margin: 33px 10px 15px 25px;
}
.left-menu li a span {
  position: relative;
}
.menu-arrow {
  background: url(../images/menu-arrow.svg) no-repeat left center;
  position: absolute;
  top: 37px;
  right: 25px;
  cursor: pointer;
}
.menu-arrow > img {
  vertical-align: middle;
  margin-left: 14px;
}
.logo-main {
  position: relative;
}
.leftbar.menu-open {
  width: 56px;
}
.leftbar.menu-open .menu-arrow {
  background: none;
}
.leftbar.menu-open .logo,
.leftbar.menu-open .left-menu li a span b,
.leftbar.menu-open .waiting-room a span b,
.leftbar.menu-open .past-appointments a span b {
  display: none;
}
.left-menu li a span b {
  font-weight: normal;
}
.leftbar.menu-open .left-menu li a,
.leftbar.menu-open .waiting-room a {
  padding-left: 15px;
}
.leftbar.menu-open .past-appointments a {
  padding-left: 20px;
}
.close-menu .rightbar {
  padding-left: 90px;
}
.waiting-room a:hover,
.waiting-room a:focus,
.waiting-room a.active,
.past-appointments a:hover,
.past-appointments a:focus,
.past-appointments a.active,
.left-menu li a.btn-hover,
.left-menu li.active a {
  background-color: #3e4896;
}
.waiting-room a.meeting-link span {
  background: url(../images/meeting.svg) no-repeat left center;
}
.left-menu,
.waiting-room {
  font-family: "ProximaSoft-Regular";
  /* font-family: 'Roboto', sans-serif !important; */
}
.waiting-room a span b,
.past-appointments a span b {
  font-weight: normal;
}
.waiting-room a {
  /* border-top: 1px solid #3E4896; */
  width: 100%;
  display: inline-block;
  border-bottom: 1px solid #3e4896;
}
.waiting-room {
  border: 0;
}
.past-appointments a {
  font-size: 14px;
  color: #9ca9e2;
  font-family: "ProximaSoft-Regular";
}
.waiting-room a span {
  background: url(../images/waiting-room.svg) no-repeat left;
}
.left-menu li a.patients span {
  background: url(../images/patients.svg) no-repeat left;
}
.left-menu li a.dashboard span {
  background: url(../images/DashboardIcon.png) no-repeat left !important;
}
.left-menu li a.bulletin span {
  background: url(../images/bulletin.svg) no-repeat left;
}
.left-menu li a.inbox span {
  background: url(../images/inbox.png) no-repeat left;
}
.left-menu li a {
  color: #f4f9ff;
}
.left-menu li a.active {
  opacity: 1;
}
.note {
  font-family: "EuclidFlex-Medium";
  background: #6dcdb8;
  height: 13px;
  width: 13px;
  border-radius: 100%;
  text-align: center;
  line-height: 15px;
  font-size: 8px;
  color: #2a3688;
  position: absolute;
  right: -20px;
  top: -4px;
  font-style: normal;
}
.leftbar.menu-open .note,
.leftbar.menu-open .left-menu li a.inbox.active .note {
  right: 4px;
  top: -6px;
}
/*** meeting room css start ***/
.meeting-right .custom-scrl-content {
  height: 217px;
  padding: 10px 0 7px 7px;
}
.username {
  background: #f2994a;
  font-family: "EuclidFlex-Medium";
  font-size: 13px;
  height: 34px;
  width: 34px;
  text-align: center;
  line-height: 34px;
  color: #fff;
  border-radius: 100%;
  color: #fff;
  position: relative;
  display: inline-block;
}
.notification {
  position: absolute;
  top: -6px;
  right: -8px;
}
.user-profile-right span {
  line-height: 16px;
  color: #343642;
  font-family: "EuclidFlex-Medium";
  /* font-family: 'Roboto', sans-serif !important; */
  padding-right: 10px;
  font-size: 13px;
}
.dashboard-title h2 {
  color: #2a3688;
  font-family: "EuclidFlex-Medium";
  /* font-family: 'Roboto', sans-serif !important; */
  line-height: 38px;
}
.btns {
  font-family: "EuclidFlex-Medium";
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  background: #3e4896;
  padding: 11px 12px 9px;
  min-width: 164px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 5px;
}
.btns:hover,
.btns:focus {
  background: #2a3688;
}
.start-meeting span {
  background: url(../images/video.svg) no-repeat left center;
  padding-left: 25px;
}
.meeting-details h2 {
  /* font-family: 'EuclidFlex-Medium'; */
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #343642;
  border-bottom: 1px solid #e0e0e0;
  padding: 18px 30px;
}
.meeting-details form label {
  font-family: "ProximaSoft-Regular";
  font-weight: normal;
  font-size: 14px;
  color: #4f4f4f;
}
.meeting-details form {
  padding: 35px 108px 40px 30px;
  display: inline-block;
  width: 100%;
}
.meeting-details input[type="radio"]:checked + label,
.meeting-details input[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 20px;
  cursor: pointer;
  font-weight: 500;
  font-family: "ProximaSoft-Regular";
  /* font-family: 'Roboto', sans-serif !important; */
  font-size: 14px;
  color: #4f4f4f;
  width: 6px;
  height: 6px;
  font-weight: 400;
  /* border: 1px solid #828282; */
}
.meeting-details input[type="radio"]:checked + label::before,
.meeting-details input[type="radio"]:not(:checked) + label::before {
  width: 6px;
  height: 6px;
  border: 1px solid #828282;
}
.meeting-details [type="radio"]:checked + label::after,
.meeting-details[type="radio"]:not(:checked) + label::after {
  width: 8px;
  height: 8px;
}
.meeting-details .form-group > label {
  width: 125px;
  float: left;
  margin-top: 10px;
}
.meeting-details .common-input {
  border: 1px solid #e0e0e0;
  padding: 5px 6px 5px 18px;
  height: 35px;
  font-family: "ProximaSoft-Regular";
  /* font-family: 'Roboto', sans-serif !important; */
  font-size: 14px;
  color: #4f4f4f;
  min-width: 289px;
}
.inner-fields {
  background: rgba(242, 242, 242, 0.5);
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 15px 10px;
  float: right;
  width: calc(100% - 125px);
}
.meeting-details form .inner-fields label {
  width: 100%;
  display: inline-block;
  margin-bottom: 7px;
  font-size: 11px;
}
.addguest {
  font-family: "EuclidFlex-Medium";
  /* font-family: 'Roboto', sans-serif !important; */
  font-weight: 500;
  font-size: 13px;
  color: #4f4f4f;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  min-width: 100px;
  display: inline-block;
  text-align: center;
  text-transform: capitalize;
  padding: 8px;
}
.addguest:hover,
.addguest:focus {
  color: #fff;
  background: #4f4f4f;
  border: 1px solid #4f4f4f;
}
.add-guest {
  float: left;
  margin: 12px 0 0 125px;
  width: calc(100% - 125px);
}
.meeting-details .inner-fields .common-input {
  min-width: 100%;
  padding-left: 10px;
}
.meeting-details textarea.common-input {
  min-height: 70px;
  width: 100%;
  padding: 10px;
}
.meeting-details {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  margin-bottom: 20px;
}
.meeting-details form .btns {
  min-width: 190px;
  float: right;
}
.tooltip {
  background: url(../images/tooltip.svg) no-repeat right;
  padding: 5px 30px 5px 0;
}
.p-info-inner {
  display: inline-block;
  vertical-align: middle;
  margin-left: 25px;
}
.meeting-option .p-info {
  padding: 20px 0 35px 0;
}
.p-info-inner:first-child {
  margin-left: 0;
}
.meeting-details .form-group {
  margin-bottom: 35px;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}
.patients-export-option.meeting-patients-de > h2 {
  width: calc(100% - 260px);
  float: left;
  line-height: 28px !important;
}
/*** date time picker css start ***/
.bootstrap-datetimepicker-widget.dropdown-menu.top {
  position: absolute;
  top: 100% !important;
  left: 100px !important;
  bottom: auto !important;
  z-index: 999999;
  background: #ffffff;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  margin-top: 20px;
}
.bootstrap-datetimepicker-widget table td.today::before {
  content: none;
}
.bootstrap-datetimepicker-widget table tr td {
  font-size: 11px;
  line-height: 16px;
  color: #333333;
}
.bootstrap-datetimepicker-widget table td.active {
  background-color: #3e4896;
  color: #fff;
  text-shadow: none;
  border-radius: 100%;
  height: 22px;
  width: 22px;
  display: inline-block;
  line-height: 14px;
}
.bootstrap-datetimepicker-widget table td.active:hover,
.bootstrap-datetimepicker-widget table td.day.active:hover {
  background-color: #3e4896;
  color: #fff;
}
.bootstrap-datetimepicker-widget table th {
  color: #bdbdbd;
  text-transform: uppercase;
  padding: 2px;
}
.bootstrap-datetimepicker-widget table td.old,
.bootstrap-datetimepicker-widget table td.new {
  color: #bdbdbd;
}
.bootstrap-datetimepicker-widget table td.day:hover,
.bootstrap-datetimepicker-widget table td.hour:hover,
.bootstrap-datetimepicker-widget table td.minute:hover,
.bootstrap-datetimepicker-widget table td.second:hover {
  border-radius: 100%;
  background: transparent;
}
.bootstrap-datetimepicker-widget table th.prev::after,
.bootstrap-datetimepicker-widget table th.next::after {
  content: none;
}
.bootstrap-datetimepicker-widget table th.prev,
.bootstrap-datetimepicker-widget table th.prev:hover,
.bootstrap-datetimepicker-widget table th.prev:focus {
  background: url(../images/prev-dt.svg) no-repeat center center !important;
}
.bootstrap-datetimepicker-widget table th.next,
.bootstrap-datetimepicker-widget table th.next:hover,
.bootstrap-datetimepicker-widget table th.next:focus {
  background: url(../images/next-dt.svg) no-repeat center center !important;
}
.bootstrap-datetimepicker-widget table td.day {
  height: 22px;
  line-height: 23px;
  width: 22px;
  padding: 0 !important;
}
.bootstrap-datetimepicker-widget table th.picker-switch {
  color: #4f4f4f;
  text-transform: capitalize;
}
/* .app-details .add-another a .form-control {
	border: 0;
	line-height: 10px;
} */
.bootstrap-datetimepicker-widget table th.picker-switch:hover {
  background: transparent !important;
}
.bootstrap-datetimepicker-widget.dropdown-menu.top::before {
  border-left: none;
  border-right: none;
}
.bootstrap-datetimepicker-widget.dropdown-menu::after {
  border: none !important;
  background: url(../images/datetime-arrow.png) no-repeat center center !important;
  width: 68px;
  height: 21px;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: -20px;
}
.bootstrap-datetimepicker-widget.dropdown-menu {
  width: 205px;
}
/* .app-details .add-another table a {
	background: transparent;
	border: 0;
} */
.datetime-main table.table-condensed tr:first-child a i.fa-clock-o,
.datetime-main table.table-condensed tr:first-child a i.fa-clock-o:hover {
  background: url(../images/clock-blue.svg) no-repeat center center !important;
  width: 13px;
  background-size: 100% !important;
}
.picker-switch.accordion-toggle {
  margin-top: -10px;
  text-align: center;
  width: 100%;
  padding: 0 !important;
}
.bootstrap-datetimepicker-widget table td span:hover,
.bootstrap-datetimepicker-widget table td i:hover {
  background: transparent;
}
.meeting-right h5 {
  font-family: "EuclidFlex-Medium";
  /* font-family: 'Roboto', sans-serif !important; */
  font-weight: 500;
  font-size: 14px;
  color: #2a3688;
  border-bottom: 1px solid #e0e0e0;
  padding: 25px;
  margin-bottom: 0;
}
.meeting-right li > span {
  display: block;
  padding: 5px 0 0 0px;
}
.meeting-right li {
  background: rgba(242, 242, 242, 0.5) url(../images/left-arrow.svg) no-repeat
    97%;
  margin-bottom: 8px;
  padding: 13px 15px;
  font-size: 12px;
  color: #4f4f4f;
}
.meeting-right li:nth-child(even) {
  background: #f2f2f2 url(../images/left-arrow.svg) no-repeat 97%;
}
.right-sec.meeting-right {
  font-family: "ProximaSoft-Regular";
  margin-bottom: 20px;
}
.stage4-main .mCSB_scrollTools .mCSB_draggerRail {
  width: 7px !important;
}
.stage4-main .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #c4c4c4 !important;
  width: 7px !important;
  height: 133px;
}
.stage4-main .mCSB_inside > .mCSB_container {
  margin-right: 20px;
}
.patients-export-option {
  padding: 10px 0 40px 0;
}
/*** patients1 page css start ***/
.meeting-details .switch-main {
  margin: 15px 0 0 125px;
  width: 289px;
  position: relative;
}
.meeting-details .notify {
  padding-left: 10px;
  font-family: "ProximaSoft-Regular";
  font-weight: normal;
  color: #4f4f4f;
  /* font-family: 'Roboto', sans-serif !important; */
}
.meeting-details .switch-btn::before {
  background-color: #bdbdbd;
  border-radius: 20px;
}
.meeting-details .switch-btn {
  background-color: #e0e0e0;
}
.meeting-details input:checked + .switch-btn::before {
  background-color: #4f4f4f;
}
.meeting-details input:checked + .switch-btn {
  background-color: #e0e0e0;
}
.switch-main .tooltip-main {
  position: absolute;
  right: 0;
  top: 0;
}
.inner-fields .fields-row-main {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  margin-bottom: 15px;
}
.meeting-details select {
  background: #fff url(../images/select-arrow.svg) no-repeat 96%;
  height: 35px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  width: 100%;
  color: #4f4f4f;
  padding-left: 5px;
}
.arrow_box {
  background: rgba(242, 242, 242, 0.5);
  position: absolute;
  left: 100%;
  padding: 10px 20px;
  font-size: 12px;
  color: #4f4f4f;
  width: 325px;
  top: -27px;
  margin-left: 20px;
  font-family: "EuclidFlex-Regular";
  opacity: 0;
  transition: 0.4s;
}
.arrow_box::after {
  right: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: transparent;
  border-right-color: rgba(242, 242, 242, 0.5);
  border-width: 10px;
  margin-top: -10px;
}
.meeting-tooltip {
  position: relative;
  display: inline-block;
}
.meeting-tooltip.open-tooltip .arrow_box,
.tooltip-main.open-tooltip .arrow_box {
  opacity: 1;
}
/*** patients1 page css end ***/
/*** patients4 page css start ***/
.inner-fields.btm {
  margin: 15px 0;
}
.stage4-popup {
  width: 100%;
  padding: 0;
}
.stage4-main .fancybox-slide--html {
  padding: 0;
}
.stage4-main .fancybox-bg {
  background: rgba(52, 54, 66, 0.7);
}
.stage4-popup .sub-heading h3 {
  color: #2a3688;
  font-size: 14px;
  line-height: 14px;
  text-transform: uppercase;
  font-family: "ProximaSoft-Regular";
  letter-spacing: 0.08em;
}
.stage4-popup .inner-forgot-pass {
  padding: 20px;
  text-align: left;
}
.stage4-popup .inner-forgot-pass h5 {
  font-size: 18px;
  line-height: 23px;
  color: #343642;
  margin-bottom: 5px;
}
.stage4-popup .login-submit .btns {
  min-width: 60px;
}
.meeting-details .custom-scrl-content {
  height: 800px;
}
.stage4-main .left-sec .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  height: 463px;
}
/*** meeting patients details page css start ***/
.title-goback h2 {
  background: url(../images/goback.svg) no-repeat left center;
  padding-left: 30px;
}
.meeting-patients-de.patients-export-option {
  padding: 0 0 10px 0;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}
.stage4-main .patients-export-option > h2 {
  float: left;
  font-size: 24px;
  line-height: 38px;
  color: #343642;
  font-family: "EuclidFlex-Regular";
}
.stage4-main .patients-export-option .exports {
  float: right;
}
.stage4-main .border-btn {
  border: 1px solid #e0e0e0;
  color: #4f4f4f;
  min-width: 120px;
  background: transparent !important;
  padding: 10px 12px 8px;
}
.stage4-main .border-btn:hover {
  background: #4f4f4f !important;
  color: #fff;
  border: 1px solid #4f4f4f;
}
.meeting-patients-de .btns {
  min-width: 110px;
}
.meeting-patients-de .exports .btns {
  margin-left: 12px;
}
.meeting-details h5 {
  font-size: 14px;
  color: #2a3688;
  margin-bottom: 30px;
}
.patients-n {
  font-size: 14px;
  color: #4f4f4f;
  padding: 8px 0 5px 0;
  text-decoration: underline;
  font-family: "ProximaSoft-Regular";
  display: block;
}
.guests-link {
  padding: 7px 0 20px 0;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}
.guests-link a {
  font-size: 12px;
  font-family: "EuclidFlex-Regular";
  color: #4f4f4f;
  text-decoration: underline;
}
.guests-link a:hover,
.guests-link a:focus {
  color: #2a3688;
}
.inner-fields-main {
  width: calc(100% - 125px);
  float: right;
}
.inner-fields-main .inner-fields {
  width: 100%;
}
.guest-form-group .add-guest {
  margin-top: 0;
}
.meet-links input[type="text"] {
  background: transparent;
  border: 0;
  color: #4f4f4f;
  font-size: 14px;
  text-decoration: underline;
  width: calc(100% - 30px);
}
.meet-links-main {
  padding: 5px 25px 25px 25px;
}
.meet-links {
  background: #f2f2f2;
  border-radius: 5px;
  height: 30px;
  padding: 4px 10px;
}
.meet-links input[type="submit"] {
  background: url(../images/submit-arrow.svg) no-repeat left -2px;
  float: right;
  border: 0;
  width: 26px;
  height: 26px;
  cursor: pointer;
}
.meeting-id-main {
  font-size: 14px;
  color: #4f4f4f;
  font-family: "ProximaSoft-Regular";
  margin: 25px 0 0 0;
}
.meeting-id-main > label {
  width: 85px;
  display: inline-block;
  vertical-align: middle;
}
.meeting-id {
  background: #fee398;
  height: 30px;
  min-width: 87px;
  border-radius: 5px;
  display: inline-block;
  text-align: center;
  line-height: 30px;
  text-decoration: underline;
}
.id-copy {
  font-size: 11px;
  border: 1px solid #e0e0e0;
  height: 30px;
  text-align: center;
  line-height: 30px;
  display: inline-block;
  text-align: center;
  min-width: 60px;
  border-radius: 5px;
  margin-left: 15px;
}
.meeting-info h5 {
  border: 0;
}
.stage4-popup .fancybox-button {
  top: 20px;
}
.stage4-popup .login-submit .btns.border-btn {
  margin-right: 10px;
}
.stage4-popup .login-submit {
  margin: 0;
}
/*** past appointmnet patient oriented page css start ***/
.text-decoration {
  text-decoration: none;
}
.meeting-option.form-group .p-info {
  padding: 11px 0 0 0;
}
.past-appointment-stage4 .meeting-details .inner-fields .common-input {
  background: rgba(255, 255, 255, 0.3);
}
.past-appointment-stage4 .meeting-details select {
  background: rgba(255, 255, 255, 0.3) url(../images/select-arrow.svg) no-repeat
    96%;
}
.meeting-right .meeting-summary-main {
  margin-bottom: 25px;
}
.meeting-info p {
  color: #4f4f4f;
  padding-bottom: 5px;
}
.meeting-summary {
  padding: 0 25px 25px;
}
.export-btn span {
  background: url(../images/export-arrow.svg) no-repeat right center;
  padding: 10px 30px 10px 0;
}
.meeting-patients-de .btns.export-btn {
  min-width: 95px;
}
.meeting-patients-de .btns.delete-app {
  min-width: 60px;
}
.guests-fields .inner-fields {
  margin-bottom: 12px;
  padding: 15px 10px;
}
.guests-fields .inner-fields .fields-row-main {
  margin-bottom: 0;
}
.stage4-main .dashboard-title {
  width: calc(100% - 180px);
}
.datetime-main #datetimepicker1 {
  background: rgba(242, 242, 242, 0.5);
  border: 0;
  border-radius: 5px;
  height: 35px;
  min-width: 130px;
  text-align: center;
  font-family: "ProximaSoft-Regular";
  color: #4f4f4f;
}
.form-group.datetime-main {
  font-family: "ProximaSoft-Regular";
  position: relative;
}
/*** past appointmnet patient oriented page css end ***/
/*** new added css start ***/
footer ul li a.fancybox {
  text-decoration: underline;
}
#user-info li:first-child a {
  position: relative;
}
#user-info li:first-child a::before {
  content: "";
  position: absolute;
  left: 17px;
  top: 9px;
  /* background: url(../images/profile-arrow.svg); */
  width: 11px;
  height: 11px;
}
.user-profile-right #user-info li:first-child a span {
  font-size: 12px;
  font-family: "EuclidFlex-Regular";
  padding: 5px 0 0 0;
  display: block;
}
.user-profile-right #user-info li:first-child a span i {
  font-style: normal;
  font-size: 9px;
  font-family: "EuclidFlex-Medium";
  height: 13px;
  width: 13px;
  background: #f2f2f2;
  text-align: center;
  display: inline-block;
  border-radius: 100%;
}
.dashboard-main-title {
  padding: 18px 20px 18px 20px;
  position: relative;
}
.goto-office {
  display: table;
}
.office-settings-main {
  float: right;
  position: absolute;
  right: 15px;
  top: 8px;
}
.offic-setting-icon {
  background: url(../images/office-setting.png) no-repeat;
  height: 40px;
  width: 40px;
  display: block;
  background-size: 100%;
}
#office-setting {
  position: absolute;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  width: 151px;
  right: 0;
  top: 100%;
  margin-top: 1px;
  display: none;
}
#office-setting li a {
  font-size: 12px;
  padding: 9px 10px 9px 30px;
  border-top: 1px solid #f2f2f2;
  position: relative;
  display: block;
}
#office-setting li a:hover,
#office-setting li a:focus {
  color: #2a3688;
}
.create-new-o {
  background: url(../images/office-plus.svg) no-repeat 15px center;
}
.edit-ex-office {
  background: url(../images/Edit.svg) no-repeat 10px center;
}
.invitation .dashboard-main-title {
  border-bottom: 1px solid #f2f2f2;
}
.invitation p {
  padding-bottom: 0;
}
.walkin-invi {
  padding: 30px;
  display: inline-block;
  width: 100%;
}
.invist-btns .border-btn {
  min-width: 70px;
}
.invi-accepted {
  background: #6dcdb8;
  box-shadow: 2px 2px 20px rgba(62, 132, 219, 0.08);
  border-radius: 0 0 5px 5px;
  vertical-align: bottom;
}

/* Added style for error messages */
.error-message {
  color: red;
  font-weight: bold;
  padding-top: 3em;
}

/*** create office page css start ***/
.create-office-main .white-box > h2 {
  padding: 20px 20px 20px 30px;
  font-size: 24px;
  line-height: 30px;
  color: #343642;
  border-bottom: 1px solid #e0e0e0;
}
.office-form {
  padding: 20px 110px 0 30px;
}
.create-office-main .form-group label {
  width: 125px;
  float: left;
  margin-top: 10px;
}
.create-office-main .common-input {
  padding-left: 8px;
  height: 35px;
  font-family: "ProximaSoft-Regular";
  min-width: 290px;
}
.office-form .form-group {
  margin-bottom: 30px;
}
.tabbing-main .note {
  height: 15px;
  width: 15px;
  line-height: 16px;
  font-size: 9px;
  right: 8px;
  top: 9px;
}
/*** settings page css start ***/
.profile-main {
  padding: 50px 0 0 0;
}
.col-6 {
  float: left;
  width: 50%;
}
.profile-infomation {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
}
.profile-imgs {
  width: 108px;
  float: left;
  position: relative;
  overflow: hidden;
  border-radius: 100%;
  height: 108px;
  cursor: pointer;
}
.profile-info-main {
  display: flex;
  width: 100%;
  vertical-align: middle;
  padding: 45px 60px 45px 60px;
  height: 100%;
  align-items: center;
}
.profile-imgs > img {
  height: 100%;
  width: 100%;
  border-radius: 100%;
  object-fit: cover;
}
.prfl-info {
  display: table;
  padding: 10px 0 0 60px;
}
.prfl-info h5 {
  font-size: 14px;
  color: #2a3688;
  font-family: "EuclidFlex-Medium";
  margin-bottom: 20px;
}
.profile-info {
  color: #4f4f4f;
  font-size: 14px;
  font-family: "ProximaSoft-Regular";
  padding-bottom: 10px;
}
.profile-info label {
  float: left;
  width: 120px;
  padding-right: 10px;
}
.profile-info input {
  border: 0;
  background: transparent;
  padding: 0;
}
.col-left {
  padding-right: 12px;
}
.col-right {
  padding-left: 12px;
}
.c-password {
  font-size: 13px;
  color: #4f4f4f;
  background: rgba(242, 242, 242, 0.5);
  text-align: center;
  width: 207px;
  padding: 10px;
  border-radius: 5px;
  display: block;
  margin: 20px 0 0 0;
}
.c-password:hover,
.c-password:focus {
  background: #f2f2f2;
}
.profile-head {
  display: inline-block;
  width: 100%;
}
.profile-head h2 {
  float: left;
  width: calc(100% - 270px);
  font-size: 24px;
}
.profile-head .profilebtn {
  float: right;
}
.profile-hover {
  background: rgba(51, 51, 51, 0.7);
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  transition: 0.4s;
}
.profile-imgs:hover .profile-hover {
  opacity: 1;
}
.profile-hover span {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.profile-infomation .col-right .prfl-info {
  padding-left: 0;
}
.common-subheading.sub-heading {
  padding: 13px 20px 10px;
}
/*** custom upload button css start ***/
#custom-button {
  background: rgba(242, 242, 242, 0.7);
  border-radius: 5px;
  display: inline-block;
  vertical-align: middle;
  min-width: 245px;
  font-size: 13px;
  line-height: 17px;
  color: #4f4f4f;
  padding: 9px 10px;
  border: 0;
  margin-left: 20px;
  font-family: "EuclidFlex-Medium";
}
.upload-photo {
  position: relative;
  padding: 20px 0 0 0;
}
#custom-text {
  font-size: 12px;
  padding-left: 0;
  color: #a5a5a5;
  display: block;
  position: absolute;
  right: auto;
  top: 29px;
  left: 370px;
}
#real-file {
  position: absolute;
  top: 0;
  height: 40px;
  width: 228px;
  opacity: 0;
  cursor: pointer;
}
/*** custom upload button css end ***/
.inner-popup-content {
  padding: 20px;
  text-align: left;
}
.inner-popup-content .forgot-pass-head h5 {
  color: #2a3688;
  font-size: 14px;
  font-family: "EuclidFlex-Medium";
}
.select-photo li {
  display: inline;
  margin-left: 7px;
}
.select-photo li:first-child {
  margin-left: 0;
}
.select-photo li a > img {
  max-width: 34px;
}
.inner-popup-content .or {
  font-size: 13px;
  font-family: "EuclidFlex-Medium";
  padding: 20px 0;
  display: block;
}
.select-photo label {
  padding-bottom: 15px;
  display: block;
}
.up-btn {
  text-align: right;
}
.select-photo {
  padding-bottom: 40px;
}
.profiles-popup .form-group label {
  width: 110px;
  padding-right: 10px;
  display: inline-block;
  vertical-align: middle;
}
.profiles-popup .form-group .common-input {
  width: calc(100% - 120px);
}
.profiles-popup .form-group {
  margin-bottom: 25px;
}
.password-condition {
  font-size: 12px;
  padding: 10px 0 30px 0;
}
.profiles-popup .up-btn .common-btn {
  min-width: 145px;
}
.inner-popup-content .common-input {
  height: 35px;
  padding-left: 10px;
}
.inner-popup-content select.common-input,
.create-office-main select.common-input {
  padding-left: 5px;
  background: url(../images/select-arrow.svg) no-repeat 97%;
}
.profile-infomation .col-right .inbox-bx {
  float: left;
  width: 50%;
}
.appointments-bx {
  padding-left: 20px;
  float: left;
  width: 50%;
}
.profilebtn a {
  margin-left: 13px;
}
.profilebtn a:first-child {
  margin-left: 0;
}
/*** patients profile page css start ***/
.account-info .prfl-info {
  padding-left: 0;
}
.account-info .prfl-info {
  float: left;
  width: 50%;
  padding-right: 50px;
}
.acc-row label {
  float: left;
  width: 40%;
  padding-right: 10px;
}
.linked-accounts {
  padding: 20px 0 0 0;
}
.acc-row {
  background: rgba(242, 242, 242, 0.5);
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 13px 12px;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  font-family: "ProximaSoft-Regular";
}
.account-info .prfl-info.right {
  padding-left: 60px;
  padding-right: 0;
}
.profile-info a:hover,
.profile-info a:focus {
  color: #2a3688;
}
.account-info {
  margin-top: 30px;
}
.profile-info-main.account-info {
  -moz-align-items: start;
  -webkit-align-items: start;
  -ms-align-items: start;
  align-items: start;
}
.active-resolved span {
  text-decoration: underline;
}
.inbox-appoint {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
}
#edit-profile-popup .profiles-popup .form-group {
  margin-bottom: 10px;
}
.user-contact {
  padding: 40px 0 30px 0;
}
#edit-profile-popup .profiles-popup .up-btn .common-btn {
  min-width: 177px;
}
.inner-popup-content .login-submit .common-btn {
  margin-left: 10px;
}
.inner-popup-content .login-submit .common-btn:first-child {
  margin-left: 0px;
}
.inner-popup-content .login-submit {
  margin: 10px 0 0 0;
}
.fancybox-slide--html .common-subheading .fancybox-button {
  top: 15px;
}
.opacity-btn {
  opacity: 0.3;
}
/*** accordion css start ***/
.accordion-container {
  position: relative;
  width: 290px;
  height: auto;
  display: inline-block;
}
.set {
  position: relative;
  margin-bottom: 8px;
}
.set > a {
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  transition: all 0.2s linear;
  width: 100%;
  display: block;
  padding: 9px;
}
.set > a i {
  float: right;
  margin-top: 2px;
}
/* .set > a.active{
	background-color:#3399cc;
	color: #fff;
  } */
.content {
  /* display: none; */
  padding: 8px;
}
.create-office-main .accordion-container .common-input {
  height: auto;
  padding: 0;
}
.set.common-input a > span {
  height: 8px;
  width: 8px;
  border-radius: 100%;
  border: 1px solid #828282;
  display: inline-block;
  margin-right: 5px;
}
.set.common-input.selected-btn a > span {
  background: #bdbdbd;
}
.active-arrow {
  background: url(../images/accordion-arw.svg) no-repeat 97%;
  width: 5px;
  height: 8px;
}
.acc-arrow {
  background: url(../images/select-arrow.svg) no-repeat 97%;
  width: 8px;
  height: 5px;
}
/*** create office page css start ***/
.create-office-main .white-box {
  padding: 0 0 35px 0;
}
.officetime-main {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  font-family: "ProximaSoft-Regular";
}
.o-time-main {
  float: left;
  width: 33.33%;
}
.office-time {
  border-radius: 5px;
  height: 35px;
  text-align: center;
  background: #f2f2f2;
  line-height: 35px;
  margin-bottom: 3px;
}
.am-pm {
  background: rgba(242, 242, 242, 0.5);
  height: 15px;
  text-align: center;
  width: calc(50% - 6px);
  font-size: 9px;
  float: left;
  text-transform: uppercase;
  margin: 3px;
  border-radius: 5px;
}
.am-pm.selected-time {
  background: #f2f2f2;
  text-decoration: underline;
}
.time-to {
  float: left;
  width: 33.33%;
  padding: 0 20px;
  text-align: center;
  line-height: 35px;
}
.create-office-main .form-group.meeting-details .inner-fields label {
  margin-bottom: 5px;
  width: 100%;
}
.create-office-btns {
  text-align: right;
  margin-right: 35px;
}
.login-form .common-btn.ok-btn {
  min-width: 45px;
}
.edit-office-accordion .set.common-input.selected-btn a > span {
  background: #4f4f4f;
}
/*** inbox page css start ***/
.inbox-options {
  display: none;
}
/*** custom select css start ***/
.select-hidden {
  display: none;
  visibility: hidden;
  padding-right: 10px;
}
.select {
  cursor: pointer;
  display: inline-block;
  position: relative;
  font-size: 13px;
  color: #4f4f4f;
  width: 100%;
  background: #fff;
}
.select-styled {
  position: absolute;
  top: 8px;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 8px 20px;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  width: auto;
  font-family: "EuclidFlex-Medium";
}
.select-styled::after {
  position: absolute;
  right: auto;
  top: 10px;
  content: "";
  background: url(../images/select-arrow.svg) no-repeat right center;
  width: 17px;
  height: 11px;
}
.select-options {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  z-index: 999;
  margin: 0;
  padding: 0;
  list-style: none;
  background: #fff;
}
.select-options li {
  margin: 0;
  padding: 8px 0;
  text-indent: 20px;
  border-top: 1px solid #f2f2f2;
  -moz-transition: all 0.15s ease-in;
  -o-transition: all 0.15s ease-in;
  -webkit-transition: all 0.15s ease-in;
  transition: all 0.15s ease-in;
}
.select-options li:hover {
  color: #4f4f4f;
}
.select-options li[rel="hide"] {
  display: none;
}
/*** custom select css end ***/
.inbox-options-main .mCSB_scrollTools {
  right: -3px;
  z-index: 999;
}
.view-profiles {
  float: left;
}
.select-btns {
  float: left;
  margin-right: 20px;
}
.select-btns .select-styled {
  position: static;
  padding: 7px;
  min-width: 186px;
  text-align: left;
}
.select-btns .select-styled::after {
  right: 14px;
}
.select-btns .select {
  background: #fff4d6;
  border-radius: 5px;
}
.select-btns .select-options {
  background: #fff4d6;
  border-radius: 0 0 5px 5px;
  margin-top: -1px;
}
.select-btns .select-options li {
  border-top: 1px solid #fee398;
  text-indent: 10px;
  padding-top: 9px;
  padding-bottom: 9px;
}
.view-profiles {
  margin-right: 15px;
}
.view-profiles .common-btn {
  height: 30px;
  padding: 8px;
  min-width: 97px;
}
.inbox-right-top .conversation {
  height: 30px;
  width: 30px;
  text-align: center;
  display: inline-block;
  border-radius: 100%;
  line-height: 27px;
}
.inbox-right-top .conversation:hover {
  background: #f2f2f2;
}
.green-btns .select,
.green-btns.select-btns .select-options {
  background: #8ad7c6;
}
.select-btns.green-btns .select-options li {
  border-top: 1px solid #86e0cc;
}
.attachment-msg {
  float: left;
  padding: 0 13px;
}
.attachment {
  background: url(../images/attachment.png) no-repeat;
  width: 17px;
  height: 14px;
  display: inline-block;
}
.calendar-msg {
  float: left;
  position: relative;
}
.calendar {
  background: url(../images/cal.png) no-repeat;
  width: 18px;
  height: 17px;
  display: block;
}
.copy-msg-m {
  float: left;
  width: 23px;
}
.calendar-msg.cmsg .cal-msg-open {
  opacity: 1;
}
.calendar-msg .cal-msg-open {
  left: 0;
  top: -45px;
}
.left-menu li a.inbox span b {
  position: relative;
}
.left-menu li.inbox-menu-main a.inbox span b::after {
  background: url(../images/inbox-arrow.png) no-repeat right;
  position: absolute;
  right: -12px;
  top: 9px;
  content: "";
  width: 7px;
  height: 5px;
}
.left-menu li.inbox-menu-main.inbox-menu-open a.inbox span b::after {
  transform: rotate(-180deg);
  top: 7px;
}
.left-menu li.inbox-menu-main a.inbox.active .note {
  right: -25px;
}
.inbox-menu {
  display: none;
}
.leftbar .inbox-menu li a {
  background: rgba(62, 72, 150, 0.5);
  padding-left: 52px;
  color: #9ca9e2;
}
.leftbar .inbox-menu li:last-child a {
  background: transparent;
}
.inbox-menu-open .inbox-menu {
  display: block;
}
.inbox-menu-main {
  position: relative;
}
.close-menu .inbox-menu {
  position: absolute;
  left: 100%;
  top: 0;
  width: 175px;
}
.close-menu .leftbar .inbox-menu li a,
.close-menu .leftbar .inbox-menu li:last-child a {
  background: #3e4896;
  padding-left: 25px;
}
.close-menu .leftbar .inbox-menu li a:hover,
.close-menu .leftbar .inbox-menu li:last-child a:hover {
  background: #2a3688;
}
.archive-show {
  border: 1px solid #e0e0e0;
  border-radius: 5px;
}
.archive-show li a {
  border-top: 1px solid #e0e0e0;
  width: 100%;
  display: block;
  padding: 9px 15px;
}
.archive-show li:first-child a {
  border-top: 0;
}
.pink-btns .select,
.pink-btns.select-btns .select-options {
  background: #fee398;
}
.select-btns.pink-btns .select-options li {
  border-top: 1px solid #fcdb7e;
}
.delete-msg {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  text-align: center;
  font-size: 12px;
  width: 121px;
  padding: 9px;
  position: absolute;
  right: 0;
  top: 100%;
  margin-top: 2px;
  z-index: 9;
}
.chat-text.delete-mesgs .chatbox {
  background: rgba(62, 72, 150, 0.5);
}
.chat-text.delete-mesgs .chatbox p {
  opacity: 0.8;
}
.form-head.forgot-pass-head h4 {
  color: #343642;
  font-family: "EuclidFlex-Medium";
  margin-bottom: 0;
}
.chat-text.deleted-mesgs {
  display: none;
}
#snooze-popup [type="radio"]:checked + label::before,
#snooze-popup [type="radio"]:not(:checked) + label::before {
  background: #fff;
  border: 1px solid #828282;
}
#snooze-popup [type="radio"]:checked + label::after,
#snooze-popup [type="radio"]:not(:checked) + label::after {
  border: 1px solid #828282;
  background: #bdbdbd;
  width: 9px;
  height: 9px;
}
.snooze-options li {
  margin-top: 10px;
  font-family: "ProximaSoft-Regular";
}
.snooze-date-time .office-time {
  float: left;
  min-width: 100px;
}
.snooze-date-time .office-time.o-date {
  /* min-width: 131px; */
  margin-right: 15px;
}
.snooze-date-time {
  margin: 15px 0 0 0;
  display: inline-block;
  width: 100%;
}
.office-time input.form-control {
  background: transparent;
  border: 0;
  font-family: "ProximaSoft-Regular";
  font-size: 14px;
  color: #4f4f4f;
}
.snooze-datetime-main {
  margin: 0 0 5px 0;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}
.inbox-info-right .chat-with-selected-msg .chat-text.chat-right {
  padding-right: 107px;
}
.attachment-img img {
  vertical-align: bottom;
  border-radius: 5px;
  overflow: hidden;
}
#attachment-popup {
  width: 100%;
  height: 100%;
}
.upload-imgs-main {
  padding: 10px 25px 10px 25px;
  width: 100%;
  vertical-align: bottom;
  position: absolute;
  left: 0;
  bottom: 106px;
}
.upload-imgs {
  background: #f2f2f2;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  position: relative;
  padding: 11px;
  overflow: hidden;
}
.upload-imgs #custom-text {
  font-size: 10px;
  color: #4f4f4f;
  top: 4px;
  left: 12px;
  padding-left: 16px;
}
.upload-imgs #custom-text:before {
  position: absolute;
  left: 0;
  top: 4px;
  content: "";
  /* background: url(../images/close-primary.png) no-repeat; */
  width: 8px;
  height: 8px;
}
.upload-imgs #custom-button {
  position: absolute;
  right: 0;
  top: 0;
  min-width: 100px;
  font-size: 10px;
  font-family: "EuclidFlex-Regular";
  color: #4f4f4f;
  background: transparent;
  border-radius: 0;
  margin: 0;
  background: #fff url(../images/upload-arrow.png) no-repeat 89% center;
  padding: 2px;
}
.chat-text.screenshot-download {
  max-width: 200px;
}
.screenshot-download .chatbox {
  max-width: 100%;
  background-image: url(../images/export-arrow.svg);
  background-repeat: no-repeat;
  background-position: 87% center;
  padding: 0;
}
.screenshot-download .chatbox a {
  padding: 12px;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}
.meeting-options .select-options {
  border: 1px solid #e0e0e0;
  border-radius: 0 0 5px 5px;
  margin-top: -2px;
  border-top: 0;
  position: static;
}
.meeting-options .select-styled::after {
  right: 15px;
  background: url(../images/select-arrow.svg) no-repeat right center;
}
.meeting-options .select-styled {
  background: #fff;
  border-radius: 5px;
  height: 35px;
  width: 100%;
  border: 1px solid #e0e0e0;
  color: #4f4f4f;
  font-family: "ProximaSoft-Regular";
  position: static;
}
.meeting-popup-code .meet-links {
  height: 30px;
  padding: 7px 10px;
  display: block;
  max-width: 254px;
}
.meeting-popup-code .meeting-code {
  float: left;
  margin-right: 10px;
}
.meeting-popup-code .common-btn {
  padding: 6px;
  min-width: 70px;
}
.meeting-popup-code {
  margin: 20px 0 15px 0;
}
.meeting-options .select-options li {
  padding: 9px 0;
}
.link-copied-btn {
  background: #e0e0e0;
  border: 0;
  max-width: 98px;
  display: inline-block;
  position: absolute;
  left: 0;
  right: 0;
  margin: 160px auto 0;
  display: none;
}
.meeting-popup-code.link-copied .link-copied-btn {
  display: block;
}
.past-appoint-data > h5 {
  color: #2a3688;
  padding: 10px 25px;
}
.past-app-options.inbox-options-main {
  float: left;
  width: 180px;
  margin: 10px 15px 0 0;
}
.past-app-options.inbox-options-main .select-styled {
  top: 3px;
  border-radius: 3px;
  background: #fff;
  padding-left: 10px;
  font-family: "EuclidFlex-Regular";
}
.past-app-options.inbox-options-main .select {
  background: transparent;
  height: 35px;
}
.past-app-options.inbox-options-main select {
  padding: 0;
  height: 35px;
}
.past-app-options.inbox-options-main .select-styled::after {
  right: 10px;
}
.search-form input[type="submit"] {
  background: url(../images/Search-black.svg) no-repeat center left;
}
.dash-btn-content .btns.export-btn {
  min-width: 92px;
  margin: 10px 0 0 0;
}
/*** waiting pages css start ***/
.dashboard-title.with-right-icon h2 span {
  background: url(../images/office-setting.png) no-repeat right center;
  padding-right: 45px;
  background-size: 33px;
}
.waiting-table > h5 {
  font-size: 15px;
  color: #2a3688;
  padding: 15px 0 0 20px;
}
.no-patients-queue {
  font-family: "ProximaSoft-Regular";
  text-align: center;
  padding: 30px 0 0 0;
}
.expand-view {
  position: absolute;
  right: 25px;
  top: 20px;
}
.patients-info-heading > h5 {
  float: left;
  width: calc(100% - 17px);
  padding-right: 15px;
  margin-bottom: 0;
}
.no-patients-room {
  padding: 15px 0 0 15px;
}
.videcall-img.with-bg {
  width: 100%;
  background: #343642;
  min-height: 468px;
}
.videcall-img.with-bg .video-options {
  background: transparent;
}
.expand-views {
  position: absolute;
  left: 17px;
}
.expand-views > a {
  background: url(../images/expand-view.png) no-repeat;
  height: 36px;
  width: 36px;
  display: block;
}
.join-call-title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}
.join-call-title h2 {
  font-size: 24px;
}
.patients-info-main .patients-title {
  background: transparent;
  border-bottom: 1px solid #f2f2f2;
  border-top: 1px solid #f2f2f2;
  padding-left: 15px;
}
.stage4-main .patients-info-main .mCSB_inside > .mCSB_container {
  margin-right: 0;
}
.stage4-main .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #828282 !important;
  width: 9px !important;
  height: 198px;
}
.stage4-main .mCSB_scrollTools .mCSB_draggerRail {
  width: 9px !important;
}
.waiting-table table tr:nth-child(2n) {
  background: #f2f2f2;
}
.white-btns .select,
.white-btns.select-btns .select-options {
  background: #fff;
}
.select-btns.white-btns .select-options li {
  border-top: 1px solid #e0e0e0;
}
.waiting-table .select-btns .select-styled {
  font-family: "ProximaSoft-Regular";
  padding: 4px 10px;
}
.waiting-table .select {
  font-size: 12px;
  height: 25px;
}
.waiting-table table td.calls.white-call a {
  background-color: #fff;
}
.waiting-table .select-styled::after {
  top: 8px;
}
.waiting-table table td.calls a {
  padding-left: 15px;
}
.stage4-main .waiting-table .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  height: 30px;
}
.close-menu .waiting-table .select-btns .select-styled {
  min-width: 176px;
}
.waiting-table .select-btns .select-styled {
  min-width: 127px;
}
.chatbox.blue-bx {
  background: #3e4896;
}
.purple-bg {
  background: #bb6bd9;
  color: #fff;
}
.setting-open {
  background: #fff;
  position: absolute;
  bottom: 45px;
  right: 10px;
  border-radius: 3px;
  min-width: 310px;
  text-align: left;
  opacity: 0;
  transition: 0.2s;
}
.setting-open ul li {
  display: inline-block;
  font-size: 13px;
  border-top: 1px solid #e0e0e0;
  width: 100%;
  margin: 0;
  padding: 11px 15px;
}
.setting-open ul li:first-child {
  border: 0;
}
.setting-open li a {
  width: auto;
  height: auto;
}
.setting-open li a.active-layout {
  text-decoration: underline;
}
.options-open .setting-open {
  opacity: 1;
}
.full-screen .video-call-content {
  margin: 0;
}
.patients-exp .waiting-table.white-box {
  height: calc(100vh - 175px);
  border-bottom: 25px solid #f4f9ff;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  padding-bottom: 30px;
}
.patients-exp .video-call-content {
  position: relative;
  margin: 0;
}
.patients-exp .video-options {
  position: static;
  padding: 23px;
  border-radius: 5px;
  overflow: hidden;
}
.patients-exp .video-settings {
  width: 76px;
  border-radius: 0 5px 5px 0;
}
.calls-opt {
  float: right;
  margin-right: 75px;
  position: relative;
  z-index: 9;
}
.click-go {
  font-size: 24px;
  line-height: 38px;
}
.triage-queue.no-patients-queue {
  padding: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.for-patients-exp .setting-open {
  bottom: 65px;
}
.patients-exp .waiting-table .custom-scrl-content {
  height: calc(100% - 40px);
}
.stage4-main
  .patients-exp
  .waiting-table
  .mCSB_scrollTools
  .mCSB_dragger
  .mCSB_dragger_bar {
  height: 215px;
}
.mic-left.forexp {
  left: auto;
  top: 5px;
  right: -50px;
}
.chat-rejoin .mic-left {
  left: 0;
  top: 7px;
}
.chat-rejoin.click-go .click-go-link {
  padding-left: 70px;
  color: rgba(255, 255, 255, 0.3);
}
.click-go .click-go-link {
  color: rgba(255, 255, 255, 0.3);
}
.click-go .click-go-link:hover {
  color: #fff;
}
.triage-que {
  font-family: "ProximaSoft-Regular";
  padding: 10px 0 0 0;
}
.triage-que label {
  float: left;
  width: 127px;
  padding-right: 7px;
}
.triage-que .common-input {
  min-height: 35px;
  width: calc(100% - 127px);
  display: inline-block;
  padding: 9px;
}
.switch-main.waiting-popup-switch {
  margin: 20px 0 0 127px;
}
.switch-main.waiting-popup-switch .notify {
  font-family: "ProximaSoft-Regular";
}
.close-box .fancybox-button.fancybox-close-small {
  display: block;
}
.fancybox-button.fancybox-close-small {
  display: none;
}
.threeway-call-video .video-on.third-video {
  top: 145px;
}
.threeway-call-video .video-on {
  right: 0;
  width: 267px;
  top: 0;
  height: auto;
  border-radius: 0;
}
.inbox-msgs .mCSB_scrollTools {
  z-index: 9999;
}
/*** added css 14-7-20 ***/
.dashboard-title h2 a > img {
  max-width: 33px;
  margin: 0px 0 0 10px;
  display: inline-block;
  vertical-align: middle;
}
.dashboard-title.with-right-icon {
  position: relative;
  width: auto;
}
.dashboard-title.with-right-icon .archive-show {
  width: 154px;
  right: 0;
  left: auto;
  margin-top: 0;
}
.archive-show li a.copy-public {
  background: url(../images/copy-grey.png) no-repeat 15px center;
  font-size: 10px;
}
.copy-public span {
  color: #4f4f4f;
  opacity: 0.2;
  padding-left: 20px;
}
.dashboard-title.with-right-icon .archive-show .notify {
  font-size: 12px;
}
.dashboard-title.with-right-icon .archive-show li {
  margin: 0;
}
.archive-show li:nth-child(3) {
  margin: 3px 0;
  padding: 0;
  border: 0;
}
.dashboard-title.with-right-icon .archive-show .switch-main,
.dashboard-title.with-right-icon .archive-show li a {
  padding: 11px 15px;
}
.patients-only-bg {
  background: rgba(224, 224, 224, 0.3);
}
.user-settingss > a {
  background: url(../images/gear.png) no-repeat;
  width: 17px;
  height: 17px;
  display: block;
  z-index: 9999;
  position: relative;
}
.user-settings-main {
  position: relative;
  padding: 20px;
  background: url(../images/white-bx.png) no-repeat;
  display: block;
  width: 300px;
  height: 151px;
  position: absolute;
  padding: 20px 45px 20px 20px;
  right: 40px;
  top: -28px;
  z-index: 999;
  display: none;
}
.user-name-info > img {
  max-width: 38px;
  float: left;
  margin-right: 10px;
}
.user-name-info h5 {
  font-size: 15px;
  font-family: "EuclidFlex-Medium";
  display: table;
}
.pc-info {
  background: rgba(242, 242, 242, 0.5);
  border-radius: 2px;
  padding: 10px;
  display: inline-block;
  width: 100%;
}
.pc-info li {
  float: left;
  width: 50%;
  margin-bottom: 6px;
}
.pc-info li > img {
  vertical-align: middle;
  display: inline-block;
  margin-right: 5px;
}
.call-info li {
  display: inline;
}
.call-info li a img {
  vertical-align: middle;
  display: inline-block;
}
.user-name-info {
  float: left;
  width: 50%;
}
.call-info {
  float: left;
  margin: 5px 0 0 0;
}
.user-settingss {
  position: relative;
}
.pc-info li:nth-child(2) {
  padding-left: 20px;
}
.close-popups {
  position: absolute;
  top: 12px;
  right: 34px;
}
.open-settingss .user-settings-main {
  display: block;
}
/*** appointment weekly page css start ***/
/*  */
/*** appointment edit message page css start ***/
.automatic-msg-main > h2 {
  color: #343642;
  border-bottom: 1px solid #e0e0e0;
  padding: 17px 30px;
}
.automatic-msg-main .accordion-container {
  height: auto;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  float: left;
  width: 61%;
}
.automatic-msg-main .accordion-container .set > a {
  padding: 15px;
  background: url(../images/select-arrow.svg) no-repeat 98%;
  border-bottom: 1px solid #e0e0e0;
}
.automatic-msg-main .accordion-container h5 {
  font-size: 14px;
  font-family: "EuclidFlex-Medium";
  margin: 0;
}
.automatic-msg-main .accordion-container p {
  line-height: 28px;
  padding-bottom: 10px;
}
.selected-text {
  background: rgba(156, 169, 226, 0.3);
  font-size: 13px;
  padding: 5px;
  border-radius: 2px;
  margin: 0 5px;
  font-family: "EuclidFlex-Medium";
}
.automatic-msg-main .accordion-container .set {
  margin-bottom: 0;
}
.automatic-msg-main .accordion-container .set:last-child > a {
  border: 0;
}
.automatic-msg-main .accordion-container .content {
  padding: 20px;
  padding-top: 0;
  border-bottom: 1px solid #e0e0e0;
}
.innermsg-info {
  padding: 20px 30px 20px 30px;
  display: inline-block;
  width: 100%;
  vertical-align: middle;
}
.new-response {
  float: left;
  width: 39%;
  padding-left: 45px;
}
.tag-templates h5 span {
  height: 17px;
  width: 17px;
  border-radius: 100%;
  background: #2a3688;
  display: inline-block;
  color: #fff;
  line-height: 16px;
  text-align: center;
  margin-left: 5px;
}
.tag-templates {
  margin: 30px 0 0 0;
}
.tag-templates h5 {
  font-size: 14px;
  font-family: "EuclidFlex-Medium";
}
.tag-templates .selected-text {
  margin: 0 0 6px 0;
  display: inline-block;
}
.automatic-msg-main.white-box {
  height: calc(100vh - 145px);
}
.automatic-msg-col {
  padding: 30px 0 0 0;
}
/*** appointment event details css start ***/
.common-input.full-width {
  width: calc(100% - 125px);
  min-width: calc(100% - 125px);
}
.app-event-details-form {
  padding-right: 40px;
}
.half-group {
  float: left;
  width: 50%;
}
.half-group.left {
  padding-right: 25px;
}
.half-group.right {
  padding-left: 25px;
  float: right;
  text-align: right;
}
.app-event-details-form .form-group {
  margin-bottom: 15px;
  display: inline-block;
  width: 100%;
  vertical-align: middle;
}
.half-group.right label {
  text-align: left;
}
.app-event-details-form textarea {
  line-height: 24px;
}
.symtoms-checklist [type="radio"]:checked + label::before,
.symtoms-checklist [type="radio"]:not(:checked) + label::before,
.cancel-event-popup [type="radio"]:checked + label::before,
.cancel-event-popup [type="radio"]:not(:checked) + label::before {
  border: 1px solid #bdbdbd;
  border-radius: 2px;
  width: 8px;
  height: 8px;
}
.symtoms-checklist [type="radio"]:checked + label::after,
.symtoms-checklist [type="radio"]:not(:checked) + label::after,
.cancel-event-popup [type="radio"]:checked + label::after,
.cancel-event-popup [type="radio"]:not(:checked) + label::after {
  border-radius: 1px;
  left: 1px;
  width: 8px;
  height: 8px;
  top: 3px;
}
.symtoms-checklist [type="radio"]:checked + label,
.symtoms-checklist [type="radio"]:not(:checked) + label {
  font-size: 14px;
}
.symtoms-checklist .p-info {
  clear: both;
  width: 100%;
  padding: 10px 0 10px 0;
}
.create-office-main .form-group .first-symptoms label {
  width: 200px;
}
.create-office-main .symtoms-checklist .checkbox-custom-label {
  width: 100%;
}
.symtoms-checklist .checkbox-custom + .checkbox-custom-label::before {
  width: 8px;
  height: 8px;
}
.symtoms-checklist .checkbox-custom:checked + .checkbox-custom-label::after {
  width: 8px;
  height: 8px;
  background: #4f4f4f;
  border-radius: 1px;
  /* border: 1px solid #BDBDBD; */
  left: 1px;
  top: 6px;
}
.create-office-main .other-symptoms textarea,
.create-office-main .more-details textarea {
  min-height: 60px;
  margin: 15px 0 0 35px;
  min-width: 367px;
}
.create-office-main .full-label label {
  width: 100%;
}
.create-office-main .more-details textarea {
  margin-left: 0;
  min-width: 405px;
}
.anycontact {
  padding: 20px 0;
}
.personal-contact-details {
  padding: 50px 0 40px 0;
}
.personal-contact-details h5,
.symtoms-checklist h5 {
  font-size: 14px;
  margin-bottom: 20px;
  font-family: "EuclidFlex-Medium";
}
.other-actions {
  background: #f2f2f2;
  border-radius: 5px;
  height: 35px;
  min-width: 123px;
  text-align: center;
  display: inline-block;
  line-height: 35px;
}
.other-actions span {
  font-family: "EuclidFlex-Medium";
  font-size: 13px;
  background: url(../images/select-arrow.svg) no-repeat right;
  padding-right: 15px;
}
.event-head {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  color: #343642;
  border-bottom: 1px solid #e0e0e0;
  padding: 17px 30px;
  -moz-align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
.event-head h2 {
  margin: 0;
}
.event-btns li {
  display: inline;
  margin-left: 11px;
  position: relative;
}
.event-btns li:first-child {
  margin-left: 0;
}
.event-btns li a.blue-btn {
  min-width: 70px;
}
.other-actions-open .archive-show {
  display: block;
}
.event-btns .archive-show li {
  margin: 3px 0;
}
.event-btns .archive-show {
  min-width: 155px;
  right: 0;
  left: auto;
  margin-top: 11px;
}
.symtoms-checklist .form-group {
  margin-bottom: 10px;
}
.app-event-details-form .common-input {
  min-width: 213px;
  resize: none;
}
.cancel-event-popup p {
  color: #343642;
  line-height: 17px;
}
.cancel-event-popup .p-info {
  padding: 10px 0 30px 0;
}
.app-event-details-form .half-group .common-input {
  width: calc(100% - 125px);
}
.app-event-details-form .first-symptoms .common-input {
  width: calc(50% - 200px);
}
/*** calendar added css start ***/
.rbc-time-header-cell .rbc-header a span b,
.rbc-month-header .rbc-header span,
.rbc-allday-cell .rbc-header.rbc-today a span b {
  display: block;
  font-weight: normal;
  font-size: 10px;
  color: #2a3688;
  opacity: 0.6;
  font-family: "EuclidFlex-Medium";
}
.rbc-header span {
  font-size: 20px;
  color: #2a3688;
  font-family: "ProximaSoft-Regular";
}
.rbc-header span i {
  font-style: normal;
}
.rbc-header.rbc-today span i {
  background: rgba(156, 169, 226, 0.2);
  width: 28px;
  height: 28px;
  border-radius: 100%;
  display: inline-block;
  line-height: 28px;
}
.noborder {
  border: 0 !important;
  padding: 0 5px !important;
}
.noborder:hover,
.noborder:focus {
  background: transparent !important;
}
.month-wek-day-btn {
  display: inline-block;
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  box-shadow: none;
  border-radius: 5px;
  padding: 7px 11px;
  margin-left: 7px;
}
.rbc-toolbar button.today {
  background: #f2f2f2 !important;
  border: 1px solid #f2f2f2 !important;
  box-shadow: 2px 2px 20px rgba(62, 132, 219, 0.08);
  border-radius: 5px !important;
  color: #4f4f4f !important;
  font-family: "EuclidFlex-Medium";
  font-size: 12px;
  padding: 10px 13px !important;
}
.calendar-headings .dashboard-title h2 {
  float: left;
}
.dashboard-top.calendar-headings .dashboard-title {
  min-width: 500px;
}
.new-event-btn {
  float: left;
  margin-left: 15px;
}
.appointment-setting-popup {
  position: relative;
  margin-left: 10px;
  float: left;
}
.appointment-setting-popup a > img {
  max-width: 33px;
}
.automatic-msg span {
  background: url(../images/edit.png) no-repeat 15px center;
  padding: 10px 10px 10px 33px !important;
}
.export-data span {
  background: url(../images/plus.png) no-repeat 15px center;
  padding: 10px 10px 10px 33px !important;
}
.print-data span {
  background: url(../images/print-icon.png) no-repeat 15px center;
  padding: 10px 10px 10px 33px !important;
}
.calendar-headings .archive-show li a {
  padding: 10px 0 !important;
}
.calendar-headings .dashboard-title.with-right-icon .archive-show {
  width: 170px;
}
.calendar-main .custom-scrl-content {
  height: 495px;
}
.calendar-main .mCSB_inside > .mCSB_container {
  margin-right: 0;
  position: absolute !important;
  right: 0;
}
.calendar-main .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #c4c4c4 !important;
  width: 7px !important;
}
.rbc-month-view .rbc-month-header .rbc-header {
  padding: 18px 3px;
}
.rbc-month-view .rbc-date-cell {
  font-size: 12px;
  text-align: center;
  padding-top: 10px;
  color: #2a3688;
  font-family: "ProximaSoft-Regular";
  margin-bottom: 5px;
}
.rbc-month-view .rbc-off-range a {
  color: #2a3688;
  opacity: 0.2;
}
.rbc-month-row .rbc-event {
  background: #f2994a;
  font-size: 11px;
  min-height: 22px;
}
.refresh {
  background: url(../images/refresh.svg) no-repeat;
  width: 11px;
  height: 10px;
  display: inline-block;
  opacity: 0.6;
  margin-left: 2px;
}
.monthly-event {
  font-size: 11px;
  color: #4f4f4f;
  font-family: "ProximaSoft-Regular";
  padding: 5px;
}
.monthly-event li span {
  font-family: "EuclidFlex-Medium";
}
.monthly-event li {
  background: url(../images/green-dots.svg) no-repeat left;
  padding-left: 10px;
}
.more-events {
  font-family: "EuclidFlex-Medium";
}
.rbc-month-row .rbc-date-cell.rbc-now.rbc-current a {
  background: rgba(156, 169, 226, 0.2);
  border-radius: 100%;
  height: 18px;
  width: 18px;
  display: inline-block;
  line-height: 18px;
}
.rbc-day-slot .refresh {
  background: url(../images/refresh-white.svg) no-repeat;
  opacity: 1;
  width: 13px;
  height: 11px;
}
.rbc-time-header-content .rbc-allday-cell {
  justify-content: start;
  display: flex;
}
.rbc-allday-cell .rbc-header {
  border: 0;
  padding-left: 60px;
}
.select-dt {
  float: left;
  margin: 8px 10px 0 0;
}
.calendar-datetime {
  padding: 5px 0 20px 0;
}
.to {
  float: left;
  margin: 9px 13px 0 0;
}
.appointment-setting-popup.open-archive .archive-show {
  display: block;
}
.print-checbk {
  font-family: "ProximaSoft-Regular";
  padding-bottom: 20px;
}
.print-checbk > label {
  width: 98px;
  float: left;
}
.print-checbk .checkbox-custom + .checkbox-custom-label::before {
  margin-right: 13px;
}
.print-checbk .form-group {
  margin-right: 46px;
}
.connected-main {
  position: absolute;
  left: 15px;
  bottom: 100px;
  margin-top: 100px;
}
.connected {
  background: url(../images/connected.svg) no-repeat 10px center;
  color: #fff;
  font-size: 12px;
  background-color: rgba(156, 169, 226, 0.15);
  padding: 8px 10px 8px 27px;
  border-radius: 5px;
}
.close-menu .connected span {
  display: none;
}
.close-menu .connected-main {
  left: 10px;
}
.close-menu .connected {
  padding-right: 6px;
}

.open-cal-popup .appointment-popups {
  display: block;
}
.office-text {
  background: url(../images/office.png) no-repeat;
  color: rgba(79, 79, 79, 0.5);
  padding-left: 25px;
}
.prints-type li {
  display: inline;
  margin-left: 15px;
}
.prints-type li:first-child {
  margin-left: 0;
}
.prints-type li a {
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  font-size: 12px;
  padding: 7px 11px;
}
.prints-type li a span {
  background: url(../images/print-icon.png) no-repeat right center;
  padding-right: 20px;
}
.prints-type li a:hover,
.prints-type li a:focus {
  color: #2a3688;
}
.prints-type {
  margin: 30px 0 10px 0;
}
.rbc-day-slot .appointment-popups .refresh {
  background: url(../images/refresh.svg) no-repeat;
  width: 16px;
  height: 15px;
  opacity: 0.3;
  background-size: 100%;
}
.top-info > h4 {
  float: left;
}
.set-options {
  float: right;
}
.top-info {
  display: inline-block;
  width: 100%;
  vertical-align: middle;
}
.set-options li {
  display: inline;
  margin-left: 10px;
}
.set-options li a img {
  vertical-align: middle;
}
.closepopup {
  margin-left: 30px;
}
.appointment-popups p {
  padding-bottom: 7px;
}
.stage4-main #export-data-popup .border-btn,
#print-data-popup .border-btn {
  min-width: auto !important;
}
.print-checbk .checkbox-custom-label {
  vertical-align: middle;
}
.print-checbk .checkbox-custom:checked + .checkbox-custom-label::after {
  width: 9px;
  height: 9px;
  top: 5px;
  left: 1px;
  border-radius: 1px;
}
.print-checbk .checkbox-custom + .checkbox-custom-label::before {
  width: 9px;
  height: 9px;
}
a:hover {
  text-decoration: none;
}

/*** search bar css ***/
.open-search-result .close-search {
  position: absolute;
  right: 15px;
  top: 10px;
}
.search-resultopen {
  background: #ffffff;
  border: 1px solid #f2f2f2;
  box-shadow: 2px 2px 20px rgba(62, 132, 219, 0.08);
  border-radius: 0 0 5px 5px;
  width: 100%;
  position: absolute;
  left: 0;
  top: 100%;
  margin-top: -1px;
  z-index: 9;
}
.search-resultopen li a {
  font-size: 12px;
  border-top: 1px solid #f2f2f2;
  width: 100%;
  display: inline-block;
  padding: 10px 15px;
  font-family: "ProximaSoft-Regular";
}
.search-resultopen li:first-child a {
  border: 0;
}
/*** week page css ***/
.rbc-header span i {
  font-family: "EuclidFlex-Medium";
  color: #343642;
  font-size: 18px;
  display: block;
}
.weekly-view .rbc-header {
  text-align: left;
  padding-left: 12px;
  height: 57px;
}
.weekly-view .rbc-time-header-cell .rbc-header a span b {
  font-size: 10px;
  color: #828282;
}
/* .weekly-view .rbc-header {
   border-left: 0.5px solid #F2F2F2;
   border-right: 0.5px solid #F2F2F2;
} */
.weekly-view .rbc-header.rbc-today {
  border: 1px solid #9ca9e2;
  border-radius: 5px;
}
.weekly-view .rbc-header.rbc-today span i {
  background: transparent;
}
.rbc-time-slot.available {
  text-align: center;
  background: #f8f8fd;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  line-height: 35px;
  text-transform: uppercase;
  font-size: 13px;
  font-family: "EuclidFlex-Medium";
  color: #38417c;
  cursor: pointer;
  transition: 0.4s;
  margin: 1px;
}
.rbc-time-slot.booked {
  text-align: center;
  background: #f8f8fd;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  line-height: 35px;
  text-transform: uppercase;
  font-size: 13px;
  font-family: "EuclidFlex-Medium";
  opacity: 0.6;
  color: #38417c;
  pointer-events: none;
  margin: 1px;
}
.rbc-time-slot.available:hover {
  background: #38417c;
  color: #fff;
}
a.next-cal {
  float: left;
  width: 47px;
  height: 57px;
  text-align: center;
  line-height: 66px;
  border-right: 1px solid #f2f2f2;
}
.topbtns-main.searchbar-rght {
  padding: 0;
}
.cal-search-main.open-search-result {
  margin: 10px 0 10px 11px;
  width: calc(100% - 68px);
}
.rbc-time-content {
  overflow: unset;
}
.search-result-active {
  background: #f2f2f2;
}
.app-search-results-main .visit-drodown {
  background: transparent;
}
.weekly-view .rbc-timeslot-group {
  border-bottom: transparent;
}
.rbc-day-slot .rbc-events-container {
  position: static;
}
/*** search reslut page css start ***/
.app-search-results-main .search-reslut-heade h2 {
  font-size: 18px;
  color: #4f4f4f;
  font-family: "EuclidFlex-Medium";
  margin-bottom: 0;
}
.app-search-results-main .srch-res-info > h3 {
  font-size: 14px;
  color: #38417c;
  border-bottom: 1px solid #f2f2f2;
  font-family: "EuclidFlex-Medium";
  padding-bottom: 10px;
  margin-bottom: 0;
}
.app-search-results-main .srch-res-info-main {
  padding: 15px 20px;
}
.app-search-results-main .srch-info > h4 {
  font-size: 12px;
  color: #38417c;
  font-family: "EuclidFlex-Medium";
  border-bottom: 1px solid rgba(224, 224, 224, 0.5);
  padding-bottom: 8px;
  margin-bottom: 10px;
}
.app-search-results-main .simple-app-info-main {
  border: 0;
  background: transparent;
  padding: 0;
  margin: 0;
}
.app-search-results-main .srch-info {
  background: #f8f8fd;
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 10px;
  margin-top: 13px;
}
.app-search-results-main .app-times {
  font-size: 12px;
}
.app-search-results-main .srch-res-info {
  margin-bottom: 24px;
}
#user-info {
  z-index: 99;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .safari.mac
    .print-checbk
    .checkbox-custom:checked
    + .checkbox-custom-label::after {
    top: 3px !important;
  }
  .safari.mac .events-btns .common-btn {
    padding-top: 10px !important;
  }
  .safari.mac
    .visit-options
    .form-group
    .checkbox-custom:checked
    + .checkbox-custom-label::after {
    top: 3px !important;
  }
  .safari.mac
    .print-checbk
    .checkbox-custom:checked
    + .checkbox-custom-label::after {
    top: 3px !important;
  }
  .safari.mac
    .types-date-main
    .form-group
    .checkbox-custom:checked
    + .checkbox-custom-label::after {
    top: 4px;
  }
}
.date-picker .form-feild,
.time-picker .form-feild {
  background-color: rgba(242, 242, 242, 0.5);
}
/*** stage7 css end ***/

.headrright {
  float: right;
}
.headrright .search-main {
  background: #fff;
  border-radius: 20px;
  padding: 5px 15px;
  width: 103px;
  float: left;
  margin-right: 13px;
  height: 32px;
}
.search-submit {
  background: url(../images/new-search.svg) no-repeat left 4px;
  border: 0;
  cursor: pointer;
}
input.search-text {
  width: calc(100% - 20px);
  border: 0;
  margin: 0;
  display: inline-block;
  vertical-align: top;
  font-size: 13px;
  color: #38417c;
  font-family: "EuclidFlex-Medium";
  line-height: 21px;
  margin-left: 3px;
  margin-top: -1px;
}
input.search-text::-webkit-input-placeholder {
  color: #38417c;
  opacity: 1 !important;
}
input.search-text::-moz-placeholder {
  opacity: 1 !important;
}
input.search-text::-ms-input-placeholder {
  color: #38417c;
}
input.search-text::-moz-placeholder {
  color: #38417c;
}

.search-questions {
  float: left;
  margin-top: 2px;
  /* margin-right: 40px; */
}
